import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import routes from "./routes";
import { checkHasAccess, ACCESS_RESPONSE_TYPE } from "utils/checkHasAccess";
import getDefaultLanguage from "utils/getDefaultLang";
import translatePathname from "utils/translatePathname";
import SmallLoader from "components/SmallLoader";

const LandingPage = lazy(() => import("pages/LandingPage"));
const MainPage = lazy(() => import("pages/MainPage"));
const MainPageJazz = lazy(() => import("pages/MainPageJazz"));
const Composers = lazy(() => import("pages/Composers"));
const WorkDetails = lazy(() => import("pages/WorkDetails"));
const Radios = lazy(() => import("pages/Radios"));
const RadiosDetail = lazy(() => import("pages/RadiosDetail"));
const Podcasts = lazy(() => import("pages/Podcasts"));
const PodcastDetails = lazy(() => import("pages/PodcastDetails"));
const ChapterDetails = lazy(() => import("pages/ChapterDetails"));
const Account = lazy(() => import("pages/Account"));
const Welcome = lazy(() => import("pages/Welcome"));
const WelcomeRadio = lazy(() => import("pages/WelcomeRadio"));
const Articles = lazy(() => import("pages/Articles"));
const ArticleDetails = lazy(() => import("pages/ArticleDetails"));
const JazzArticleDetails = lazy(() => import("pages/JazzArticleDetails"));
const ArticleDetailsOld = lazy(() => import("pages/ArticleDetailsOld"));
const Upgrade = lazy(() => import("pages/Upgrade"));
const Checkout = lazy(() => import("pages/Checkout"));
const Congratulations = lazy(() => import("pages/Congratulations"));
const CongratulationsJazz = lazy(() => import("pages/CongratulationsJazz"));
const Expired = lazy(() => import("pages/Expired"));
const NoPlan = lazy(() => import("pages/NoPlan"));
const Library = lazy(() => import("pages/Library"));
const PlaylistList = lazy(() => import("pages/PlaylistList"));
const Confidentiality = lazy(() => import("pages/Confidentiality"));
const RedirectPage = lazy(() => import("pages/RedirectPage"));
const Videos = lazy(() => import("pages/Videos"));
const VideoDetail = lazy(() => import("pages/VideoDetail"));
const ForgotPassword = lazy(() => import("pages/ForgotPassword"));
const ResetPassword = lazy(() => import("pages/ResetPassword"));
const About = lazy(() => import("pages/About"));
const AboutJazz = lazy(() => import("pages/AboutJazz"));
const NoMatch = lazy(() => import("pages/NoMatch"));
const Registration = lazy(() => import("pages/Registration"));
const Search = lazy(() => import("pages/Search"));
const ConfirmPaypal = lazy(() => import("pages/ConfirmPaypal"));
const LandingPageJazz = lazy(() => import("pages/LandingPageJazz"));
const JazzPlaylists = lazy(() => import("pages/JazzPlaylists"));
const JazzPlaylistDetails = lazy(() => import("pages/JazzPlaylistDetails"));
const AlbumsJazz = lazy(() => import("pages/AlbumsJazz"));
const AlbumJazzDetail = lazy(() => import("pages/AlbumJazzDetail"));
const AlbumClassicalDetail = lazy(() => import("pages/AlbumClassicalDetail"));
const Artists = lazy(() => import("pages/Artists"));
const ArtistDetails = lazy(() => import("pages/Person"));
const JazzArticles = lazy(() => import("pages/JazzArticles"));
const Embed = lazy(() => import("pages/Embed"));
const Person = lazy(() => import("pages/Person"));
const Interpretation = lazy(() => import("pages/Interpretation"));
const GiftcardPurchase = lazy(() => import("pages/GiftcardPurchase"));
const VoucherActivation = lazy(() => import("pages/VoucherActivation"));
const SimpleLogin = lazy(() => import("components/Login/SimpleLogin"));
const PartnerPage = lazy(() => import("pages/PartnerPage"));
const CircleLogin = lazy(() => import("components/Login/CircleLogin"));
const CancelSubscription = lazy(() => import("pages/CancelSubscription"));
const CancellationConfirmed = lazy(() => import("pages/CancellationConfirmed"));
const JazzVideos = lazy(() => import("pages/JazzVideos"));
const ComposersCategory = lazy(() => import("pages/ComposersCategory"));
const ContentByCategory = lazy(() => import("pages/ContentByCategory"));
const JumpBackIn = lazy(() => import("pages/JumpBackIn"));
const RecommendedAlbums = lazy(() => import("pages/RecommendedAlbums"));
const PopularAlbums = lazy(() => import("pages/PopularAlbums"));
const PopularVideos = lazy(() => import("pages/PopularVideos"));
const PersonalRecommendations = lazy(() => import("pages/PersonalRecommendations"));
const YouMayAlsoLove = lazy(() => import("pages/YouMayAlsoLove"));
const Sitemap = lazy(() => import("pages/Sitemap"));
const Collections = lazy(() => import("pages/Collections"));
const PartnerVoucherActivation = lazy(() => import("pages/PartnerVoucherActivation"));

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const loginData = JSON.parse(localStorage.getItem("loginData"));
      const hasAccess = checkHasAccess(props.location.pathname) === ACCESS_RESPONSE_TYPE.ALLOWED;
      const isJazz = props.location.pathname.indexOf("/jazz") !== -1;
      return loginData && hasAccess ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: `${isJazz ? "/jazz" : "/"}`,
            state: {
              from: props.location,
              hasAccess,
            },
          }}
        />
      );
    }}
  />
);

const ApplicationRoutes = ({ match, history: { location } }) => {
  const { lng = getDefaultLanguage() } = match.params;
  const lngRoutes = routes[lng];

  if ((!location.pathname.includes(`/${lng}/`) && !(location.pathname === `/${lng}`)) || location.pathname.length === 1) {
    const test = translatePathname(lng);
    return <Redirect to={`/${lng}${test}${window.location.search}`} />;
  }
  return (
    <Suspense fallback={<SmallLoader isPage />}>
      <Switch>
        <Route exact path={`/${lng}`} component={LandingPage} />
        <PrivateRoute exact path={`/${lng}${lngRoutes.continueListeningJazz}`} component={JumpBackIn} />
        <PrivateRoute exact path={`/${lng}${lngRoutes.continueListening}`} component={JumpBackIn} />
        <PrivateRoute exact path={`/${lng}${lngRoutes.recommendedAlbumsJazz}`} component={RecommendedAlbums} />
        <PrivateRoute exact path={`/${lng}${lngRoutes.recommendedAlbums}`} component={RecommendedAlbums} />
        <PrivateRoute exact path={`/${lng}${lngRoutes.popularAlbumsJazz}`} component={PopularAlbums} />
        <PrivateRoute exact path={`/${lng}${lngRoutes.popularAlbums}`} component={PopularAlbums} />
        <PrivateRoute exact path={`/${lng}${lngRoutes.popularVideosJazz}`} component={PopularVideos} />
        <PrivateRoute exact path={`/${lng}${lngRoutes.popularVideos}`} component={PopularVideos} />
        <PrivateRoute exact path={`/${lng}${lngRoutes.personalRecommendationsJazz}`} component={PersonalRecommendations} />
        <PrivateRoute exact path={`/${lng}${lngRoutes.personalRecommendations}`} component={PersonalRecommendations} />
        <PrivateRoute exact path={`/${lng}${lngRoutes.youMayAlsoLoveJazz}`} component={YouMayAlsoLove} />
        <PrivateRoute exact path={`/${lng}${lngRoutes.youMayAlsoLove}`} component={YouMayAlsoLove} />
        <PrivateRoute exact path={`/${lng}${lngRoutes.jazzCategory}`} component={ContentByCategory} />
        <PrivateRoute exact path={`/${lng}${lngRoutes.category}`} component={ContentByCategory} />
        <Route exact path={`/${lng}${lngRoutes.articlesPaginated}`} component={Articles} />
        <Route exact path={`/${lng}${lngRoutes.jazzArticlesPaginated}`} component={JazzArticles} />
        <Route exact path={`/${lng}${lngRoutes.videosPaginated}`} component={Videos} />
        <Route exact path={`/${lng}${lngRoutes.jazzVideosPaginated}`} component={JazzVideos} />
        <Route exact path={`/${lng}${lngRoutes.articleDetails}`} component={ArticleDetails} />
        <Route exact path={`/${lng}${lngRoutes.jazzArticleDetails}`} component={JazzArticleDetails} />
        <Route exact path={`/${lng}${lngRoutes.playlistDetailsOld}`} component={ArticleDetailsOld} />
        <Route exact path={`/${lng}${lngRoutes.jazzLandingPage}`} component={LandingPageJazz} />
        <Route exact path={`/${lng}${lngRoutes.jazzWelcome}`} component={Welcome} />
        <Route exact path={`/${lng}${lngRoutes.ardoizInscription}`} component={RedirectPage} />
        <Route exact path={`/${lng}${lngRoutes.taProgramme}`} component={RedirectPage} />
        <Route exact path={`/${lng}${lngRoutes.taCompositeurs}`} component={RedirectPage} />
        <Route exact path={`/${lng}${lngRoutes.taJazzmen}`} component={RedirectPage} />
        <Route exact path={`/${lng}${lngRoutes.taOeuvres}`} component={RedirectPage} />
        <Route exact path={`/${lng}${lngRoutes.taTelechargement}`} component={RedirectPage} />
        <Route exact path={`/${lng}${lngRoutes.taInstruments}`} component={RedirectPage} />
        <Route exact path={`/${lng}${lngRoutes.taQuiz}`} component={RedirectPage} />
        <Route exact path={`/${lng}${lngRoutes.toutApprendre}`} component={RedirectPage} />
        <Route exact path={`/${lng}${lngRoutes.expired}`} component={Expired} />
        <Route exact path={`/${lng}${lngRoutes.noplan}`} component={NoPlan} />
        <Route exact path={`/${lng}${lngRoutes.registration}`} component={Registration} />
        <Route exact path={`/${lng}${lngRoutes.confidentiality}`} component={Confidentiality} />
        <Route exact path={`/${lng}${lngRoutes.forgotPassword}`} component={ForgotPassword} />
        <Route exact path={`/${lng}${lngRoutes.generatingNewPassword}`} component={ResetPassword} />
        <Route exact path={`/${lng}${lngRoutes.jazzGeneratingNewPassword}`} component={ResetPassword} />
        <Route exact path={`/${lng}${lngRoutes.whoAreWe}`} component={About} />
        <Route exact path={`/${lng}${lngRoutes.jazzWhoAreWe}`} component={AboutJazz} />
        <Route exact path={`/${lng}${lngRoutes.subscribe}`} component={Upgrade} />
        <Route exact path={`/${lng}${lngRoutes.subscribeStep}`} component={Checkout} />
        <Route exact path={`/${lng}${lngRoutes.checkout}`} component={Checkout} />
        {/* leave paginated playlists and playlistdetails here, otherwise playlist/page/2 will wrongly match playlistDetails! */}
        <Route exact path={`/${lng}${lngRoutes.playlistsPaginated}`} component={Radios} />
        <Route exact path={`/${lng}${lngRoutes.playlistDetails}`} component={RadiosDetail} />
        <Route exact path={`/${lng}${lngRoutes.playlistDetailsWithTitle}`} component={RadiosDetail} />
        <Route exact path={`/${lng}${lngRoutes.podcastDetails}`} component={PodcastDetails} />
        <Route exact path={`/${lng}${lngRoutes.podcastDetailsJazz}`} component={PodcastDetails} />
        <Route exact path={`/${lng}${lngRoutes.chapterDetails}`} component={ChapterDetails} />
        <Route exact path={`/${lng}${lngRoutes.chapterDetailsJazz}`} component={ChapterDetails} />
        <Route exact path={`/${lng}${lngRoutes.videoDetails}`} component={VideoDetail} />
        <Route exact path={`/${lng}${lngRoutes.workDetail}`} component={WorkDetails} />
        <Route exact path={`/${lng}${lngRoutes.workDetailWithTitle}`} component={WorkDetails} />
        <Route exact path={`/${lng}${lngRoutes.workDetailWithTitleAndComposer}`} component={WorkDetails} />
        <Route exact path={`/${lng}${lngRoutes.confirmpaypal}`} component={ConfirmPaypal} />
        <Route exact path={`/${lng}${lngRoutes.jazzArticles}`} component={JazzArticles} />
        <PrivateRoute exact path={`/${lng}${lngRoutes.classicalHome}`} component={MainPage} />
        <PrivateRoute exact path={`/${lng}${lngRoutes.jazzHome}`} component={MainPageJazz} />
        <Route exact path={`/${lng}${lngRoutes.articles}`} component={Articles} />
        <Route exact path={`/${lng}${lngRoutes.composers}`} component={Composers} />
        <Route exact path={`/${lng}${lngRoutes.welcome}`} component={Welcome} />
        <Route exact path={`/${lng}${lngRoutes.welcomeRadio}`} component={WelcomeRadio} />
        <Route exact path={`/${lng}${lngRoutes.playlists}`} component={Radios} />
        <Route exact path={`/${lng}${lngRoutes.podcasts}`} component={Podcasts} />
        <Route exact path={`/${lng}${lngRoutes.podcastsJazz}`} component={Podcasts} />
        <PrivateRoute exact path={`/${lng}${lngRoutes.account}`} component={Account} />
        <PrivateRoute exact path={`/${lng}${lngRoutes.upgrade}`} component={Upgrade} />
        <PrivateRoute exact path={`/${lng}${lngRoutes.congratulations}`} component={Congratulations} />
        <PrivateRoute exact path={`/${lng}${lngRoutes.jazzCongratulations}`} component={CongratulationsJazz} />
        <PrivateRoute exact path={`/${lng}${lngRoutes.library}`} component={Library} />
        <PrivateRoute exact path={`/${lng}${lngRoutes.libraryPlaylistDetails}`} component={PlaylistList} />
        <Route exact path={`/${lng}${lngRoutes.videos}`} component={Videos} />
        <Route exact path={`/${lng}${lngRoutes.jazzVideos}`} component={JazzVideos} />
        <Route exact path={`/${lng}${lngRoutes.jazzVideoDetails}`} component={VideoDetail} />
        <Route exact path={`/${lng}${lngRoutes.search}`} component={Search} />
        <Route exact path={`/${lng}${lngRoutes.jazzSearch}`} component={Search} />
        <Route exact path={`/${lng}${lngRoutes.jazzAlbums}`} component={AlbumsJazz} />
        <Route exact path={`/${lng}${lngRoutes.jazzAlbumsDetails}`} component={AlbumJazzDetail} />
        <Route exact path={`/${lng}${lngRoutes.classicalAlbumsDetails}`} component={AlbumClassicalDetail} />
        <Route exact path={`/${lng}${lngRoutes.jazzArtists}`} component={Artists} />
        <Route exact path={`/${lng}${lngRoutes.jazzArtistsDetails}`} component={ArtistDetails} />
        <Route exact path={`/${lng}${lngRoutes.composersCategory}`} component={ComposersCategory} />
        <Route exact path={`/${lng}${lngRoutes.jazzPlaylistsPaginated}`} component={JazzPlaylists} />
        <Route exact path={`/${lng}${lngRoutes.jazzPlaylists}`} component={JazzPlaylists} />
        <Route exact path={`/${lng}${lngRoutes.jazzPlaylistDetails}`} component={JazzPlaylistDetails} />
        <Route exact path={`/${lng}${lngRoutes.jazzPlaylistDetailsWithTitle}`} component={JazzPlaylistDetails} />
        <Route exact path={`/${lng}${lngRoutes.paginatedPersonPage}`} component={Person} />
        <Route exact path={`/${lng}${lngRoutes.personPage}`} component={Person} />
        <Route exact path={`/${lng}${lngRoutes.giftcardPurchase}`} component={GiftcardPurchase} />
        <Route exact path={`/${lng}${lngRoutes.giftcardActivation}`} component={VoucherActivation} />
        <Route exact path={`/${lng}${lngRoutes.partnerVoucherActivation}`} component={PartnerVoucherActivation} />
        <Route exact path={`/${lng}${lngRoutes.interpretation}`} component={Interpretation} />
        <Route exact path={`/${lng}${lngRoutes.interpretationNoYear}`} component={Interpretation} />
        <Route exact path={`/${lng}/embed`} component={Embed} />
        <Route exact path={`/${lng}${lngRoutes.simpleLogin}`} component={SimpleLogin} />
        <Route exact path={`/${lng}${lngRoutes.circleLogin}`} render={(props) => <SimpleLogin {...props} isCircle />} />
        <Route exact path={`/${lng}/circle-auth`} component={CircleLogin} />
        <Route exact path={`/${lng}${lngRoutes.classicalPartnerPage}`} render={(props) => <PartnerPage {...props} isJazz={false} />} />
        <Route exact path={`/${lng}${lngRoutes.jazzPartnerPage}`} render={(props) => <PartnerPage {...props} isJazz={true} />} />
        <Route exact path={`/${lng}${lngRoutes.collectionsPaginated}`} render={(props) => <Collections {...props} isJazz={false} />} />
        <Route exact path={`/${lng}${lngRoutes.jazzCollectionsPaginated}`} render={(props) => <Collections {...props} isJazz={true} />} />
        <Route exact path={`/${lng}${lngRoutes.collections}`} render={(props) => <Collections {...props} isJazz={false} />} />
        <Route exact path={`/${lng}${lngRoutes.jazzCollections}`} render={(props) => <Collections {...props} isJazz={true} />} />
        <PrivateRoute exact path={`/${lng}${lngRoutes.cancelSubscription}`} component={CancelSubscription} />
        <PrivateRoute exact path={`/${lng}${lngRoutes.cancellationConfirmed}`} component={CancellationConfirmed} />
        <Route exact path={`/${lng}${lngRoutes.siteMap}`} component={Sitemap} />
        <Route exact path={`/${lng}${lngRoutes.siteMapJazz}`} component={Sitemap} />
        <Redirect from="/help-2" to="/" />
        <Route component={NoMatch} />
      </Switch>
    </Suspense>
  );
};

export default ApplicationRoutes;
