const routes = {
  en: {
    articleDetails: "/articles/:id/:title",
    jazzArticles: "/jazz/articles",
    jazzArticleDetails: "/jazz/articles/:id/:title",
    playlistDetailsOld: "/playlist/:title",
    jazzLandingPage: "/jazz",
    classicalLandingPage: "/",
    jazzWelcome: "/jazz/welcome",
    ardoizInscription: "/ardoiz-inscription",
    taProgramme: "/ta-programme/:title",
    taCompositeurs: "/ta-compositeurs/:title",
    taJazzmen: "/ta-jazzmen/:title",
    taOeuvres: "/ta-oeuvres/:title",
    taTelechargement: "/ta-telechargement/:title",
    taInstruments: "/ta-instruments/:title",
    taQuiz: "/ta-quiz/:title",
    toutApprendre: "/tout-apprendre",
    expired: "/expired",
    noplan: "/noplan",
    registration: "/registration",
    confidentiality: "/confidentiality",
    forgotPassword: "/forgot-password",
    generatingNewPassword: "/forgot-password/generating-new-one/:code",
    jazzGeneratingNewPassword: "/jazz/forgot-password/generating-new-one/:code",
    whoAreWe: "/who-are-we",
    jazzWhoAreWe: "/jazz/who-are-we",
    subscribe: "/subscribe",
    subscribeStep: "/subscribe/:step",
    checkout: "/checkout",
    playlistDetails: "/playlists/:id",
    playlistDetailsWithTitle: "/playlists/:id/:title",
    videoDetails: "/videos/:id/:title",
    jazzVideoDetails: "/jazz/videos/:id/:title",
    workDetail: "/works/:id",
    workDetailWithTitle: "/works/:id/:title",
    workDetailWithTitleAndComposer: "/works/:id/:title/:composer",
    confirmpaypal: "/confirmpaypal",
    classicalHome: "/home",
    jazzHome: "/jazz/home",
    articles: "/articles",
    composers: "/composers",
    welcome: "/welcome",
    welcomeRadio: "/welcomeRadio",
    playlists: "/playlists",
    playlistsPaginated: "/playlists/page/:pageNumber",
    podcastsJazz: "/jazz/podcasts",
    podcasts: "/podcasts",
    podcastDetails: "/podcasts/:id",
    podcastDetailsJazz: "/jazz/podcasts/:id",
    chapterDetails: "/chapter/:id",
    chapterDetailsJazz: "/jazz/chapter/:id",
    account: "/account",
    upgrade: "/upgrade",
    congratulations: "/congratulations",
    jazzCongratulations: "/jazz/congratulations",
    library: "/library",
    libraryPlaylistDetails: "/library/playlist/:id",
    videos: "/videos",
    jazzVideos: "/jazz/videos",
    search: "/search",
    jazzSearch: "/jazz/search",
    jazzAlbums: "/jazz/albums",
    jazzAlbumsDetails: "/jazz/albums/:id/:title",
    classicalAlbumsDetails: "/albums/:id/:title",
    jazzArtists: "/jazz/artists",
    jazzArtistsDetails: "/jazz/profile/:id/:slug",
    jazzPlaylists: "/jazz/playlists",
    jazzPlaylistsPaginated: "/jazz/playlists/page/:pageNumber",
    jazzPlaylistDetails: "/jazz/playlists/:id",
    jazzPlaylistDetailsWithTitle: "/jazz/playlists/:id/:title",
    personPage: "/profile/:id/:name",
    paginatedPersonPage: "/profile/:id/:name/page/:pageNumber",
    interpretation: "/profile/:id/:name/recording/:workId/:workName/:year",
    interpretationNoYear: "/profile/:id/:name/recording/:workId/:workName",
    giftcardPurchase: "/gift-card",
    giftcardPurchaseSuccess: "/gift-card/success",
    giftcardActivation: "/gift-card/activate/:voucherCode",
    partnerVoucherActivation: "/gift-card/partner/qoqa",
    simpleLogin: "/login",
    circleLogin: "/login-circle",
    collectionsPaginated: "/collections/page/:pageNumber",
    jazzCollectionsPaginated: "/jazz/collections/page/:pageNumber",
    collections: "/collections",
    jazzCollections: "/jazz/collections",
    classicalPartnerPage: "/collection/:partnerName",
    jazzPartnerPage: "/jazz/collection/:partnerName",
    cancelSubscription: "/account/cancel",
    cancellationConfirmed: "/account/cancel/confirmed",
    composersCategory: "/composers/:category",
    articlesPaginated: "/articles/page/:pageNumber",
    jazzArticlesPaginated: "/jazz/articles/page/:pageNumber",
    videosPaginated: "/videos/page/:pageNumber",
    jazzVideosPaginated: "/jazz/videos/page/:pageNumber",
    continueListeningJazz: "/jazz/category/continue-listening",
    continueListening: "/category/continue-listening",
    recommendedAlbumsJazz: "/jazz/category/recommended-albums",
    recommendedAlbums: "/category/recommended-albums",
    popularAlbumsJazz: "/jazz/category/popular-albums",
    popularAlbums: "/category/popular-albums",
    personalRecommendationsJazz: "/jazz/category/personal-mix",
    personalRecommendations: "/category/personal-mix",
    youMayAlsoLoveJazz: "/jazz/category/you-may-also-love",
    youMayAlsoLove: "/category/you-may-also-love",
    popularVideosJazz: "/jazz/category/popular-videos",
    popularVideos: "/category/popular-videos",
    jazzCategory: "/jazz/:type/category/:id",
    category: "/:type/category/:id",
    siteMap: "/sitemap",
    siteMapJazz: "/jazz/sitemap",
  },
  fr: {
    articleDetails: "/articles/:id/:title",
    jazzArticleDetails: "/jazz/articles/:id/:title",
    playlistDetailsOld: "/playlist/:title",
    jazzLandingPage: "/jazz",
    classicalLandingPage: "/",
    jazzWelcome: "/jazz/bienvenue",
    ardoizInscription: "/ardoiz-inscription",
    taProgramme: "/ta-programme/:title",
    taCompositeurs: "/ta-compositeurs/:title",
    taJazzmen: "/ta-jazzmen/:title",
    taOeuvres: "/ta-oeuvres/:title",
    taTelechargement: "/ta-telechargement/:title",
    taInstruments: "/ta-instruments/:title",
    taQuiz: "/ta-quiz/:title",
    toutApprendre: "/tout-apprendre",
    expired: "/expire",
    noplan: "/sans-plan",
    registration: "/inscription",
    confidentiality: "/confidentialite",
    forgotPassword: "/mot-de-passe-perdu",
    generatingNewPassword: "/mot-de-passe-perdu/generation/:code",
    jazzGeneratingNewPassword: "/jazz/mot-de-passe-perdu/generation/:code",
    whoAreWe: "/qui-sommes-nous",
    jazzWhoAreWe: "/jazz/qui-sommes-nous",
    subscribe: "/s-inscrire",
    subscribeStep: "/s-inscrire/:step",
    checkout: "/paiement",
    playlistDetails: "/playlists/:id",
    playlistDetailsWithTitle: "/playlists/:id/:title",
    videoDetails: "/videos/:id/:title",
    jazzVideoDetails: "/jazz/videos/:id/:title",
    workDetail: "/oeuvres/:id",
    workDetailWithTitle: "/oeuvres/:id/:title",
    workDetailWithTitleAndComposer: "/oeuvres/:id/:title/:composer",
    confirmpaypal: "/confirmpaypal",
    jazzArticles: "/jazz/articles",
    classicalHome: "/accueil",
    jazzHome: "/jazz/accueil",
    articles: "/articles",
    composers: "/compositeurs",
    welcome: "/bienvenue",
    welcomeRadio: "/bienvenueRadio",
    playlists: "/playlists",
    playlistsPaginated: "/playlists/page/:pageNumber",
    podcasts: "/podcasts",
    podcastsJazz: "/jazz/podcasts",
    podcastDetails: "/podcasts/:id",
    podcastDetailsJazz: "/jazz/podcasts/:id",
    chapterDetails: "/chapter/:id",
    chapterDetailsJazz: "/jazz/chapter/:id",
    account: "/compte",
    upgrade: "/mise-a-niveau",
    congratulations: "/felicitations",
    jazzCongratulations: "/jazz/felicitations",
    library: "/bibliotheque",
    libraryPlaylistDetails: "/bibliotheque/playlist/:id",
    videos: "/videos",
    jazzVideos: "/jazz/videos",
    search: "/recherche",
    jazzSearch: "/jazz/recherche",
    jazzAlbums: "/jazz/albums",
    jazzAlbumsDetails: "/jazz/albums/:id/:title",
    classicalAlbumsDetails: "/albums/:id/:title",
    jazzArtists: "/jazz/artistes",
    jazzArtistsDetails: "/jazz/profil/:id/:slug",
    jazzPlaylists: "/jazz/playlists",
    jazzPlaylistsPaginated: "/jazz/playlists/page/:pageNumber",
    jazzPlaylistDetails: "/jazz/playlists/:id",
    jazzPlaylistDetailsWithTitle: "/jazz/playlists/:id/:title",
    personPage: "/profil/:id/:name",
    paginatedPersonPage: "/profil/:id/:name/page/:pageNumber",
    interpretation: "/profil/:id/:name/enregistrement/:workId/:workName/:year",
    interpretationNoYear: "/profil/:id/:name/enregistrement/:workId/:workName",
    giftcardPurchase: "/cartecadeau",
    giftcardPurchaseSuccess: "/cartecadeau/reussi",
    giftcardActivation: "/cartecadeau/activer/:voucherCode",
    partnerVoucherActivation: "/cartecadeau/partenaire/qoqa",
    simpleLogin: "/connexion",
    circleLogin: "/connexion-circle",
    collectionsPaginated: "/collections/page/:pageNumber",
    jazzCollectionsPaginated: "/jazz/collections/page/:pageNumber",
    collections: "/collections",
    jazzCollections: "/jazz/collections",
    classicalPartnerPage: "/collection/:partnerName",
    jazzPartnerPage: "/jazz/collection/:partnerName",
    cancelSubscription: "/compte/annuler",
    cancellationConfirmed: "/compte/annuler/confirme",
    composersCategory: "/compositeurs/:category",
    articlesPaginated: "/articles/page/:pageNumber",
    jazzArticlesPaginated: "/jazz/articles/page/:pageNumber",
    videosPaginated: "/videos/page/:pageNumber",
    jazzVideosPaginated: "/jazz/videos/page/:pageNumber",
    continueListeningJazz: "/jazz/categorie/continuez-a-ecouter",
    continueListening: "/categorie/continuez-a-ecouter",
    recommendedAlbumsJazz: "/jazz/categorie/albums-recommandations",
    recommendedAlbums: "/categorie/albums-recommandations",
    popularAlbumsJazz: "/jazz/categoire/nos-meilleurs-albums",
    popularAlbums: "/categoire/nos-meilleurs-albums",
    personalRecommendationsJazz: "/jazz/categorie/mix-personnel",
    personalRecommendations: "/categorie/mix-personnel",
    youMayAlsoLoveJazz: "/jazz/categorie/nos-recommandations-pour-vous",
    youMayAlsoLove: "/categorie/nos-recommandations-pour-vous",
    popularVideosJazz: "/jazz/categoire/nos-meilleures-videos",
    popularVideos: "/categoire/nos-meilleures-videos",
    jazzCategory: "/jazz/:type/categorie/:id",
    category: "/:type/categorie/:id",
    siteMap: "/plan-du-site",
    siteMapJazz: "/jazz/plan-du-site",
  },
};

export const accessRoutes = {
  en: {
    articleDetails: "/en/articles",
    jazzArticleDetails: "/en/jazz/articles",
    playlistDetailsOld: "/en/playlist",
    jazzLandingPage: "/en/jazz",
    classicalLandingPage: "/en",
    jazzWelcome: "/en/jazz/welcome",
    ardoizInscription: "/en/ardoiz-inscription",
    taCompositeurs: "/en/ta-compositeurs",
    taJazzmen: "/en/ta-jazzmen",
    taOeuvres: "/en/ta-oeuvres",
    taTelechargement: "/en/ta-telechargement",
    taInstruments: "/en/ta-instruments",
    taQuiz: "/en/ta-quiz",
    toutApprendre: "/en/tout-apprendre",
    expired: "/en/expired",
    noplan: "/en/noplan",
    registration: "/en/registration",
    confidentiality: "/en/confidentiality",
    forgotPassword: "/en/forgot-password",
    generatingNewPassword: "/en/forgot-password/generating-new-one",
    jazzGeneratingNewPassword: "/en/jazz/forgot-password/generating-new-one",
    whoAreWe: "/en/who-are-we",
    jazzWhoAreWe: "/en/jazz/who-are-we",
    subscribe: "/en/subscribe",
    subscribeStep: "/en/subscribe",
    checkout: "/en/checkout",
    playlistDetails: "/en/playlists",
    videoDetails: "/en/videos",
    jazzVideoDetails: "/en/jazz/videos",
    workDetail: "/en/works",
    workDetailWithTitle: "/en/works",
    confirmpaypal: "/en/confirmpaypal",
    jazzArticles: "/en/jazz/articles",
    classicalHome: "/en/home",
    jazzHome: "/en/jazz/home",
    articles: "/en/articles",
    composers: "/en/composers",
    welcome: "/en/welcome",
    welcomeRadio: "/en/welcomeRadio",
    playlists: "/en/playlists",
    podcasts: "/en/podcasts",
    podcastsJazz: "/en/jazz/podcasts",
    podcastDetails: "/en/podcasts",
    podcastDetailsJazz: "/en/jazz/podcasts",
    chapterDetails: "/en/chapter",
    chapterDetailsJazz: "/en/jazz/chapter",
    account: "/en/account",
    upgrade: "/en/upgrade",
    congratulations: "/en/congratulations",
    jazzCongratulations: "/en/jazz/congratulations",
    library: "/en/library",
    libraryPlaylistDetails: "/en/library/playlist",
    videos: "/en/videos",
    jazzVideos: "/en/jazz/videos",
    search: "/en/search",
    jazzSearch: "/en/jazz/search",
    jazzAlbums: "/en/jazz/albums",
    jazzAlbumsDetails: "/en/jazz/albums",
    classicalAlbumsDetails: "/en/albums",
    jazzArtists: "/en/jazz/artists",
    jazzArtistsDetails: "/en/jazz/profile",
    jazzPlaylists: "/en/jazz/playlists",
    jazzPlaylistDetails: "/en/jazz/playlists",
    jazzPlaylistDetailsWithTitle: "/en/jazz/playlists",
    personPage: "/en/profile",
    giftcardPurchase: "/en/gift-card",
    giftcardPurchaseSuccess: "/en/gift-card/success",
    simpleLogin: "/login",
    circleLogin: "/login-circle",
    collectionsPaginated: "/collections/page/:pageNumber",
    jazzCollectionsPaginated: "/jazz/collections/page/:pageNumber",
    collections: "/en/collections",
    jazzCollections: "/en/jazz/collections",
    classicalPartnerPage: "/en/collection",
    jazzPartnerPage: "/en/jazz/collection",
    recording: "recording",
    cancelSubscription: "/account/cancel",
    cancellationConfirmed: "/account/cancel/confirmed",
    composersCategory: "/en/composers",
    articlesPaginated: "/en/articles/page/:pageNumber",
    jazzArticlesPaginated: "/en/jazz/articles/page/:pageNumber",
    videosPaginated: "/en/videos/page/:pageNumber",
    jazzVideosPaginated: "/en/jazz/videos/page/:pageNumber",
    continueListeningJazz: "/en/jazz/category/continue-listening",
    continueListening: "/en/category/continue-listening",
    recommendedAlbumsJazz: "/en/jazz/category/recommended-albums",
    recommendedAlbums: "/en/category/recommended-albums",
    popularAlbumsJazz: "/en/jazz/category/popular-albums",
    popularAlbums: "/en/category/popular-albums",
    personalRecommendationsJazz: "/en/jazz/category/personal-mix",
    personalRecommendations: "/en/category/personal-mix",
    youMayAlsoLoveJazz: "/en/jazz/category/you-may-also-love",
    youMayAlsoLove: "/en/category/you-may-also-love",
    popularVideosJazz: "/en/jazz/category/popular-videos",
    popularVideos: "/en/category/popular-videos",
    jazzCategory: "/en/jazz/:type/category",
    category: "/en/:type/category",
    siteMap: "/en/sitemap",
    siteMapJazz: "/en/jazz/sitemap",
  },
  fr: {
    articleDetails: "/fr/articles",
    jazzArticleDetails: "/fr/jazz/articles",
    playlistDetailsOld: "/fr/playlist/:title",
    jazzLandingPage: "/fr/jazz",
    classicalLandingPage: "/fr",
    jazzWelcome: "/fr/jazz/bienvenue",
    ardoizInscription: "/fr/ardoiz-inscription",
    taProgramme: "/fr/ta-programme",
    taCompositeurs: "/fr/ta-compositeurs",
    taJazzmen: "/fr/ta-jazzmen",
    taOeuvres: "/fr/ta-oeuvres",
    taTelechargement: "/fr/ta-telechargement",
    taInstruments: "/fr/ta-instruments",
    taQuiz: "/fr/ta-quiz/:title",
    toutApprendre: "/fr/tout-apprendre",
    expired: "/fr/expire",
    noplan: "/fr/sans-plan",
    registration: "/fr/inscription",
    confidentiality: "/fr/confidentialite",
    forgotPassword: "/fr/mot-de-passe-perdu",
    generatingNewPassword: "/fr/mot-de-passe-perdu/generation",
    jazzGeneratingNewPassword: "/fr/jazz/mot-de-passe-perdu/generation",
    whoAreWe: "/fr/qui-sommes-nous",
    jazzWhoAreWe: "/fr/jazz/qui-sommes-nous",
    subscribe: "/fr/s-inscrire",
    subscribeStep: "/fr/s-inscrire",
    checkout: "/fr/paiement",
    playlistDetails: "/fr/playlists",
    playlistDetailsWithTitle: "/fr/playlists",
    videoDetails: "/fr/videos",
    jazzVideoDetails: "/fr/jazz/videos",
    workDetail: "/fr/oeuvres",
    workDetailWithTitle: "/fr/oeuvres",
    confirmpaypal: "/fr/confirmpaypal",
    jazzArticles: "/fr/jazz/articles",
    classicalHome: "/fr/accueil",
    jazzHome: "/fr/jazz/accueil",
    articles: "/fr/articles",
    composers: "/fr/compositeurs",
    welcome: "/fr/bienvenue",
    welcomeRadio: "/fr/bienvenueRadio",
    playlists: "/fr/playlists",
    podcasts: "/fr/podcasts",
    podcastsJazz: "/fr/jazz/podcasts",
    podcastDetails: "/fr/podcasts",
    podcastDetailsJazz: "/fr/jazz/podcasts",
    chapterDetails: "/fr/chapter",
    chapterDetailsJazz: "/fr/jazz/chapter",
    account: "/fr/compte",
    upgrade: "/fr/mise-a-niveau",
    congratulations: "/fr/felicitations",
    jazzCongratulations: "/fr/jazz/felicitations",
    library: "/fr/bibliotheque",
    libraryPlaylistDetails: "/fr/bibliotheque/playlist",
    videos: "/fr/videos",
    jazzVideos: "/fr/jazz/videos",
    search: "/fr/recherche",
    jazzSearch: "/fr/jazz/recherche",
    jazzAlbums: "/fr/jazz/albums",
    jazzAlbumsDetails: "/fr/jazz/albums",
    classicalAlbumsDetails: "/fr/albums",
    jazzArtists: "/fr/jazz/artistes",
    jazzArtistsDetails: "/fr/jazz/profil",
    jazzPlaylists: "/fr/jazz/playlists",
    jazzPlaylistDetails: "/fr/jazz/playlists",
    jazzPlaylistDetailsWithTitle: "/fr/jazz/playlists",
    personPage: "/fr/profil",
    giftcardPurchase: "/fr/cartecadeau",
    giftcardPurchaseSuccess: "/fr/cartecadeau/reussi",
    simpleLogin: "/connexion",
    circleLogin: "/connexion-circle",
    collectionsPaginated: "/collections/page/:pageNumber",
    jazzCollectionsPaginated: "/jazz/collections/page/:pageNumber",
    collections: "/fr/collections",
    jazzCollections: "/fr/jazz/collections",
    classicalPartnerPage: "/fr/collection",
    jazzPartnerPage: "/fr/jazz/collection",
    recording: "enregistrement",
    cancelSubscription: "/compte/annuler",
    cancellationConfirmed: "/compte/annuler/confirme",
    composersCategory: "/fr/compositeurs",
    articlesPaginated: "/fr/articles/page/:pageNumber",
    jazzArticlesPaginated: "/fr/jazz/articles/page/:pageNumber",
    videosPaginated: "/fr/videos/page/:pageNumber",
    jazzVideosPaginated: "/fr/jazz/videos/page/:pageNumber",
    continueListeningJazz: "/fr/jazz/categorie/continuez-a-ecouter",
    continueListening: "/fr/categorie/continuez-a-ecouter",
    recommendedAlbumsJazz: "/fr/jazz/categorie/albums-recommandations",
    recommendedAlbums: "/fr/categorie/albums-recommandations",
    popularAlbumsJazz: "/fr/jazz/categoire/nos-meilleurs-albums",
    popularAlbums: "/fr/categoire/nos-meilleurs-albums",
    personalRecommendationsJazz: "/fr/jazz/categorie/mix-personnel",
    personalRecommendations: "/fr/categorie/mix-personnel",
    youMayAlsoLoveJazz: "/fr/jazz/categorie/nos-recommandations-pour-vous",
    youMayAlsoLove: "/fr/categorie/nos-recommandations-pour-vous",
    popularVideosJazz: "/fr/jazz/categoire/nos-meilleures-videos",
    popularVideos: "/fr/categoire/nos-meilleures-videos",
    jazzCategory: "/fr/jazz/:type/categorie",
    category: "/fr/:type/categorie",
    siteMap: "/fr/plan-du-site",
    siteMapJazz: "/fr/jazz/plan-du-site",
  },
};

export default routes;
