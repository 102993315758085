import axios from "axios";

const getFlacAllowed = async () => {
  try {
    const tokenHash = JSON.parse(localStorage.getItem("loginData")).token;
    const token = `Bearer ${tokenHash}`;
    const url = `${process.env.REACT_APP_VIALMA_API}/streams/allow_flac`;
    const response = await axios.get(url, { headers: { Authorization: token } });
    if (response && response.data) {
      if (response.data === true) {
        return true;
      }
      return false;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export { getFlacAllowed };
