import { combineReducers } from "redux";
import miniPlayerReducer from "./modules/MiniPlayer/reducer";
import configReducer from "./modules/Config/reducer";
import composersReducer from "./modules/Composers/reducer";
import worksReducer from "./modules/Works/reducer";
import radiosReducer from "./modules/Radios/reducer";
import searchReducer from "./modules/Search/reducer";
import libraryReducer from "./modules/Library/reducer";
import trackOfTheDayReducer from "./modules/TrackOfTheDay/reducer";
import accountReducer from "./modules/Account/reducer";
import videosReducer from "./modules/Videos/reducer";
import albumsJazzReducer from "./modules/AlbumsJazz/reducer";
import artistsReducer from "./modules/Artists/reducer";
import jazzPlaylistsReducer from "./modules/JazzPlaylists/reducer";
import podcastsReducer from "./modules/Podcasts/reducer";
import favouritesReducer from "./modules/Favourites/reducer";

const rootReducer = combineReducers({
  miniPlayer: miniPlayerReducer,
  config: configReducer,
  composers: composersReducer,
  works: worksReducer,
  radios: radiosReducer,
  search: searchReducer,
  library: libraryReducer,
  trackOfTheDay: trackOfTheDayReducer,
  account: accountReducer,
  videos: videosReducer,
  albumsJazz: albumsJazzReducer,
  artists: artistsReducer,
  jazzPlaylists: jazzPlaylistsReducer,
  podcasts: podcastsReducer,
  favourites: favouritesReducer,
});

export default rootReducer;
