import React, { useState, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import playRadio from "assets/images/play_radio.svg";
import jazzPlayRadio from "assets/images/jazz_play_radio.svg";
import classicalPauseIcon from "assets/images/pause_red.svg";
import jazzPauseIcon from "assets/images/pause_blue.svg";
import getAbsolutePath from "utils/getAbsolutePath";
import { dispatchUpdateMiniPlayer } from "modules/MiniPlayer/actions";
import api from "utils/apiManager";
import { isAllowedToPlay } from "utils/checkHasAccess";
import { withRouter } from "react-router";
import { dispatchShowSignupFreeTrialModal, dispatchUpdateShowClassicModal } from "modules/Config/actions";
import notificationManager from "utils/notificationManager";
import { isTrackJazz } from "utils/metaDataTools";
import { accessRoutes } from "components/ApplicationRoutes/routes";
import { getLangSelector } from "modules/Config/selectors";
import { getMiniPlayerSelector } from "modules/MiniPlayer/selectors";
import { getIsCurrentlyPlayingOrFetching } from "utils/storeUtils";
import { CLASSICAL_NAME, JAZZ_NAME } from "utils/nameVariables";

const InteractivePlayIcon = ({
  t,
  playlistId,
  isJazz,
  pageOrigin,
  isUserPlaylist,
  lang,
  preFetchPlaylistFunction,
  setShowClassicModal,
  artistId,
  playTrackingFunction = () => {},
  mediaProps = null,
  // added to force re-render component on miniplayer change
  miniPlayer,
}) => {
  const [isInitialising, setInitialising] = useState(false);
  const [hasAttemptedAltUniverse, setHasAttemptedAltUniverse] = useState(false);

  const playIcon = isJazz ? getAbsolutePath(jazzPlayRadio) : getAbsolutePath(playRadio);
  const pauseIcon = isJazz ? getAbsolutePath(jazzPauseIcon) : getAbsolutePath(classicalPauseIcon);
  const dispatch = useDispatch();

  const { isCurrentlyPlaying, isCurrentlyLoading } = getIsCurrentlyPlayingOrFetching(playlistId);

  const playPlaylist = (playlistData) => {
    //currently, only composer icons use preFetchPlaylistFunction which returns an array
    //in the future, could make it prop similar to isUserPlaylist
    const dataTrack = preFetchPlaylistFunction ? playlistData : isUserPlaylist ? playlistData.playLists : playlistData.tracks;
    const urlsToUseHeaderJazz = [accessRoutes[lang].library, accessRoutes[lang].classicalHome, accessRoutes[lang].jazzHome];
    let playJazzMode;
    if (!urlsToUseHeaderJazz.some((url) => window.location.pathname.includes(url))) {
      playJazzMode = isJazz;
    } else {
      playJazzMode = isTrackJazz(dataTrack[0]);
    }

    if (dataTrack && dataTrack.length) {
      if (isAllowedToPlay(dataTrack[0])) {
        let miniPlayerData = {
          playing: true,
          indexOfTrack: 0,
          url: "blank",
          dataTrack: dataTrack,
          isWebPlayer: true,
          pictureUrl: playlistData.image_url || null,
          composerImage: null,
          composerName: null,
          composerSlug: null,
          composerId: null,
          jazzMode: playJazzMode,
          pageOrigin,
          playlist: playlistData.title,
          playlistId: preFetchPlaylistFunction ? null : playlistId,
          mediaId: playlistId,
          mediaProps,
        };

        dispatch(dispatchUpdateMiniPlayer(miniPlayerData));
      }
    } else {
      notificationManager.showMessage("none", t("PLAY_ICON_EMPTY_PLAYLIST"));
    }
    setInitialising(false);
  };

  const handlePlayError = (error) => {
    if (error?.response?.data.includes?.("User not allowed to")) {
      setShowClassicModal(true);
      setInitialising(false);
    } else if (!hasAttemptedAltUniverse && error?.message === "retry") {
      onPlayClick(null, isJazz ? CLASSICAL_NAME : JAZZ_NAME);
      setHasAttemptedAltUniverse(true);
    }
  };

  const onPlayClick = async (event, altUniverse) => {
    event?.preventDefault?.();
    if (!isCurrentlyPlaying) {
      setInitialising(true);
      playTrackingFunction(event);
    }

    if (isCurrentlyPlaying) {
      dispatch(dispatchUpdateMiniPlayer({ playing: false }));
      return;
    }

    if (preFetchPlaylistFunction) {
      try {
        const resp = altUniverse
          ? await preFetchPlaylistFunction(artistId, altUniverse)
          : artistId
          ? await preFetchPlaylistFunction(artistId)
          : await preFetchPlaylistFunction();
        playPlaylist(Array.isArray(resp) ? resp : resp.data);
        return;
      } catch (error) {
        handlePlayError(error);
      }
    }

    let headers;
    const loginData = JSON.parse(localStorage.getItem("loginData"));
    if (loginData !== null) {
      const token = `Bearer ${loginData.token}`;
      headers = {
        Authorization: token,
      };
    }
    const playlistUrl = isUserPlaylist
      ? `${process.env.REACT_APP_VIALMA_API}/playlists/${playlistId}/pistes?lang=en&ver=2`
      : `${process.env.REACT_APP_VIALMA_API}/radios/${playlistId}?lang=${lang}&universe=${isJazz ? "jazz" : "classical"}`;
    try {
      const { data } = await api({ url: playlistUrl, headers });
      if (data) {
        playPlaylist(data);
      }
    } catch (error) {
      handlePlayError(error);
    }
  };

  return (
    <Fragment>
      <div
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      ></div>
      <div
        className={`interactive-play-icon ${isInitialising ? "initialising" : ""} ${isCurrentlyLoading ? "loading" : ""} ${
          isCurrentlyPlaying ? "playing" : ""
        }`}
        onClick={onPlayClick}
        data-cy={`play-button-${playlistId}`}
      >
        {isCurrentlyPlaying ? <img src={pauseIcon} alt="pause" /> : <img src={playIcon} alt="play" />}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  lang: getLangSelector(state),
  miniPlayer: getMiniPlayerSelector(state),
});
const mapDispatchToProps = (dispatch) => ({
  setShowClassicModal: (showClassicModal) => {
    dispatch(dispatchUpdateShowClassicModal(showClassicModal));
  },
  setShowSignupFreeTrialModal: (showSignupFreeTrialModal, headerText) => {
    dispatch(dispatchShowSignupFreeTrialModal(showSignupFreeTrialModal, headerText));
  },
});

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(InteractivePlayIcon)));
