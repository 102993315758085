import produce from 'immer';
import {
  SET_TRACK_OF_THE_DAY,
} from './constants';

const initialState = {
  trackOfTheDay: null,
};

const trackOfDayReducer = function (state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SET_TRACK_OF_THE_DAY:
        draft.trackOfTheDay = action.trackOfTheDay
        break;
      default:
        return;
    }
  });
}

export default trackOfDayReducer;