import axios from "axios";
import api from "utils/apiManager";
import { FAVOURITE_ALBUMS_CACHE_KEY, FAVOURITE_COLLECTIONS_CACHE_KEY, FAVOURITE_VIALMA_PLAYLISTS_CACHE_KEY, FAVOURITE_VIDEOS_CACHE_KEY } from "utils/cacheKeys";
import { CLASSICAL_NAME, JAZZ_NAME } from "utils/nameVariables";
import { fetchLang, isHeaderJazzUniverse, isLoggedIn } from "utils/storeUtils";
import { getHeaders } from "..";
import {
  createVoucherUrl,
  getAlsoLikedUrl,
  getQuizContentByTypeUrl,
  getSearchResultsUrl,
  getWelcomeQuiz,
  likeOrUnlikeAlbum,
  likeOrUnlikeCollection,
  likeOrUnlikePlaylist,
  likeOrUnlikeVideo,
  submitQuizResultUrl,
} from "../urls";

export const apiLibraryGetAlsoLiked = async (id) => {
  const lang = fetchLang();
  const headers = getHeaders();
  const universe = isHeaderJazzUniverse() ? JAZZ_NAME : CLASSICAL_NAME;
  const alsoLikeUrl = getAlsoLikedUrl(id, lang, universe);

  try {
    const resp = await api({ url: alsoLikeUrl, headers });
    if (resp.status === 200) {
      return resp;
    }
    return null;
  } catch (error) {
    return false;
  }
};

export const apiLibraryLikeUnlikeAlbum = async (method, id) => {
  const isLogged = isLoggedIn();
  if (!isLogged) return;

  const headers = getHeaders();
  const lang = fetchLang();
  const likeOrUnlikeAlbumUrl = likeOrUnlikeAlbum(id, lang);

  let response;
  try {
    response = await axios({
      method,
      url: likeOrUnlikeAlbumUrl,
      headers,
    }).then((resp) => {
      api.storage.remove(FAVOURITE_ALBUMS_CACHE_KEY);
      return resp;
    });
  } catch (error) {
    response = false;
  } finally {
    return response;
  }
};
export const apiLibraryLikeUnlikePlaylist = async (method, id) => {
  const isLogged = isLoggedIn();
  if (!isLogged) return;

  const headers = getHeaders();
  const lang = fetchLang();
  const likeOrUnlikePlaylistUrl = likeOrUnlikePlaylist(id, lang);

  let response;
  try {
    response = await axios({
      method,
      url: likeOrUnlikePlaylistUrl,
      headers,
    }).then((resp) => {      
      api.storage.remove(FAVOURITE_VIALMA_PLAYLISTS_CACHE_KEY);
      return resp;
    });
  } catch (error) {
    response = false;
  } finally {
    return response;
  }
};

export const apiLibraryLikeUnlikeVideo = async (method, id) => {
  const isLogged = isLoggedIn();
  if (!isLogged) return;

  const headers = getHeaders();
  const lang = fetchLang();
  const likeOrUnlikeVideoUrl = likeOrUnlikeVideo(id, lang);

  let response;
  try {
    response = await axios({
      method,
      url: likeOrUnlikeVideoUrl,
      headers,
    }).then((resp) => {
      api.storage.remove(FAVOURITE_VIDEOS_CACHE_KEY);
      return resp;
    });
  } catch (error) {
    response = false;
  } finally {
    return response;
  }
};

export const apiLibraryLikeUnlikeCollection = async (method, id) => {
  const isLogged = isLoggedIn();
  if (!isLogged) return;

  const headers = getHeaders();
  const lang = fetchLang();
  const likeOrUnlikeCollectionUrl = likeOrUnlikeCollection(id, lang);

  let response;
  try {
    response = await axios({
      method,
      url: likeOrUnlikeCollectionUrl,
      headers,
    }).then((resp) => {
      api.storage.remove(FAVOURITE_COLLECTIONS_CACHE_KEY);
      return resp;
    });
  } catch (error) {
    response = false;
  } finally {
    return response;
  }
};
export const apiLibraryCreateVoucher = async (formData) => {
  const lang = fetchLang();
  const createVoucher = createVoucherUrl(lang);
  try {
    const resp = await axios.post(createVoucher, formData, { "Content-Type": "application/x-www-form-urlencoded" });
    if (resp.status === 200) return resp;
    return false;
  } catch (error) {
    return false;
  }
};

export const apiLibraryGetQuizContent = async (type) => {
  const lang = fetchLang();
  const headers = getHeaders();
  const universe = isHeaderJazzUniverse() ? "&universes[]=jazz" : "&universes[]=classical";
  const quizUrl = type ? getQuizContentByTypeUrl(lang, type, universe) : getWelcomeQuiz(lang, universe);
  try {
    const quizContent = await api({ url: quizUrl, headers });
    return quizContent;
  } catch (error) {
    return [];
  }
};

export const apiLibrarySubmitQuiz = async (answersSlug) => {
  const lang = fetchLang();
  const headers = getHeaders();
  const submitUrl = submitQuizResultUrl(lang, answersSlug);
  try {
    const resp = await axios.post(submitUrl, answersSlug, { headers });
    if (resp.status === 200) return resp.data.data.quizResult;
    return false;
  } catch (error) {
    return false;
  }
};

export const apiLibrarySearch = async (searchText) => {
  const lang = fetchLang();
  const headers = getHeaders();
  const universe = isHeaderJazzUniverse(true) ? JAZZ_NAME : CLASSICAL_NAME;
  const searchUrl = getSearchResultsUrl(searchText, lang, universe);
  try {
    const resp = await axios.post(searchUrl, null, { headers });
    if (resp.status === 200) return resp;
  } catch (error) {
    return false;
  }
};
