import { store } from "App";
import axios from "axios";
import orderBy from "lodash/orderBy";
import { dispatchSetFavouritePersons } from "modules/Favourites/actions";
import {
  dispatchSetListeningHistory,
  dispatchSetPlaylists,
  dispatchSetUserLikedAlbums,
  dispatchSetUserLikedCollections,
  dispatchSetUserLikedTracks,
  dispatchSetUserLikedVideos,
  dispatchSetVialmaPlaylists,
} from "modules/Library/actions";
import api from "utils/apiManager";
import {
  FAVOURITE_ALBUMS_CACHE_KEY,
  FAVOURITE_COLLECTIONS_CACHE_KEY,
  FAVOURITE_TRACKS_CACHE_KEY,
  FAVOURITE_VIALMA_PLAYLISTS_CACHE_KEY,
  FAVOURITE_VIDEOS_CACHE_KEY,
} from "utils/cacheKeys";
import { fetchLang, isLoggedIn } from "utils/storeUtils";
import { getHeaders } from "..";
import {
  getFavouritePersonsUrl,
  getFavouriteVialmaPlaylistsUrl,
  getUserLikedAlbums,
  getUserLikedCollections,
  getUserLikedTracks,
  getUserLikedVideos,
  getUserListeningHistory,
  getUserPlaylists,
} from "../urls";

export const apiLibraryGetFavouritePersons = async () => {
  const isLogged = isLoggedIn();
  const headers = getHeaders();
  if (!isLogged) return;
  const personUrl = getFavouritePersonsUrl;
  try {
    const favouritePersons = await axios.get(personUrl, { headers });
    store.dispatch(dispatchSetFavouritePersons(favouritePersons.data));
    return favouritePersons.data;
  } catch (error) {
    store.dispatch(dispatchSetFavouritePersons([]));
    return null;
  }
};

export const apiLibraryGetFavouriteVialmaPlaylists = async () => {
  const headers = getHeaders();
  const lang = fetchLang();
  const favouriteVialmaPlaylistsUrl = getFavouriteVialmaPlaylistsUrl(lang);
  try {
    if (!headers) return;
    const resp = await api({
      url: favouriteVialmaPlaylistsUrl,
      headers,
      id: FAVOURITE_VIALMA_PLAYLISTS_CACHE_KEY,
    });
    store.dispatch(dispatchSetVialmaPlaylists(resp.data));
    return resp;
  } catch (error) {
    return null;
  }
};

export const apiLibraryGetUserPlaylists = async () => {
  const headers = getHeaders();
  const lang = fetchLang();
  const getUserPlayliststUrl = getUserPlaylists(lang);

  try {
    const resp = await axios.get(getUserPlayliststUrl, { headers });

    if (resp.status === 200) {
      const sortedPlaylists = orderBy(resp.data, (item) => Date.parse(item.createdAt.date), ["desc"]);
      store.dispatch(dispatchSetPlaylists(sortedPlaylists));
      return sortedPlaylists;
    }
  } catch (error) {
    return [];
  }
};

export const apiLibraryGetUserListeningHistory = async (limit) => {
  const lang = fetchLang();
  const isLogged = isLoggedIn();
  const headers = getHeaders();
  if (!isLogged) return;
  const listeningHistoryUrl = getUserListeningHistory(lang, limit);
  try {
    const listeningHistory = await axios.get(listeningHistoryUrl, { headers });
    store.dispatch(dispatchSetListeningHistory(listeningHistory.data.data));
    return listeningHistory.data.data;
  } catch (error) {
    store.dispatch(dispatchSetListeningHistory([]));
    return [];
  }
};

export const apiLibraryGetUserLikedAlbums = async (limit) => {
  const isLogged = isLoggedIn();
  if (!isLogged) return;

  const lang = fetchLang();
  const headers = getHeaders();
  const likedAlbumsUrl = getUserLikedAlbums(lang, limit);

  try {
    const resp = await api({ url: likedAlbumsUrl, headers, id: FAVOURITE_ALBUMS_CACHE_KEY });
    if (resp.status === 200) {
      store.dispatch(dispatchSetUserLikedAlbums(resp.data.data.albums));
      return resp.data.data.albums;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const apiLibraryGetUserLikedVideos = async (limit) => {
  const isLogged = isLoggedIn();
  if (!isLogged) return;

  const lang = fetchLang();
  const headers = getHeaders();
  const likedVideosUrl = getUserLikedVideos(lang, limit);

  try {
    const resp = await api({ url: likedVideosUrl, headers, id: FAVOURITE_VIDEOS_CACHE_KEY });
    if (resp.status === 200) {
      store.dispatch(dispatchSetUserLikedVideos(resp.data.data.videos));
      return resp.data.data.videos;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const apiLibraryGetUserLikedCollections = async (limit) => {
  const isLogged = isLoggedIn();
  if (!isLogged) return;

  const lang = fetchLang();
  const headers = getHeaders();
  const likedCollectionsUrl = getUserLikedCollections(lang, limit);

  try {
    const resp = await api({ url: likedCollectionsUrl, headers, id: FAVOURITE_COLLECTIONS_CACHE_KEY });
    if (resp.status === 200) {
      store.dispatch(dispatchSetUserLikedCollections(resp.data.data.partners));
      return resp.data.data.partners;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const apiLibraryGetUserLikedTracks = async () => {
  const isLogged = isLoggedIn();
  if (!isLogged) return;

  const lang = fetchLang();
  const headers = getHeaders();
  const likedTracksUrl = getUserLikedTracks(lang);

  try {
    const resp = await api({ url: likedTracksUrl, headers, id: FAVOURITE_TRACKS_CACHE_KEY });
    if (resp.status === 200) {
      store.dispatch(dispatchSetUserLikedTracks(resp.data));
      return resp.data;
    }
    return null;
  } catch (error) {
    return null;
  }
};
