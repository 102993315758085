const mediaTypeTranslations = (mediaType) => {
  switch (mediaType) {
    case "album":
      return "MEDIATYPE_ALBUM";
    case "article":
      return "MEDIATYPE_ARTICLE";
    case "podcast":
      return "MEDIATYPE_PODCAST";
    case "chapter":
      return "MEDIATYPE_CHAPTER";
    case "playlist":
      return "MEDIATYPE_PLAYLIST";
    case "video":
      return "MEDIATYPE_VIDEO";
    case "artist":
      return "MEDIATYPE_ARTIST";
    default:
      return "";
  }
};

export default mediaTypeTranslations;
