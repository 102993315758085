import React, { useState, useEffect } from "react";
import iconMenuRemovePlaylist from "assets/images/remove_from_playlist.svg";
import getAbsolutePath from "utils/getAbsolutePath";
import TracksHeart from "components/TracksHeart";
import { Link } from "react-router-dom";
import AddToPlaylist from "./AddToPlaylist";

const MenuMorePopupDesktop = (props) => {
  const {
    t,
    listSongs,
    isLogged,
    hideAddToPlaylist,
    addToPlaylist,
    createPlaylist,
    removeFromPlaylistProps,
    isUserPlaylist,
    openDeleteModal,
    track,
    urlOptions,
    onUrlOptionClick,
    getIcon,
    geoRestricted,
    isMiniPlayer,
  } = props;

  const [addTopAdjustment, setAddTopAdjustment] = useState(false);
  const [addBottomAdjustment, setAddBottomAdjustment] = useState(false);

  useEffect(() => {
    const menu = document.getElementById("menu-more-desktop");

    if (menu) {
      const rect = menu.getBoundingClientRect();
      if (rect.top < 50 && !addTopAdjustment) {
        setAddTopAdjustment(true);
      }
    }
  }, [addTopAdjustment]);

  useEffect(() => {
    const menu = document.getElementById("menu-more-desktop");
    const isTrackQueueContentMenu = menu?.closest?.(".list-of-queue");

    if (menu && isTrackQueueContentMenu) {
      const rect = menu.getBoundingClientRect();
      if (isTrackQueueContentMenu?.getBoundingClientRect()?.bottom - rect.bottom < 50 && !addBottomAdjustment) {
        setAddBottomAdjustment(true);
      }
    }
  }, [addBottomAdjustment]);

  return (
    <ul className={`more-menu ${addTopAdjustment ? "top-adjustment" : ""} ${addBottomAdjustment ? "bottom-adjustment" : ""}`} id="menu-more-desktop">
      {isLogged && !hideAddToPlaylist && !geoRestricted && (
        <AddToPlaylist listSongs={listSongs} addToPlaylist={addToPlaylist} createPlaylist={createPlaylist} />
      )}
      {isLogged && removeFromPlaylistProps && isUserPlaylist && (
        <li onClick={() => openDeleteModal(listSongs)}>
          <img src={getAbsolutePath(iconMenuRemovePlaylist)} alt="remove track from playlist" />
          {t("MENUMORE_REMOVE_FROM_PLAYLIST")}
        </li>
      )}

      {isLogged && track && !geoRestricted && <TracksHeart track={track} isMiniPlayerHeart={isMiniPlayer} source="more menu" />}

      {urlOptions &&
        urlOptions.map((option, index) => {
          if (option && option.url && !option.url.includes("null") && !option.url.includes("undefined")) {
            return (
              <Link key={index} to={option.url} onClick={() => onUrlOptionClick(option)}>
                {getIcon(option.iconType)}
                {option.text}
              </Link>
            );
          } else {
            return null;
          }
        })}
    </ul>
  );
};

export default MenuMorePopupDesktop;
