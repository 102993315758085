import { store } from "App";
import axios from "axios";
import { dispatchSetUserCountry } from "modules/Account/actions";
import { dispatchSetFlacStream, dispatchToggleFlacStream } from "modules/Config/actions";
import api from "utils/apiManager";
import { pendoInitHandler } from "utils/pendo";
import { fetchLang, getIsFlacStream, isLoggedIn } from "utils/storeUtils";
import { getHeaders } from "..";
import { getCancelSubUrl, getClientUrl, getDeleteAccountUrl, getSetAudioFormatUrl, getUserCountryUrl, refreshClientCacheUrl } from "../urls";

export const apiLibraryGetUserCountry = async () => {
  const headers = getHeaders();
  const userCountryUrl = getUserCountryUrl;
  try {
    const resp = await api({ url: userCountryUrl, headers });
    if (resp.status === 200) {
      store.dispatch(dispatchSetUserCountry(resp.data));
      return resp.data;
    }
  } catch (error) {
    return false;
  }
};

export const apiLibraryRefreshClientCache = async () => {
  const lang = fetchLang();
  const headers = getHeaders();
  const refreshClientCache = refreshClientCacheUrl(lang);
  try {
    const resp = await axios.get(refreshClientCache, { headers });
    if (resp.status === 200) return true;
    return false;
  } catch (error) {
    return false;
  }
};

export const apiLibraryGetClient = async (tokenProp) => {
  const lang = fetchLang();
  const headers = tokenProp
    ? {
        Authorization: tokenProp,
      }
    : getHeaders();
  const getClient = getClientUrl(lang);

  try {
    const resp = await axios.get(getClient, { headers });
    store.dispatch(dispatchSetFlacStream(resp?.data?.streamQualityWeb === "flac"));
    pendoInitHandler(resp?.data);
    return resp?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const apiLibraryCancelSub = async (reason) => {
  const lang = fetchLang();
  const isLogged = isLoggedIn();
  const headers = getHeaders();

  if (!isLogged) return;
  const cancelSubUrl = getCancelSubUrl(lang);

  try {
    const resp = await axios.post(cancelSubUrl, reason, { headers });
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const apiLibraryDeleteClientAccount = async () => {
  const lang = fetchLang();
  const isLogged = isLoggedIn();
  const headers = getHeaders();

  if (!isLogged) return;
  const deleteAccount = getDeleteAccountUrl(lang);

  try {
    const resp = await axios.post(deleteAccount, null, { headers });
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const apiLibraryToggleAudioQuality = async (audioFormat) => {
  const headers = getHeaders();
  const setAudioFormatUrl = getSetAudioFormatUrl(audioFormat);

  try {
    await axios.post(setAudioFormatUrl, null, { headers });
  } catch (error) {}
};

export const handleToggleAudioQuality = () => {
  const isFlacStream = getIsFlacStream();
  apiLibraryToggleAudioQuality(isFlacStream === true ? "mp3" : "flac");
  store.dispatch(dispatchToggleFlacStream());
};
