import axios from "axios";
import { captureException } from "@sentry/react";
import { store } from "App.js";
import { isLoggedIn } from "../storeUtils";
import { dispatchDecreaseAxiosLiveCalls, dispatchIncreaseAxiosLiveCalls } from "modules/Config/actions";

// apilibrary broken down and grouped (roughly)into different sections 
// to reduce file download time when loading functions thereby reducing unused javascript


//Setting axios params
export const getHeaders = () => {
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const token = loginData ? `Bearer ${loginData.token}` : "";
  let headers = "";
  if (loginData !== null && token) {
    headers = {
      Authorization: token,
    };
  }
  return headers;
};

// axios.defaults.headers.common["Authorization"] = token;
axios.interceptors.request.use(
  (config) => {
    store.dispatch(dispatchIncreaseAxiosLiveCalls());
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    store.dispatch(dispatchDecreaseAxiosLiveCalls());
    return response;
  },
  (error) => {
    const isLogged = isLoggedIn();
    if (isLogged) captureException(error);
    console.log("Intercepted error", error);
    return Promise.reject(error);
  }
);