import React from 'react';
import { withTranslation } from 'react-i18next';
import { ReactComponent as ErrorPageImage } from 'assets/images/errorPageImg.svg';
import { ReactComponent as PhoneIcon } from 'assets/images/icon_phone.svg';
import { ReactComponent as MailIcon } from 'assets/images/icon_mail.svg';
import { ReactComponent as InstantMessagingIcon } from 'assets/images/icon_instant_messaging.svg';

const NoIE = ({t}) => {

  const onButtonClick = () => {
    window.location = t('SOMETHING_WENT_WRONG_IE_DOWNLOAD_URL')
  }  

  return (
    <div className="ErrorPage">
      <div className="ErrorPage__TopSection">
        <div className="ErrorPage__TopSection__ImageContainer">
          <ErrorPageImage />
        </div>
        <h1 className="ErrorPage__TopSection__Heading">
          {t('SOMETHING_WENT_WRONG_IE_TITLE_1')}
          <br />
          {t('SOMETHING_WENT_WRONG_IE_TITLE_2')}
        </h1>

        <button className="button-red ErrorPage__TopSection__Button" onClick={onButtonClick}>
          {t('SOMETHING_WENT_WRONG_IE_DOWNLOAD')}
        </button>
      </div>
      <div className="ErrorPage__ContactSection">
        <p className="ErrorPage__ContactSection__Copy">
          {t('SOMETHING_WENT_WRONG_CONTACT_COPY')}
        </p>

        <div className="ErrorPage__ContactSection__ContactInfo">
          <p><MailIcon />{t('SOMETHING_WENT_WRONG_CONTACT_EMAIL')}</p>
          <p><PhoneIcon />{t('SOMETHING_WENT_WRONG_CONTACT_PHONE_EN')}</p>
          <p><PhoneIcon />{t('SOMETHING_WENT_WRONG_CONTACT_PHONE_FR')}</p>
          <p><InstantMessagingIcon />{t('SOMETHING_WENT_WRONG_CONTACT_INSTANT_MSG')}</p>
        </div>
      </div>

    </div>
  )
}

export default withTranslation()(NoIE);