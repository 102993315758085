import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import home from "assets/images/home_menu.svg";
import browse from "assets/images/browse_menu.svg";
import composers from "assets/images/composers_menu.svg";
import getAbsolutePath from "utils/getAbsolutePath";
import { useSelector } from "react-redux";
import library from "assets/images/library_menu.svg";
import { accessRoutes } from "components/ApplicationRoutes/routes";
import { classicalBrowseMenuItems, isRouteActive, jazzBrowseMenuItems } from "components/HeaderVialma/BrowseMenuItemsArray";
import { useTour } from "@reactour/tour";

const MobileMenu = () => {
  const lang = useSelector((state) => state.config.lang);
  const isLogged = useSelector((state) => state.config.isLogged);
  const isJazzSelected = useSelector((state) => state.config.isJazzSelected);
  const lngRoutes = accessRoutes[lang];
  const [isBrowseExpanded, setIsBrowseExpanded] = useState(false);

  const toggleBrowseExpand = () => {
    setIsBrowseExpanded(!isBrowseExpanded);
  };

  const { isOpen, setCurrentStep } = useTour();
  const checkTourLinkClicked = () => {
    if (isOpen) {
      setCurrentStep(2);
    }
  };

  const menuItems = isJazzSelected ? jazzBrowseMenuItems() : classicalBrowseMenuItems();
  return (
    <Fragment>
      <ExpandedBrowse lang={lang} isBrowseExpanded={isBrowseExpanded} menuItems={menuItems} toggleBrowseExpand={toggleBrowseExpand} />
      {isJazzSelected ? (
        <div className="mobilemenu" data-cy="mobile-menu">
          <Link className="mobilemenu__item__container" to={{ pathname: lngRoutes.jazzHome, state: { jazzMode: true } }}>
            <img src={getAbsolutePath(home)} alt="" />
            <div>{lang === "en" ? "Home" : "Accueil"}</div>
          </Link>

          <Link className="mobilemenu__item__container" to={{ pathname: lngRoutes.jazzArtists, state: { jazzMode: true } }}>
            <img src={getAbsolutePath(composers)} alt="" />
            <div>{lang === "en" ? "Artists" : "Artistes"}</div>
          </Link>

          <div className="mobilemenu__item__container" onClick={toggleBrowseExpand}>
            <img src={getAbsolutePath(browse)} alt="" />
            <div>{lang === "en" ? "Browse" : "Parcourir"}</div>
          </div>

          {isLogged && (
            <Link
              data-tour="library"
              onClick={checkTourLinkClicked}
              className="mobilemenu__item__container"
              to={{ pathname: lngRoutes.library, state: { jazzMode: true } }}
            >
              <img src={getAbsolutePath(library)} alt="" />
              <div>{lang === "en" ? "My library" : "Ma bibliothèque"}</div>
            </Link>
          )}
        </div>
      ) : (
        <div className="mobilemenu" data-cy="mobile-menu">
          <Link className="mobilemenu__item__container" to={{ pathname: lngRoutes.classicalHome, state: { jazzMode: false } }}>
            <img src={getAbsolutePath(home)} alt="" />
            <div>{lang === "en" ? "Home" : "Accueil"}</div>
          </Link>

          <Link className="mobilemenu__item__container" to={{ pathname: lngRoutes.composers, state: { jazzMode: false } }}>
            <img src={getAbsolutePath(composers)} alt="" />
            <div>{lang === "en" ? "Composers" : "Compositeurs"}</div>
          </Link>

          <div className="mobilemenu__item__container" onClick={toggleBrowseExpand}>
            <img src={getAbsolutePath(browse)} alt="" />
            <div>{lang === "en" ? "Browse" : "Parcourir"}</div>
          </div>

          {isLogged && (
            <Link
              data-tour="library"
              onClick={checkTourLinkClicked}
              className="mobilemenu__item__container"
              to={{ pathname: lngRoutes.library, state: { jazzMode: false } }}
            >
              <img src={getAbsolutePath(library)} alt="" />
              <div>{lang === "en" ? "My library" : "Ma bibliothèque"}</div>
            </Link>
          )}
        </div>
      )}
    </Fragment>
  );
};

const MobileMenuItemComponent = ({ lang, route, state, name_en, name_fr, toggleBrowseExpand, isRouteActive }) => (
  <Link
    className={`menu-item ${isRouteActive}`}
    to={{
      pathname: route,
      state: state,
    }}
    onClick={toggleBrowseExpand}
  >
    {lang === "fr" ? name_fr : name_en}
  </Link>
);
const ExpandedBrowse = ({ lang, isBrowseExpanded, menuItems, toggleBrowseExpand }) => {
  return (
    <div className={isBrowseExpanded ? "expanded-browse show" : "expanded-browse"}>
      {menuItems.map(({ pathName, state, name_en, name_fr }, index) => (
        <MobileMenuItemComponent
          isRouteActive={isRouteActive(pathName)}
          key={index}
          lang={lang}
          route={pathName}
          state={state}
          name_en={name_en}
          name_fr={name_fr}
          toggleBrowseExpand={toggleBrowseExpand}
        />
      ))}
    </div>
  );
};

export default MobileMenu;
