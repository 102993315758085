const FILTERS = [
  {
    name: "Period",
    name_fr: "Période",
    toggle: false,
    id: 1,
    filters: [
      {
        name: "Baroque",
        name_fr: "Baroque",
        id: 2,
        checked: false,
        path: "baroque",
        path_fr: "baroque",
        title: "COMPOSERS_BAROQUE_TITLE",
        copy: "COMPOSERS_BAROQUE_COPY",
        see_more: "SEE_MORE_BAROQUE",
        page_title: "COMPOSERS_BAROQUE_PAGE_TITLE",
      },
      {
        name: "Classical",
        name_fr: "Classique",
        id: 3,
        checked: false,
        path: "classical",
        path_fr: "classique",
        title: "COMPOSERS_CLASSICAL_TITLE",
        copy: "COMPOSERS_CLASSICAL_COPY",
        see_more: "SEE_MORE_CLASSICAL",
        page_title: "COMPOSERS_CLASSICAL_PAGE_TITLE",
      },
      {
        name: "Romantic",
        name_fr: "Romantique",
        id: 5,
        checked: false,
        path: "romantic",
        path_fr: "romantique",
        title: "COMPOSERS_ROMANTIC_TITLE",
        copy: "COMPOSERS_ROMANTIC_COPY",
        see_more: "SEE_MORE_ROMANTIC",
        page_title: "COMPOSERS_ROMANTIC_PAGE_TITLE",
      },
      {
        name: "Middle Ages",
        name_fr: "Moyen-âge",
        id: 6,
        checked: false,
        path: "middle-ages",
        path_fr: "moyen-age",
        title: "COMPOSERS_MIDDLE_AGES_TITLE",
        copy: "COMPOSERS_MIDDLE_AGES_COPY",
        see_more: "SEE_MORE_MIDDLE_AGES",
        page_title: "COMPOSERS_MIDDLE_AGES_PAGE_TITLE",
      },
      {
        name: "Renaissance",
        name_fr: "Renaissance",
        id: 7,
        checked: false,
        path: "renaissance",
        path_fr: "renaissance",
        title: "COMPOSERS_RENAISSANCE_TITLE",
        copy: "COMPOSERS_RENAISSANCE_COPY",
        see_more: "SEE_MORE_RENAISSANCE",
        page_title: "COMPOSERS_RENAISSANCE_PAGE_TITLE",
      },
      {
        name: "XXth Century",
        name_fr: "XXe siècle",
        id: 9,
        checked: false,
        path: "20th-century",
        path_fr: "xxe-siecle",
        title: "COMPOSERS_XXTH_CENTURY_TITLE",
        copy: "COMPOSERS_XXTH_CENTURY_COPY",
        see_more: "SEE_MORE_XXTH_CENTURY",
        page_title: "COMPOSERS_XXTH_CENTURY_PAGE_TITLE",
      },
      {
        name: "Contemporary",
        name_fr: "Contemporain",
        id: 10,
        checked: false,
        path: "contemporary",
        path_fr: "contemporain",
        title: "COMPOSERS_CONTEMPORARY_TITLE",
        copy: "COMPOSERS_CONTEMPORARY_COPY",
        see_more: "SEE_MORE_CONTEMPORARY",
        page_title: "COMPOSERS_CONTEMPORARY_PAGE_TITLE",
      },
    ],
  },
  {
    name: "Popularity",
    name_fr: "Popularité",
    toggle: false,
    id: 3,
    filters: [
      {
        name: "All stars",
        name_fr: "Incontournables",
        id: "5",
        checked: false,
      },
      {
        name: "Famous",
        name_fr: "Célèbres",
        id: "2",
        checked: false,
      },
      {
        name: "To discover",
        name_fr: "A découvrir",
        id: "4",
        checked: false,
      },
    ],
  },
  {
    name: "Country",
    name_fr: "Pays",
    toggle: false,
    id: 4,
    filters: [],
  },
];

export const COMPOSER_PERIODS = [
  ...FILTERS[0].filters,
  {
    name: "featured",
    name_fr: "a-l-affiche",
    id: 0,
    checked: false,
    path: "featured",
    path_fr: "a-l-affiche",
  },
];

export default FILTERS;
