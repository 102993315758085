import "rc-slider/assets/index.css";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {
  createSound,
  changeSoundAndPlay,
  playSound,
  pauseSound,
  skip5seconds,
  rewind5seconds,
  getCurrentPosition,
  getDuration,
  setPosition,
  toggleMute,
  setVolume,
  restoreSound,
  currentSong,
} from "./soundmanager";
import Slider, { SliderTooltip } from "rc-slider";
import Duration from "../Duration";
import MenuMore from "components/MenuMore";
import { dispatchUpdateMiniPlayer, dispatchUpdateModalWebPlayer } from "modules/MiniPlayer/actions";
import { getMiniPlayerSelector } from "modules/MiniPlayer/selectors";
import { getLoginSelector, getLangSelector, getIsJazzSelector } from "modules/Config/selectors";
import { getFlacStreamSelector } from "modules/Config/selectors";
import formatTime from "utils/formatTime";
import { Mixpanel } from "utils/mixpanel";
import iconPlay from "assets/images/miniplayer/play.svg";
import iconPause from "assets/images/miniplayer/pause.svg";
import iconPrev from "assets/images/miniplayer/prev.svg";
import iconNext from "assets/images/miniplayer/skip.svg";
import iconVolume from "assets/images/ic_volume.svg";
import iconExpand from "assets/images/ic_expandplayer_white.svg";
import time from "assets/images/time.svg";
import getAbsolutePath from "utils/getAbsolutePath";
import { withRouter } from "react-router";
import SettingsCog from "components/SettingsCog";
import isEmpty from "lodash/isEmpty";
import { withTranslation } from "react-i18next";
import {
  getJazzPerformersFromTrack,
  getClassicalPerformersFromTrack,
  getWorkFromTrack,
  getTrackIdFromTrack,
  getComposerFromTrack,
  getAlbumFromTrack,
  getDisplayArtistFromTrack,
  getJazzAlbumImageFromTrack,
  getTitleFromTrack,
  getRecordYear,
  isTrackJazz,
  isCountryAllowed,
} from "utils/metaDataTools";
import {
  classicalAlbumOption,
  composerOption,
  workOption,
  jazzAlbumOption,
  artistOption,
  recordingOption,
  podcastOption,
  chapterOption,
} from "components/MenuMore/MenuMoreOptions.js";
import { Link } from "react-router-dom";
import defaultDisplayImage from "assets/images/default_person_avatar.svg";
import { getUserLikedTracksSelector } from "modules/Library/selectors";
import { dispatchSetUserLikedTracks } from "./../../modules/Library/actions";
import TracksHeart from "components/TracksHeart";
import { captureException } from "@sentry/react";
import { isAllowedToPlay } from "utils/checkHasAccess";
import { dispatchUpdateShowClassicModal } from "modules/Config/actions";
import { playerArtistNameClickedTracking, playerGoToArtistTracking, playerGoToPlayingFromTracking } from "utils/miniPlayerMixpanelUtils";
import EnhancedImage from "components/EnhancedImage";
import ExpandedPlayerMiniplayer from "./ExpandedPlayerMiniplayer";
import MediaQuery from "react-responsive";
import { getArtistUrl, parseMediaProps } from "utils/miniplayerUtils";
import { getTourSteps } from "utils/tourUtils";
import { getTrackId, getChapterId } from "./miniplayerUtils";
import { FROM_WELCOME, TOUR_HEART_CLICKED } from "utils/localStoreKeys";
import { withTour } from "@reactour/tour";
import { accessRoutes } from "components/ApplicationRoutes/routes";
import { ALBUM, PAGE_ORIGIN_PODCASTS } from "utils/nameVariables";

const Handle = Slider.Handle;
const LIMIT_SECONDS = 30;
const ROLES_TO_SHOW_CLASSICAL = [3, 13, 14, 15];
const ROLES_TO_SHOW_JAZZ = [2, 9, 10, 12, 13, 17];

let pauseTimer;

class MiniPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seeking: false,
      pictureUrl: "",
      listeningHistoryId: "",
      percentageListened: new Set(),
      isJazzTrack: false,
      pausedForSeconds: 0,
      firstSongLoaded: false,
      cachedSong: null,
      continueTourCheckDone: false,
      newTrackListeningHistoryLogged: false,
    };
    this.eventSent = false;
    this.playerRef = React.createRef();
  }

  continueTourCheck = () => {
    const { t, userLikedTracks, miniPlayer, setSteps, setIsOpen, setCurrentStep, isOpen } = this.props;

    if (miniPlayer?.playing && miniPlayer?.pageOrigin !== PAGE_ORIGIN_PODCASTS && isEmpty(userLikedTracks) && !isOpen) {
      const isMobile = window.matchMedia("(max-width: 1200px)").matches;
      setSteps(getTourSteps(t, isMobile));
      setCurrentStep(0);
      this.setState({ continueTourCheckDone: true });
      setTimeout(function () {
        setIsOpen(true);
      }, 2000);
    }
  };

  checkTourStepsAndUpdate = () => {
    const { t, history, currentStep, setCurrentStep, userLikedTracks, lang } = this.props;
    if (!isEmpty(userLikedTracks) && localStorage.getItem(TOUR_HEART_CLICKED)) {
      //if currently on first step, and heart has been clicked,
      //delay 1 second to allow for animation, then go to next step
      if (currentStep === 0) {
        setTimeout(function () {
          setCurrentStep(1);
        }, 800);
      }
      //if step is 2, route to library page
      if (currentStep === 1 && !window.location.href.includes(`${accessRoutes[lang].library}#${t("HASH_LIKED_TRACKS")}`)) {
        history.push(`${accessRoutes[lang].library}#${t("HASH_LIKED_TRACKS")}`);
      }
    }
  };

  tourCheckHeartClicked = (e) => {
    const { isOpen, setCurrentStep } = this.props;
    if (isOpen) {
      if (localStorage.getItem(TOUR_HEART_CLICKED)) {
        e?.stopPropagation();
        setCurrentStep(1);
      } else {
        localStorage.setItem(TOUR_HEART_CLICKED, true);
      }
    }
  };

  setupMiniplayerState = async () => {
    const { miniPlayer, isLogged, t, updateMiniPlayer, handlePlayerError = () => {} } = this.props;

    const { indexOfTrack, dataTrack, pageOrigin } = miniPlayer;
    if (!isEmpty(dataTrack)) {
      let trackId;
      let activeURL;
      if (pageOrigin === PAGE_ORIGIN_PODCASTS) {
        const chapterId = dataTrack[indexOfTrack].id;
        const streamUrl = await this.getChapterStreamUrl(chapterId);

        if (streamUrl) {
          activeURL = streamUrl;
          this.setState({ listeningHistoryId: "" }); // podcasts are not tracked
          this.eventSent = true;
        }
      } else {
        const isJazzTrack = isTrackJazz(dataTrack[indexOfTrack]);
        this.setState({ isJazzTrack });
        trackId = getTrackIdFromTrack(dataTrack[indexOfTrack]);
        activeURL = await this.getActiveUrl(trackId);
      }
      this.setState({ activeURL: activeURL || `song couldn't load` });
      const pos = parseFloat(localStorage.getItem("position")) || 0;
      const miniPlayerData = JSON.parse(localStorage.getItem("miniplayer"));
      if (miniPlayerData) {
        // on refresh of page
        isLogged && restoreSound(activeURL || `song couldn't load`, trackId);
      } else {
        // playing after logging in
        if (isCountryAllowed(dataTrack[indexOfTrack])) {
          changeSoundAndPlay(activeURL || `song couldn't load`, trackId);
        } else {
          updateMiniPlayer({
            playing: false,
          });
          this.changeSong(1);
        }
      }
      updateMiniPlayer({ position: pos });
      setPosition(pos);

      this.restoreMiniplayerState();
      this.setMediaSessionControls();
    } else {
      dataTrack && handlePlayerError(t("NO_TRACKS_FOUND"));
      updateMiniPlayer({
        playing: false,
      });
    }
  };

  componentDidMount() {
    createSound(this.onProgress, this.onFinishedPlaying, this.onBuffer, this.onLoad, this.onPlayError);
    this.setupMiniplayerState();
    setTimeout(() => {
      const rcSlider = document.querySelector(".control-seconds__slider .rc-slider-handle");
      if (rcSlider) {
        rcSlider.removeAttribute("tabindex");
      }
    }, 0);
    window.addEventListener(this.getEventName(), this.saveLocalStorage);
  }
  componentWillUnmount() {
    window.removeEventListener(this.getEventName(), this.saveLocalStorage);
    pauseSound();
  }
  getEventName() {
    const isOnIOS = navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i);
    const eventName = isOnIOS ? "pagehide" : "beforeunload";
    return eventName;
  }
  saveLocalStorage = () => {
    const { position, activeURL, volume } = this.state;
    const { miniPlayer, isLogged } = this.props;
    if (isLogged && activeURL) {
      localStorage.setItem("position", position);
      const data = Object.assign({}, miniPlayer, { playing: false });
      localStorage.setItem("miniplayer", JSON.stringify(data));
    }

    // below are storage items that are not related to logged in state.
    localStorage.setItem("volume", volume);
  };

  restoreMiniplayerState = () => {
    const volume = Number(localStorage.getItem("volume"));
    this.changeVolume(volume || 80);
  };

  async componentDidUpdate(prevProps, prevState) {
    const { miniPlayer, updateMiniPlayer } = this.props;
    const { pageOrigin, playlist, playlistId, playing, dataTrack, indexOfTrack } = miniPlayer;
    const isPodcastStream = pageOrigin === PAGE_ORIGIN_PODCASTS;
    const { percentageListened, pausedForSeconds, cachedSong } = this.state;
    const { miniPlayer: prevMiniPlayer } = prevProps;
    const pos = getCurrentPosition();
    const CACHE_MILLISECONDS_BEFORE = 15000;

    if (cachedSong === null && pos && getDuration()) {
      if (pos + CACHE_MILLISECONDS_BEFORE > getDuration()) {
        this.precacheNextSong();
      }
    }

    if (dataTrack && prevMiniPlayer) {
      const prevTrackId = getTrackId(prevMiniPlayer);
      const currentTrackId = getTrackId(miniPlayer);
      if (currentTrackId !== prevTrackId) {
        const isJazzTrack = isTrackJazz(dataTrack[indexOfTrack]);
        this.setState({ isJazzTrack });
      }
    }

    // Tour provider related funcs
    const { continueTourCheckDone } = this.state;
    if (dataTrack && !prevProps.isOpen && localStorage.getItem(FROM_WELCOME) && !continueTourCheckDone) {
      this.continueTourCheck();
    }
    if (dataTrack && prevProps.isOpen && this.props.isOpen) {
      this.checkTourStepsAndUpdate();
    }
    //End tour provider related funcs

    if (prevProps.miniPlayer.playing !== playing) {
      if (playing === true) {
        if (isCountryAllowed(dataTrack?.[indexOfTrack])) {
          playSound();
        } else {
          updateMiniPlayer({
            playing: false,
          });
          this.changeSong(1);
        }
      } else if (playing === false) {
        pauseSound();
      }
    }

    const currentTrackId = isPodcastStream ? getChapterId(this.props.miniPlayer) : getTrackId(this.props.miniPlayer);
    const prevTrackId = isPodcastStream ? getChapterId(prevProps.miniPlayer) : getTrackId(prevProps.miniPlayer);
    if (currentTrackId && currentTrackId !== prevTrackId) {
      const listenedPercentage = percentageListened.size;
      const audioLengthInSec = Math.round(pos / 1000);
      if (percentageListened.size > 0) {
        if (typeof pausedForSeconds === "undefined") {
          captureException(new Error("pausedForInSec is undefined"), {
            details: {
              track_id: currentTrackId,
              is_podcast: isPodcastStream,
              listenedPercentage: listenedPercentage,
              pausedForSeconds: pausedForSeconds,
              page: window.location.href,
            },
          });
        }
        Mixpanel.track("% track listened", {
          trackId: currentTrackId,
          playlistId,
          playlistName: playlist,
          audioLengthInSec,
          listenedPercentage,
          pausedForInSec: pausedForSeconds,
        });
        this.recordDurationListened(audioLengthInSec);
      }
      this.stopPauseTimer();
      this.setState({ percentageListened: new Set(), pausedForSeconds: 0 });
      updateMiniPlayer({
        playing: false,
      });
      let activeURL;
      updateMiniPlayer({ position: 0 });
      setPosition(0);

      if (isCountryAllowed(dataTrack[indexOfTrack])) {
        activeURL = await this.getActiveUrl(currentTrackId);
        this.setState({ cachedSong: null });
        this.setState({ activeURL: activeURL || `song couldn't load` });
        changeSoundAndPlay(activeURL || `song couldn't load`, currentTrackId);
        updateMiniPlayer({
          playing: true,
        });
      } else {
        updateMiniPlayer({
          playing: false,
        });
        this.changeSong(1);
      }
      this.setState({ newTrackListeningHistoryLogged: false });
      this.eventSent = false;
    } else if (currentTrackId === prevTrackId && prevState.activeURL !== this.state.activeURL && prevState.activeURL) {
      // requested a new url for same song
      changeSoundAndPlay(this.state.activeURL, currentTrackId, false);
      setPosition(prevState.position);
      this.setState({ seeking: false }, () => {
        updateMiniPlayer({
          position: prevState.position,
        });
      });
    }
  }

  recordDurationListened = (audioLengthInSec) => {
    const { listeningHistoryId } = this.state;
    const { isLogged } = this.props;
    if (isLogged && listeningHistoryId && audioLengthInSec) {
      try {
        const url = `${process.env.REACT_APP_VIALMA_API}/listening/history/${listeningHistoryId}/time?duration=${audioLengthInSec}`;
        const tokenHash = JSON.parse(localStorage.getItem("loginData")).token;
        const token = `Bearer ${tokenHash}`;
        axios.get(url, {
          headers: {
            Authorization: token,
          },
        });
      } catch (error) {}
    }
  };

  setMediaSessionControls = () => {
    const { mediaSession } = navigator;
    if (mediaSession) {
      const actionsAndHandlers = [
        ["play", () => this.playPauseSong()],
        ["pause", () => this.playPauseSong()],
        ["previoustrack", () => this.changeSong(-1)],
        ["nexttrack", () => this.changeSong(1)],
      ];

      for (const [action, handler] of actionsAndHandlers) {
        try {
          mediaSession.setActionHandler(action, handler);
        } catch (error) {
          console.log(`The media session action, ${action}, is not supported`);
        }
      }
    }
  };

  startPauseTimer = () => {
    pauseTimer = setInterval(() => {
      this.setState((prevState) => {
        const newPausedValue = prevState.pausedForSeconds + 1;
        return {
          pausedForSeconds: newPausedValue,
        };
      });
    }, 1000);
  };

  stopPauseTimer = () => {
    clearInterval(pauseTimer);
  };

  playSong = (newIndex) => {
    const { miniPlayer, updateMiniPlayer, updateShowModalWebPlayer } = this.props;

    const isPodcastStream = miniPlayer?.pageOrigin === PAGE_ORIGIN_PODCASTS;

    if (isPodcastStream || isAllowedToPlay()) {
      updateMiniPlayer({
        playing: true,
        indexOfTrack: newIndex,
      });
    } else {
      updateShowModalWebPlayer(true);
      updateMiniPlayer({
        playing: false,
      });
    }
  };

  playPauseSong = () => {
    const { updateMiniPlayer, miniPlayer } = this.props;
    const { isFetching } = miniPlayer;
    if (isFetching) return; // disable control until song is loading
    updateMiniPlayer({ isFetching: false });
    const isCurrentlyPlaying = miniPlayer.playing;
    if (isCurrentlyPlaying) {
      this.startPauseTimer();
    } else {
      this.stopPauseTimer();
    }
    updateMiniPlayer({ playing: !isCurrentlyPlaying });
    this.playerRef.current.focus();

    window.dataLayer.push({
      event: "playerInteraction",
      interaction: miniPlayer.playing ? "pause" : "play",
    });
    Mixpanel.track("Player Interaction", {
      Action: miniPlayer.playing ? "Pause" : "Play",
    });
  };
  onProgress = () => {
    const { updateMiniPlayer } = this.props;
    const currentPercentage = Math.round((getCurrentPosition() / getDuration()) * 100);
    if (currentPercentage !== 0) {
      const howMuchListened = this.state.percentageListened.add(currentPercentage);
      this.setState({ percentageListened: howMuchListened });
    }

    updateMiniPlayer({ isFetching: false });
    const seconds = parseInt(getCurrentPosition() / 1000, 10);
    if (!this.state.newTrackListeningHistoryLogged && seconds > 0) {
      this.fireListeningHistoryEvent(currentSong?.id);
      this.setState({ newTrackListeningHistoryLogged: true });
    }
    const { miniPlayer, isLogged, lang, isFlacStream } = this.props;
    const { pageOrigin } = miniPlayer;
    const isPodcastStream = pageOrigin === PAGE_ORIGIN_PODCASTS;
    if (!this.state.seeking) {
      updateMiniPlayer({ position: getCurrentPosition() });
    }
    if (seconds > LIMIT_SECONDS && !this.eventSent && miniPlayer.playing && !isPodcastStream) {
      const { dataTrack, indexOfTrack, jazzMode, playlist, playlistId } = miniPlayer;
      const track = dataTrack?.[indexOfTrack];
      const composer = getComposerFromTrack(dataTrack?.[indexOfTrack]);
      const composerId = miniPlayer.composerId || (composer && composer.id) || "";
      const trackId = getTrackIdFromTrack(track);

      window.dataLayer.push({
        event: "trackPlayed",
        composerId: composerId,
        trackId,
      });
      let mixpanelEvent = {
        "Composer ID": composerId,
        "Track ID": trackId,
        Universe: jazzMode ? "Jazz" : "Classical",
        Quality: isFlacStream ? "Flac" : "Mp3",
      };

      if (playlist) {
        mixpanelEvent.Playlist = playlist;
      }

      if (playlistId) {
        mixpanelEvent.PlaylistId = playlistId;
      }

      const { listeningHistoryId } = this.state;
      if (isLogged && listeningHistoryId) {
        const urlHistory = `${process.env.REACT_APP_VIALMA_API}/listening/history/${listeningHistoryId}/time/exceed?lang=${lang}${
          jazzMode ? "&universe=Jazz" : ""
        }`;
        try {
          const tokenHash = JSON.parse(localStorage.getItem("loginData")).token;
          const token = `Bearer ${tokenHash}`;
          Mixpanel.track("Listened to Track", mixpanelEvent);
          axios.get(urlHistory, {
            headers: {
              Authorization: token,
            },
          });
          this.eventSent = true;
        } catch (error) {}
      }
    }
  };
  onBuffer = (isBuffering) => {
    const { updateMiniPlayer, miniPlayer } = this.props;
    if (miniPlayer.playing && isBuffering) {
      updateMiniPlayer({ isFetching: true });
    }
  };
  onFinishedPlaying = () => {
    this.changeSong(1);
  };
  onSeekMouseDown = () => {
    this.setState({ seeking: true });
  };
  onSeekChange = (value) => {
    const { updateMiniPlayer } = this.props;

    updateMiniPlayer({ position: value });
  };
  onSeekMouseUp = (value) => {
    const { updateMiniPlayer } = this.props;

    Mixpanel.track("Player Interaction", {
      Action: "Scrubbing",
    });
    this.setState({ seeking: false });
    updateMiniPlayer({ position: value });
    setPosition(value);
  };
  onLoad = (isSuccess) => {
    this.setState({ firstSongLoaded: true });
  };
  changeVolume = (value) => {
    const { updateMiniPlayer } = this.props;
    updateMiniPlayer({ volume: value });
    setVolume(value);
  };
  changeSong = (changeIndex = 1) => {
    const { miniPlayer, handlePlayerError = () => {} } = this.props;
    const { indexOfTrack, dataTrack } = miniPlayer;

    const newIndex = indexOfTrack + changeIndex;
    if (!dataTrack?.[newIndex]) {
      const { t } = this.props;
      handlePlayerError(t("COUNTRY_NOT_IN_REMAINING_TRACKS"));
      return;
    }
    if (dataTrack && newIndex < dataTrack.length) {
      this.playSong(newIndex);
    }

    window.dataLayer.push({
      event: "playerInteraction",
      interaction: changeIndex === 1 ? "forward" : "backward",
    });
    Mixpanel.track("Player Interaction", {
      Action: changeIndex === 1 ? "Forward" : "Backward",
    });
  };

  getIconPrev = () => {
    const { miniPlayer, isWebplayer } = this.props;
    const { indexOfTrack } = miniPlayer;
    if (indexOfTrack === 0) {
      return (
        <div className="iconPrev disabled" data-cy={`${isWebplayer ? "extended_" : ""}miniplayer_prev_disabled`}>
          <img src={getAbsolutePath(iconPrev)} alt="" />
        </div>
      );
    } else {
      return (
        <div className="iconPrev" onClick={() => this.changeSong(-1)} data-cy={`${isWebplayer ? "extended_" : ""}miniplayer_prev`}>
          <img src={getAbsolutePath(iconPrev)} alt="" />
        </div>
      );
    }
  };

  getIconNext = () => {
    const { miniPlayer, isWebplayer } = this.props;
    const { indexOfTrack, dataTrack } = miniPlayer;
    if (!dataTrack) {
      return null;
    }
    if (indexOfTrack + 1 === dataTrack.length) {
      return (
        <div className="iconNext disabled" data-cy={`${isWebplayer ? "extended_" : ""}miniplayer_next_disabled`}>
          <img src={getAbsolutePath(iconNext)} alt="" />
        </div>
      );
    } else {
      return (
        <div className="iconPrev" onClick={() => this.changeSong(1)} data-cy={`${isWebplayer ? "extended_" : ""}miniplayer_next`}>
          <img src={getAbsolutePath(iconNext)} alt="" />
        </div>
      );
    }
  };

  handleTimeLine = (e) => {
    const { value, dragging, index, ...restProps } = e;
    const formattedValue = formatTime(value / 1000);
    return (
      <SliderTooltip prefixCls="rc-slider-tooltip" overlay={`${formattedValue}`} visible={dragging} placement="top" key={index}>
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };

  refetchSameSong = async () => {
    const { miniPlayer } = this.props;

    const currentTrackId = getTrackId(miniPlayer);
    const newUrl = await this.getActiveUrl(currentTrackId);
    this.setState({
      activeURL: newUrl,
    });
  };

  onPlayError = (errorCode, errorMessage) => {
    const { miniPlayer } = this.props;
    const { indexOfTrack, dataTrack, pageOrigin } = miniPlayer;
    const { activeURL } = this.state;

    const isPodcast = pageOrigin === PAGE_ORIGIN_PODCASTS;
    const podcastId = dataTrack && dataTrack[indexOfTrack] && dataTrack[indexOfTrack].podcast_id;
    const chapterId = dataTrack && dataTrack[indexOfTrack] && dataTrack[indexOfTrack].id;

    if (isPodcast) {
      Mixpanel.track("Error playing podcast", {
        errorCode,
        errorMessage,
        podcastId,
        chapterId,
        streamUrl: activeURL,
      });
    } else {
      if (errorCode === 2 || errorCode === 4) {
        // MEDIA_ERR_NETWORK or MEDIA_ERR_SRC_NOT_SUPPORTED
        this.refetchSameSong();
      }
    }
  };

  getActiveUrl = (id, forPrecache) => {
    const { miniPlayer } = this.props;
    const { pageOrigin } = miniPlayer;
    const { cachedSong } = this.state;
    const isPodcastStream = pageOrigin === PAGE_ORIGIN_PODCASTS;

    if (cachedSong && cachedSong.id === id) {
      const returnUrl = cachedSong.url;
      this.setState({ cachedSong: null });
      return returnUrl;
    } else if (cachedSong && cachedSong.id && cachedSong.id !== id) {
      // may not be obvious from the code, but this clears the cached song if the user didn't play the next song (clicked on other song)
      this.setState({ cachedSong: null });
    }

    if (isPodcastStream) {
      return this.getChapterStreamUrl(id);
    } else {
      return this.getTrackStreamUrl(id, forPrecache);
    }
  };

  precacheNextSong = async () => {
    const { miniPlayer } = this.props;
    // below: need to set it to an intermediary state to avoid componentdidupdate calling this multiple times until answer comes back
    this.setState({ cachedSong: "loading new song..." });
    const id = getTrackId(miniPlayer, true);

    if (!id) {
      this.setState({ cachedSong: "Next song not available...maybe you reached the end?" });
      return;
    }
    const songUrl = await this.getActiveUrl(id, true);
    this.setState({ cachedSong: { id, url: songUrl } });
  };

  getRequestHeaders = () => {
    const loginData = JSON.parse(localStorage.getItem("loginData"));
    const tokenHash = loginData && loginData.token;
    const token = `Bearer ${tokenHash}`;

    return loginData ? { headers: { Authorization: token } } : undefined;
  };

  getChapterStreamUrl = async (chapterId) => {
    const { miniPlayer, lang, updateMiniPlayer } = this.props;

    const { jazzMode } = miniPlayer;
    const url = `${process.env.REACT_APP_VIALMA_API}/chapter/${chapterId}/stream?lang=${lang}${jazzMode ? "&universe=jazz" : "&universe=classical"}`;

    try {
      updateMiniPlayer({ isFetching: true });

      const chapterResponse = await axios.get(url, this.getRequestHeaders());
      Mixpanel.track("Podcast play", {
        chapter_id: chapterId,
        url: chapterResponse.data && chapterResponse.data.stream_url,
      });
      return chapterResponse.data && chapterResponse.data.stream_url ? chapterResponse.data.stream_url : null;
    } catch (error) {
      console.log("Could not get chapter stream url");
      return null;
    } finally {
      updateMiniPlayer({ isFetching: false });
    }
  };

  getTrackStreamUrl = async (id, forPrecache = false) => {
    const { t, miniPlayer, isFlacStream, updateMiniPlayer, handlePlayerError } = this.props;
    const { platformOrigin, pageOrigin, jazzMode, playlistId } = miniPlayer;
    const url = new URL(window.location.href);
    const utmSource = url.searchParams.get("utm_source");
    const urlTrack = `${process.env.REACT_APP_VIALMA_API}/get_track_info/${id}/${platformOrigin}/${pageOrigin}/${isFlacStream ? "flac" : "mp3"}${
      jazzMode ? "?universe=jazz" : "?universe=classical"
    }&partnerId=${utmSource}${playlistId ? "&playlistId=" + playlistId : ""}`;
    try {
      !forPrecache && updateMiniPlayer({ isFetching: true });
      const res = await axios.get(urlTrack, this.getRequestHeaders());
      return res?.data?.filenameaudio || res?.data?.fileNameAudio;
    } catch (error) {
      if (!forPrecache) {
        const errorData = error?.response?.data;
        updateMiniPlayer({ playing: false, dataTrack: null, url: "empty", playlist: null, mediaId: null, mediaProps: null });
        if (errorData?.includes?.("User not allowed to")) {
          this.props?.setShowClassicModal(true);
        } else {
          handlePlayerError(error?.message || errorData?.title || t("SOMETHING_WENT_WRONG"));
        }
      }
      captureException(error, {
        name: forPrecache ? "precache error" : "play error",
        track: {
          track_url: urlTrack,
          track_id: id,
        },
      });
    } finally {
      !forPrecache && updateMiniPlayer({ isFetching: false });
    }
  };

  fireListeningHistoryEvent = async (id) => {
    const { miniPlayer, isFlacStream } = this.props;
    const { platformOrigin, pageOrigin, jazzMode, playlistId, mediaId, mediaProps } = miniPlayer;
    const url = new URL(window.location.href);
    const utmSource = url.searchParams.get("utm_source");
    const isAlbum = mediaProps?.type === ALBUM;
    let urlTrack = `${process.env.REACT_APP_VIALMA_API}/streams/${id}/${platformOrigin}/${pageOrigin}/${isFlacStream ? "flac" : "mp3"}${
      jazzMode ? "?universe=jazz" : "?universe=classical"
    }&partnerId=${utmSource}${playlistId ? "&playlistId=" + playlistId : ""}`;
    if (isAlbum) {
      urlTrack = urlTrack + `&albumId=${mediaId}`;
    }
    try {
      const res = await axios.get(urlTrack, this.getRequestHeaders());
      this.setState({ listeningHistoryId: res?.data?.listeningHistoryId });
    } catch (error) {
      captureException(error, {
        name: "streams EP error recording listening history",
        pageOrigin,
        playlistId,
        track: {
          track_url: urlTrack,
          track_id: id,
        },
      });
    }
  };

  toggleVolume = () => {
    const { miniPlayer, updateMiniPlayer } = this.props;
    const { volumeMute } = miniPlayer;
    updateMiniPlayer({ volumeMute: !volumeMute });
    toggleMute();
  };

  clickMiniplayer = (event) => {
    const playerName = event.target.attributes.name;
    const { expandPlayer, collapsePlayer, collapsed, miniPlayer } = this.props;
    const { jazzMode, pageOrigin } = miniPlayer;
    if (playerName && playerName.value === "player" && !jazzMode && pageOrigin !== PAGE_ORIGIN_PODCASTS) {
      if (collapsed) {
        expandPlayer();
      } else {
        collapsePlayer();
      }
    }
  };

  getJazzPlaylistImage = (track) => {
    if (!isEmpty(track.albums)) {
      const [album] = track.albums;
      if (album && album.pictureUrl) {
        if (album.pictureUrl !== null && album.pictureUrl.length > 1) {
          return album.pictureUrl;
        }
      }
    }
    return null;
  };

  getTitleFromTrack = (track) => {
    const langTitle = this.props.lang === "en" ? track.longnameEn : track.longnameFr;
    return track.track_title || track.trackTitle || track.title || langTitle;
  };

  getPerformerRole = (performer, isJazzTrack) => {
    if (performer && !isEmpty(performer.roles)) {
      const roleToShow = performer.roles.find((role) => {
        const lookupRoles = isJazzTrack ? ROLES_TO_SHOW_JAZZ : ROLES_TO_SHOW_CLASSICAL;
        return lookupRoles.includes(role.id);
      });
      return roleToShow ? roleToShow.name : "";
    }
    return "";
  };

  onMiniplayerKeydown = (event) => {
    switch (event.keyCode) {
      case 39:
        skip5seconds();
        break;
      case 37:
        rewind5seconds();
        break;
      case 32:
        if (document.getElementById("input-create-playlist") === document.activeElement) return;
        this.playPauseSong();
        event.preventDefault(); // to prevent page scroll on space
        break;
      default:
        break;
    }
  };

  stopExtending = (event) => {
    if (isEmpty(event.target.attributes[0])) return;

    const forcePropagationItems = ["user-playlist"];
    let forcePropagation = false;

    event.target.attributes.forEach((attr) => {
      if (!forcePropagation && attr.name === "data-cy" && forcePropagationItems.includes(attr.nodeValue)) {
        forcePropagation = true;
      }
    });
    if (!forcePropagation) event.stopPropagation();
  };

  render() {
    const { seeking, isJazzTrack } = this.state;
    const { isWebplayer, miniPlayer, expandPlayer, collapsed, isLogged, collapsePlayer, t, lang } = this.props;
    const { playing, indexOfTrack, dataTrack, jazzMode, pageOrigin, position, isFetching: fetchingSong, volume, volumeMute, mediaProps } = miniPlayer;

    if (isEmpty(dataTrack)) return null;
    const countryAllowed = isCountryAllowed(dataTrack[indexOfTrack]);

    const isPodcast = miniPlayer.pageOrigin === PAGE_ORIGIN_PODCASTS;

    const jazzFlag = jazzMode;

    const hiddenClass = miniPlayer.url === "empty" ? "hidden" : "";
    const podcastId = dataTrack && dataTrack[indexOfTrack].podcast_id;
    const chapterId = dataTrack && dataTrack[indexOfTrack].id;

    const currentTrackId = getTrackIdFromTrack(dataTrack[indexOfTrack]);
    const work = getWorkFromTrack(dataTrack[indexOfTrack]);
    const recYear = getRecordYear(dataTrack[indexOfTrack]);

    const displayArtist = getDisplayArtistFromTrack(dataTrack[indexOfTrack]);

    let displayImageRootUrl;
    let displayImagePath;
    const imageObject = getJazzAlbumImageFromTrack(dataTrack[indexOfTrack]);
    displayImageRootUrl = imageObject?.imageRootUrl;
    displayImagePath = imageObject?.imagePath;

    const displayImage = isPodcast ? (
      <img src={miniPlayer.composerImage} alt="" />
    ) : (
      <EnhancedImage size="small" imageRootUrl={displayImageRootUrl} imagePath={displayImagePath} fallbackImage={defaultDisplayImage} alt={""} />
    );

    const displayName = displayArtist && displayArtist.name;
    const title = getTitleFromTrack(dataTrack[indexOfTrack]);
    const performers = jazzFlag
      ? getJazzPerformersFromTrack(dataTrack[indexOfTrack], lang)
      : getClassicalPerformersFromTrack(dataTrack[indexOfTrack], lang);

    const album = getAlbumFromTrack(dataTrack[indexOfTrack]);

    const prevTrack = dataTrack?.[indexOfTrack - 1];
    const prevTrackTitle = isPodcast ? prevTrack?.name : getTitleFromTrack(prevTrack);
    const prevTrackDisplayArtist = getDisplayArtistFromTrack(prevTrack);
    const prevTrackDisplayArtistName = isPodcast ? prevTrack?.podcast_name || dataTrack[indexOfTrack]?.podcast?.name : prevTrackDisplayArtist?.name;
    const prevArtistId = prevTrackDisplayArtist?.id;
    const prevArtistSlug = prevTrackDisplayArtist?.slug;
    const nextTrack = dataTrack?.[indexOfTrack + 1];
    const nextTrackTitle = isPodcast ? nextTrack?.name : getTitleFromTrack(nextTrack);
    const nextTrackDisplayArtist = getDisplayArtistFromTrack(nextTrack);
    const nextTrackDisplayArtistName = isPodcast ? nextTrack?.podcast_name || dataTrack[indexOfTrack]?.podcast?.name : nextTrackDisplayArtist?.name;
    const nextArtistId = nextTrackDisplayArtist?.id;
    const nextArtistSlug = nextTrackDisplayArtist?.slug;

    const { hasMediaProps, playingFromUrl } = parseMediaProps(mediaProps);
    const displayArtistUrl = getArtistUrl(displayArtist, lang, jazzFlag);
    return (
      <div
        className={`mini-player ${!isWebplayer ? "collapse" : ""} ${hiddenClass}`}
        onKeyDown={this.onMiniplayerKeydown}
        tabIndex="0"
        ref={this.playerRef}
      >
        <div className={seeking ? "control-seconds control-seeking" : "control-seconds"}>
          <div className={jazzFlag ? "control-seconds__slider theme-slider jazz" : "control-seconds__slider theme-slider"}>
            <Slider
              min={0}
              max={parseFloat(getDuration())}
              step={1}
              defaultValue={0}
              value={position}
              onBeforeChange={this.onSeekMouseDown}
              onChange={this.onSeekChange}
              onAfterChange={this.onSeekMouseUp}
              handle={this.handleTimeLine}
            />
            <div className="duration">
              <Duration seconds={getCurrentPosition() / 1000} />
              <div className="span">
                - <Duration seconds={(parseFloat(getDuration()) - getCurrentPosition()) / 1000} />
              </div>
            </div>
          </div>
        </div>

        {!isWebplayer ? (
          <div name="player" className={`${jazzFlag ? "mini-player__controls jazz" : "mini-player__controls"} docked`} onClick={this.clickMiniplayer}>
            <div name="player" className="mini-player__controls-left">
              <div className="mini-image-composer" onClick={expandPlayer}>
                {displayImage}
              </div>
              <div className="song-info" name="player">
                <div className="song-info__row" name="player" onClick={expandPlayer}>
                  {displayName &&
                    (hasMediaProps ? (
                      <Link
                        to={displayArtistUrl}
                        onClick={(e) => {
                          e.stopPropagation();
                          playerGoToArtistTracking();
                        }}
                        className="song-info__composer truncate has-link"
                      >
                        {displayName}
                      </Link>
                    ) : (
                      <div className="song-info__composer truncate">{displayName}</div>
                    ))}
                  {dataTrack && isPodcast && <div className="song-info__composer truncate">{dataTrack[indexOfTrack].name}</div>}
                  <div className="song-info__name" onClick={expandPlayer}>
                    {dataTrack &&
                      (hasMediaProps ? (
                        <Link
                          to={playingFromUrl}
                          onClick={(e) => {
                            e.stopPropagation();
                            playerGoToPlayingFromTracking();
                          }}
                          className="truncate has-link"
                          data-title={title}
                        >
                          {title}
                        </Link>
                      ) : (
                        <span className="truncate" data-title={title}>
                          {title}
                        </span>
                      ))}
                  </div>
                  <div className="song-info__duration">
                    <img src={getAbsolutePath(time)} alt="" />
                    <Duration seconds={getDuration() / 1000} />
                  </div>
                </div>
                <div className="song-info__row" name="player">
                  {dataTrack && isPodcast && <span className="truncate">{dataTrack[indexOfTrack].podcast_name}</span>}
                  <div className="song-info__performances">
                    {performers && (
                      <div className="artist">
                        {performers.map((performer, index) => {
                          const role = this.getPerformerRole(performer, jazzFlag);
                          return (
                            <Fragment key={`${performer.id}+${index}`}>
                              {index > 0 && " • "}
                              <Link to={performer.url} onClick={playerArtistNameClickedTracking}>
                                {performer.name}
                              </Link>
                              {role && ` (${role})`}
                            </Fragment>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div name="player" className="mini-player__controls-right" onClick={this.stopExtending}>
              <SettingsCog className="stream-settings mobile" isMiniPlayer={true} />
              <div className="box-icons" name="player">
                {isLogged && !isPodcast && countryAllowed && (
                  <div onClick={this.tourCheckHeartClicked}>
                    <MediaQuery query={"only screen and (max-width: 880px)"}>
                      <TracksHeart parent={"miniplayer"} source="miniplayer" />
                    </MediaQuery>
                    <MediaQuery query={"only screen and (min-width: 881px)"}>
                      <TracksHeart parent={"miniplayer"} source="miniplayer" />
                    </MediaQuery>
                  </div>
                )}
                {this.getIconPrev()}
                <div
                  className={`iconPausePlay ${fetchingSong ? "loading" : ""}`}
                  data-cy={`${isWebplayer ? "extended_" : ""}miniplayer_playpause`}
                  onClick={this.playPauseSong}
                >
                  {playing ? <img src={getAbsolutePath(iconPause)} alt="" /> : <img src={getAbsolutePath(iconPlay)} alt="" />}
                </div>
                {this.getIconNext()}
              </div>
              <div className="volume-container hide-880">
                <img
                  onClick={this.toggleVolume}
                  src={getAbsolutePath(iconVolume)}
                  className={volumeMute ? "iconVolume disabled" : "iconVolume"}
                  alt=""
                />
                <div className={jazzFlag ? "slider-box theme-slider jazz" : "slider-box theme-slider"}>
                  <Slider min={0} max={100} step={1} value={volumeMute ? 0 : volume} disabled={volumeMute} onChange={this.changeVolume} />
                </div>
                <SettingsCog className="stream-settings" isMiniPlayer={true} />
              </div>
              <div className="mx-40">
                {pageOrigin === PAGE_ORIGIN_PODCASTS ? (
                  <MenuMore
                    hideAddToPlaylist={true}
                    urlOptions={[podcastOption(podcastId, jazzMode, lang, t), chapterOption(chapterId, jazzMode, lang, t)]}
                    isMiniPlayer={true}
                    isWebplayer={isWebplayer}
                  />
                ) : (
                  isLogged && (
                    <MenuMore
                      runAfterRedirect={collapsePlayer}
                      listSongs={[currentTrackId]}
                      isJazz={jazzFlag}
                      urlOptions={
                        jazzFlag
                          ? album && [jazzAlbumOption(album, lang, t), artistOption(displayArtist, lang, t)]
                          : [
                              composerOption(displayArtist, lang, t),
                              classicalAlbumOption(album, lang, t),
                              workOption(work, lang, t),
                              recordingOption(displayArtist, work, recYear, lang, t),
                            ]
                      }
                      track={dataTrack[indexOfTrack]}
                      playSong={this.playSong}
                      isMiniPlayer={true}
                      isWebplayer={isWebplayer}
                    />
                  )
                )}
              </div>
              <MediaQuery query={"only screen and (min-width: 658px)"}>
                {collapsed && isLogged && !isPodcast && (
                  <img onClick={expandPlayer} className="iconExpand mr-10" src={getAbsolutePath(iconExpand)} alt="" data-cy={"miniplayer_expand"} />
                )}
              </MediaQuery>
            </div>
          </div>
        ) : (
          <ExpandedPlayerMiniplayer
            isPodcast={isPodcast}
            miniPlayer={miniPlayer}
            prevTrack={prevTrack}
            prevTrackTitle={prevTrackTitle}
            prevTrackDisplayArtist={prevTrackDisplayArtist}
            prevTrackDisplayArtistName={prevTrackDisplayArtistName}
            prevArtistId={prevArtistId}
            prevArtistSlug={prevArtistSlug}
            nextTrack={nextTrack}
            nextTrackTitle={nextTrackTitle}
            nextTrackDisplayArtistName={nextTrackDisplayArtistName}
            nextArtistId={nextArtistId}
            nextArtistSlug={nextArtistSlug}
            isJazzTrack={isJazzTrack}
            playing={playing}
            indexOfTrack={indexOfTrack}
            dataTrack={dataTrack}
            jazzMode={jazzMode}
            pageOrigin={pageOrigin}
            jazzFlag={jazzFlag}
            countryAllowed={countryAllowed}
            fetchingSong={fetchingSong}
            podcastId={podcastId}
            chapterId={chapterId}
            collapsePlayer={collapsePlayer}
            currentTrackId={currentTrackId}
            work={work}
            recYear={recYear}
            lang={lang}
            volume={volume}
            volumeMute={volumeMute}
            collapsed={collapsed}
            expandPlayer={expandPlayer}
            stopExtending={this.stopExtending}
            getIconPrev={this.getIconPrev}
            playPauseSong={this.playPauseSong}
            getIconNext={this.getIconNext}
            toggleVolume={this.toggleVolume}
            changeVolume={this.changeVolume}
            isWebplayer={isWebplayer}
            playSong={this.playSong}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  miniPlayer: getMiniPlayerSelector(state),
  isLogged: getLoginSelector(state),
  lang: getLangSelector(state),
  isFlacStream: getFlacStreamSelector(state),
  userLikedTracks: getUserLikedTracksSelector(state),
  isJazzSelected: getIsJazzSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateMiniPlayer: (data) => {
    dispatch(dispatchUpdateMiniPlayer(data));
  },
  updateShowModalWebPlayer: (showModal) => {
    dispatch(dispatchUpdateModalWebPlayer(showModal));
  },
  setShowClassicModal: (showClassicModal) => {
    dispatch(dispatchUpdateShowClassicModal(showClassicModal));
  },
  setUserLikedTracks: (playlists) => {
    dispatch(dispatchSetUserLikedTracks(playlists));
  },
});

export default withTour(withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(MiniPlayer))));
