import { createSelector } from "reselect";
import { REDUCER_KEY } from "./constants";

const getLangLibrary = (state) => state[REDUCER_KEY].langLibrary;
const getListeningHistory = (state) => state[REDUCER_KEY].listeningHistory;
const getPlaylists = (state) => state[REDUCER_KEY].playlists;
const getVialmaPlaylists = (state) => state[REDUCER_KEY].vialmaPlaylists;
const getVialmaPlaylistLiked = (state) => state[REDUCER_KEY].vialmaPlaylistLiked;
const getUserLikedTracks = (state) => state[REDUCER_KEY].userLikedTracks;
const getUserLikedAlbums = (state) => state[REDUCER_KEY].userLikedAlbums;
const getUserLikedVideos = (state) => state[REDUCER_KEY].userLikedVideos;
const getUserLikedCollections = (state) => state[REDUCER_KEY].userLikedCollections;

export const getLangLibrarySelector = createSelector(getLangLibrary, (langLibrary) => langLibrary);
export const getListeningHistorySelector = createSelector(getListeningHistory, (listeningHistory) => listeningHistory);
export const getPlaylistsSelector = createSelector(getPlaylists, (playlists) => playlists);
export const getVialmaPlaylistsSelector = createSelector(getVialmaPlaylists, (vialmaPlaylists) => vialmaPlaylists);
export const getVialmaPlaylistLikedSelector = createSelector(getVialmaPlaylistLiked, (vialmaPlaylistLiked) => vialmaPlaylistLiked);
export const getUserLikedTracksSelector = createSelector(getUserLikedTracks, (userLikedTracks) => userLikedTracks);
export const getUserLikedAlbumsSelector = createSelector(getUserLikedAlbums, (userLikedAlbums) => userLikedAlbums);
export const getUserLikedVideosSelector = createSelector(getUserLikedVideos, (userLikedVideos) => userLikedVideos);
export const getUserLikedCollectionsSelector = createSelector(getUserLikedCollections, (userLikedCollections) => userLikedCollections);
