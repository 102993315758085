import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as Sentry from "@sentry/react";
import * as serviceWorker from "./serviceWorker";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  ignoreErrors: ["NotAllowedError"],
});

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// Test comment
serviceWorker.unregister();
