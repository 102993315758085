import produce from "immer";
import {
  SET_ALBUMS_FEATURED,
  SET_ALBUMS,
  SET_SEARCH_ALBUMS,
  SET_FILTERED_ALBUMS,
  SET_GENRES,
  CLEAR_ALBUMS,
  SET_ALBUMS_DEFAULTS
} from "./constants";

const initialState = {
  albumsFeatured: null,
  albums: [],
  search: "",
  filteredAlbums: null,
  genres: []
};

const albumsJazzReducer = function(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SET_ALBUMS_FEATURED:
        draft.albumsFeatured = action.albumsFeatured;
        break;
      case SET_ALBUMS:
        draft.albums.push(...action.albums);
        break;
      case SET_SEARCH_ALBUMS:
        draft.search = action.search;
        break;
      case SET_FILTERED_ALBUMS:
        draft.filteredAlbums = action.filteredAlbums;
        break;
      case SET_GENRES:
        draft.genres = Object.assign([], action.genres);
        break;
      case CLEAR_ALBUMS: 
        draft.albums = [];
        break;
      case SET_ALBUMS_DEFAULTS: 
        return initialState;
      default:
        return;
    }
  });
}

export default albumsJazzReducer;