import produce from "immer";
import {
  UPDATE_LANG,
  UPDATE_LOGIN,
  SET_OVERFLOW,
  UPDATE_SUBSCRIPTION,
  UPDATE_SHOW_FREE_TRIAL,
  UPDATE_SHOW_CLASSIC_MODAL,
  UPDATE_UNIVERSE_SELECTION,
  TOGGLE_FLAC_STREAM,
  SET_FLAC_ISENABLED,
  UPDATE_SHOW_SIGNUP_FREE_TRIAL_MODAL,
  INCREASE_AXIOS_LIVE_CALLS,
  DECREASE_AXIOS_LIVE_CALLS,
  UPDATE_SHOW_OFFER_MODAL,
  CHECK_SUBSCRIPTION_UPDATE,
  SET_FLAC_STREAM,
} from "./constants";
import getDefaultLang from "utils/getDefaultLang";
import getLangFromPathname from "utils/getLangFromPathname";
import isEmpty from "lodash/isEmpty";
import { SHOW_OFFER_MODAL } from "utils/localStoreKeys";

const initialState = {
  lang: getLangFromPathname() || getDefaultLang(),
  isLogged: !isEmpty(localStorage.getItem("loginData")) ? true : false,
  overflow: "",
  subscription: null,
  showFreeTrial: localStorage.getItem("showFreeTrial") === "true" || false,
  showClassicModal: false,
  showSignupFreeTrialModal: false,
  showSignupFreeTrialModalHeader: "",
  isJazzSelected: localStorage.getItem("isHeaderJazz") === "true",  
  isFlacStream: false,
  isFlacAllowed: false,
  axiosLiveCalls: 0,
  showOfferModal: !window.sessionStorage.getItem(SHOW_OFFER_MODAL) || window.sessionStorage.getItem(SHOW_OFFER_MODAL) === "false" ? false : true,
  checkSubscriptionUpdate: false,
};

const configReducer = function (state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case UPDATE_LANG:
        draft.lang = action.lang;
        break;
      case UPDATE_LOGIN:
        draft.isLogged = action.isLogged;
        break;
      case SET_OVERFLOW:
        draft.overflow = action.overflow;
        break;
      case UPDATE_SUBSCRIPTION:
        draft.subscription = action.subscription;
        break;
      case UPDATE_SHOW_FREE_TRIAL:
        draft.showFreeTrial = action.showFreeTrial;
        break;
      case UPDATE_SHOW_CLASSIC_MODAL:
        draft.showClassicModal = action.showClassicModal;
        break;
      case UPDATE_SHOW_SIGNUP_FREE_TRIAL_MODAL:
        draft.showSignupFreeTrialModal = action.showSignupFreeTrialModal;
        draft.showSignupFreeTrialModalHeader = action.showSignupFreeTrialModalHeader;
        break;
      case UPDATE_UNIVERSE_SELECTION:
        draft.isJazzSelected = action.isJazz;
        break;
      case TOGGLE_FLAC_STREAM:        
        draft.isFlacStream = !draft.isFlacStream;
        break;
      case SET_FLAC_STREAM:
        draft.isFlacStream = action.isFlacStream;
        break;
      case SET_FLAC_ISENABLED:
        draft.isFlacAllowed = action.isFlacAllowed;
        break;
      case INCREASE_AXIOS_LIVE_CALLS:
        draft.axiosLiveCalls = draft.axiosLiveCalls + 1;
        break;
      case DECREASE_AXIOS_LIVE_CALLS:
        draft.axiosLiveCalls = draft.axiosLiveCalls - 1;
        break;
      case UPDATE_SHOW_OFFER_MODAL:
        draft.showOfferModal = action.showOfferModal;
        break;
      case CHECK_SUBSCRIPTION_UPDATE:
        draft.checkSubscriptionUpdate = action.checkSubscriptionUpdate;
        break;
      default:
        return;
    }
  });
};

export default configReducer;
