import React, { useState } from "react";

const Heart = ({ isDefaultChecked, onCheck = () => {}, onUncheck = () => {}, withBackground = false, size = "small", copy, transparent }) => {
  const [isChecked, setIsChecked] = useState(!!isDefaultChecked);
  const [isInProgress, setIsInProgress] = useState(false);

  const onClick = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (isInProgress) return;
    setIsInProgress(true);
    setIsChecked(!isChecked);
    const action = isChecked ? onUncheck : onCheck;
    try {
      await action();
    } catch (error) {
      setIsChecked(false);
    } finally {
      setIsInProgress(false);
    }
  };

  return (
    <div className={withBackground ? "mc-heart-background" : "mc-heart-default"} onClick={(e) => onClick(e)}>
      <div className={isChecked ? `mc-heart ${size} clicked` : `mc-heart ${size} ${transparent && "transparent"}`} alt="" />
      {copy && <span className="mc-heart-copy">{copy}</span>}
    </div>
  );
};

export default Heart;
