import i18n from "i18next";
import HttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import axios from "axios";
import getDefaultLang from "utils/getDefaultLang";
import { store } from "App";
import { dispatchUpdatelang } from "modules/Config/actions";

const customLangdetector = {
  name: "customLangDetector",
  lookup(options) {
    const langRegexp = /([\w]{2})/;
    const currentPathname = window.location.pathname.split("/");
    const currentPossibleLang = currentPathname[1];
    let respLang;
    if (currentPossibleLang.length === 2 && langRegexp.test(currentPossibleLang)) {
      respLang = currentPossibleLang;
    } else {
      const defaultLang = getDefaultLang();
      respLang = defaultLang;
    }
    store.dispatch(dispatchUpdatelang(respLang));
    return respLang;
  },
};
const languageDetector = new LanguageDetector();
languageDetector.addDetector(customLangdetector);

const loadJSON = (url, options, callback, data) => {
  try {
    if (url.includes("/dev")) {
      throw new Error("dev called");
    } // to avoid 2x call, not sure how else to stop this!
    axios.get(url).then((response) => {
      callback(response.data, { status: "200" });
    });
  } catch (e) {
    callback(null, { status: "404" });
  }
};

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    load: "currentOnly",
    fallbackLng: getDefaultLang(),
    ajax: loadJSON,
    parse: (data) => data,
    detection: {
      order: ["customLangDetector", "path", "localStorage"],
      lookupLocalStorage: "userLang",
      lookupFromPathIndex: 0,
      htmlTag: document.documentElement,
      checkWhitelist: true,
    },
    backend: {
      loadPath: `${process.env.REACT_APP_VIALMA_API}/contents/{{lng}}`,
    },
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      // wait: true,
      useSuspense: true,
    },
  });

export default i18n;
