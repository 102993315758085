import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import giftbox from "assets/images/Christmas/giftbox.svg";
import { Mixpanel } from "utils/mixpanel";
import { isMobile } from "react-device-detect";

// Usage looks like:
// <SideWidget url={lngRoutes.giftcardPurchase} text={t("SIDEWIDGET_TEXT")} />
// Placed in ContainerApp
const SideWidget = ({ text, url, history, t, showIcon = false }) => {
  const sessionStorageState = window.sessionStorage.getItem("hideSideWidget");
  const [isHidden, setIsHidden] = useState(sessionStorageState === "true" || false);
  const isLiveStreamException = window.location.pathname === t("CURRENT_LIVESTREAM_URL");

  let isExternalUrl;
  try {
    isExternalUrl = new URL(url);
  } catch (error) {
    isExternalUrl = false;
  }

  const toggleWidget = (e) => {
    if (e) e.stopPropagation();
    const newState = !isHidden;
    setIsHidden(newState);
    window.sessionStorage.setItem("hideSideWidget", newState);
  };

  const handleOnClick = (e) => {
    if (e) e.stopPropagation();

    if (isHidden) {
      toggleWidget();
    } else {
      if (isExternalUrl) {
        Mixpanel.track("Widget click", { type: "External Url" });
        window.open(url, "_blank");
      } else {
        Mixpanel.track("Widget click", { type: "Gift card" });
        history.push({ pathname: url });
      }
    }
  };

  if (isLiveStreamException || isMobile) return null;

  return (
    <div className={`side-widget${isHidden ? " hidden" : ""}`} onClick={handleOnClick}>
      <div className="container">
        <div className="text">{text}</div>
        {showIcon && <img src={giftbox} className="icon" alt="" />}
      </div>
      <div className={`x-button${isHidden ? " hidden" : ""}`} onClick={toggleWidget} />
    </div>
  );
};

export default withTranslation()(withRouter(SideWidget));
