export const REDUCER_KEY = 'library';
export const SET_LANG_LIBRARY = 'SET_LANG_LIBRARY';
export const SET_LISTENING_HISTORY = 'SET_LISTENING_HISTORY';
export const SET_PLAYLISTS = 'SET_PLAYLISTS';
export const SET_VIALMA_PLAYLIST = 'SET_VIALMA_PLAYLIST';
export const SET_VIALMA_PLAYLIST_LIKED = 'SET_VIALMA_PLAYLIST_LIKED';
export const SET_VIALMA_PLAYLIST_LIKED_PLUSMINUS = 'SET_VIALMA_PLAYLIST_LIKED_PLUSMINUS';
export const SET_USER_LIKED_TRACKS = 'SET_USER_LIKED_TRACKS';
export const SET_USER_LIKED_ALBUMS = 'SET_USER_LIKED_ALBUMS';
export const SET_USER_LIKED_VIDEOS = 'SET_USER_LIKED_VIDEOS';
export const SET_USER_LIKED_COLLECTIONS = 'SET_USER_LIKED_COLLECTIONS';

