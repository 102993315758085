import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import axios from "axios";
import MediaQuery from "react-responsive";
import { getLangSelector, getLoginSelector } from "modules/Config/selectors";
import { Mixpanel } from "utils/mixpanel";
import { getPlaylistsSelector, getUserLikedTracksSelector } from "modules/Library/selectors";
import { dispatchSetPlaylists } from "modules/Library/actions";
import iconMore from "assets/images/miniplayer/dots.svg";
import iconMenuComposer from "assets/images/icon_menu_composer.svg";
import iconMenuWork from "assets/images/icon_menu_work.svg";
import iconMenuPlaylist from "assets/images/icon_menu_playlist.svg";
import getAbsolutePath from "utils/getAbsolutePath";
import Modal from "react-modal";
import customStyles from "utils/customStyleModal";
import notificationManager from "./../../utils/notificationManager";
import LoadingBubble from "components/LoadingBubble";
import { dispatchSetUserLikedTracks } from "./../../modules/Library/actions";
import MenuMorePopupDesktop from "./MenuMorePopupDesktop";
import { isCountryAllowed } from "utils/metaDataTools";
import { encodeWithQuote } from "utils/tools";
import { playerAddToPlayistTracking } from "utils/miniPlayerMixpanelUtils";
import MenuMorePopupMobile from "./MenuMorePopupMobile";
import iconClose from "assets/images/close_search.svg";
import { dispatchUpdateExpandedPlayerCollapsed } from "modules/MiniPlayer/actions";

class MenuMore extends Component {
  state = {
    moreMenu: false,
    moreMenuMobile: false,
    showCreate: false,
    showQueueMobile: false,
    textNewPlaylist: "",
    addModal: false,
    error: null,
    showDeleteModal: false,
    loadingRemove: false,
  };

  getIcon = (type) => {
    const typeToIconMapper = {
      composerIcon: iconMenuComposer,
      note: iconMenuWork,
      notePlus: iconMenuPlaylist,
    };

    return type ? <img src={getAbsolutePath(typeToIconMapper[type])} alt="" /> : null;
  };

  getPlayList = async () => {
    const { lang, setPlaylists } = this.props;
    const urlPlaylist = `${process.env.REACT_APP_VIALMA_API}/clients/playlists?lang=${lang}`;
    const loginData = JSON.parse(localStorage.getItem("loginData"));
    const tokenHash = loginData && loginData.token;
    const token = `Bearer ${tokenHash}`;
    const response = await axios.get(urlPlaylist, {
      headers: {
        Authorization: token,
      },
    });
    setPlaylists(response.data);
  };
  toggleMenu = () => {
    this.setState({ moreMenu: !this.state.moreMenu, moreMenuMobile: true });
    setTimeout(() => {
      window.addEventListener("click", this.hideMenu);
    }, 0);
  };
  hideMenu = (event) => {
    const classname = event.target.className;
    const excludes = ["button-create", "add-to-playlist", "input-create", "icon-create", "input-create focus-visible"];
    if (!excludes.includes(classname)) {
      this.setState({ moreMenu: false });
      setTimeout(() => {
        window.removeEventListener("click", this.hideMenu);
      }, 0);
    }
  };

  onUrlOptionClick = (option) => {
    const { runAfterRedirect, isMiniPlayer, setExpandedPlayerCollapsed } = this.props;
    this.setState({ moreMenu: false, moreMenuMobile: false });
    isMiniPlayer && option?.miniPlayerTrackingFunc?.();
    setExpandedPlayerCollapsed(true);
    if (runAfterRedirect) {
      runAfterRedirect();
    }
  };

  createPlaylist = async (listSongs, textNewPlaylist) => {
    if (!textNewPlaylist.trim()) return;
    const { t } = this.props;
    const urlCreatePlaylist = `${process.env.REACT_APP_VIALMA_API}/playlists/edit`;
    const encodedNewPlaylistName = encodeWithQuote(textNewPlaylist);
    const payload = {
      title: encodedNewPlaylistName,
      description: "",
      enabled: true,
      type: "customer",
    };
    const tokenHash = JSON.parse(localStorage.getItem("loginData")).token;
    const token = `Bearer ${tokenHash}`;
    axios
      .post(urlCreatePlaylist, payload, {
        headers: {
          Authorization: token,
        },
      })
      .then(() => {
        this.getPlayList().then(() => {
          const { playlists } = this.props;
          const addedPlaylist = playlists[playlists.length - 1];
          const addedPlaylistId = addedPlaylist.id;
          const addedPlaylistName = addedPlaylist.album_title;
          notificationManager.showMessage("none", t("NOTIFICATION_PLAYLIST_CREATED", { playlistName: addedPlaylistName }));
          this.addToPlaylist(addedPlaylistId, listSongs);
        });
        this.setState({ error: null, moreMenuMobile: false, moreMenu: false });
        return true;
      })
      .catch((error) => {
        notificationManager.showMessage("none", t("NOTIFICATION_PLAYLIST_CREATION_FAILURE"));
        this.setState({ error: t("MY_LIBRARY_PLAYLISTS_CREATE_ERROR") });
      });
  };

  addToPlaylist = (id, listSongs) => {
    const [songId] = listSongs;
    const urlAddToPlayList = `${process.env.REACT_APP_VIALMA_API}/playlists/${id}/add/song`;
    const payload = { songs: listSongs };
    const tokenHash = JSON.parse(localStorage.getItem("loginData")).token;
    const token = `Bearer ${tokenHash}`;
    const { t, isMiniPlayer } = this.props;
    axios
      .post(urlAddToPlayList, payload, {
        headers: {
          Authorization: token,
        },
      })
      .then(() => {
        Mixpanel.track("Saved Track to Playlist", {
          TrackID: songId,
          Universe: window.location.href.indexOf("/jazz") !== -1 ? "Jazz" : "Classical",
        });
        isMiniPlayer && playerAddToPlayistTracking();
        notificationManager.showMessage("none", t("NOTIFICATION_ADDED_TO_PLAYLIST"));
        this.setState({ addModal: false, moreMenuMobile: false, moreMenu: false });
      })
      .catch((error) => {
        notificationManager.showMessage("none", t("NOTIFICATION_ADD_TO_PLAYLIST_FAILURE"));
      });
  };
  hideMenuMobile = () => {
    this.setState({ moreMenuMobile: false });
  };
  toggleQueueMobile = () => {
    this.setState({ showQueueMobile: !this.state.showQueueMobile });
  };
  showAddModal = () => {
    this.setState({ addModal: true });
  };
  hideAddModal = () => {
    this.setState({ addModal: false });
  };

  openDeleteModal = () => {
    this.setState({
      showDeleteModal: true,
    });
  };
  closeDeleteModal = () => {
    this.handleModalReset();
    this.setState({
      showDeleteModal: false,
    });
  };

  handleModalReset = () => {
    const { removeFromPlaylistProps } = this.props;
    if (removeFromPlaylistProps) {
      const { resetModal } = removeFromPlaylistProps;
      resetModal(null);
    }
  };

  initRemoveTrack = () => {
    const { listSongs } = this.props;
    const [songId] = listSongs;
    const { removeFromPlaylist } = this.props.removeFromPlaylistProps;
    removeFromPlaylist(songId);
  };

  render() {
    const { moreMenu, moreMenuMobile, showQueueMobile } = this.state;
    const {
      t,
      playlists,
      listSongs,
      isLogged,
      workId,
      isJazz = false,
      albumDetails,
      hideAddToPlaylist,
      urlOptions,
      removeFromPlaylistProps = false,
      userPlaylistId,
      track,
      isMiniPlayer,
      isWebplayer,
      playSong,
    } = this.props;

    let loadingRemove = false;
    let showDeleteModalProp;
    if (removeFromPlaylistProps) {
      loadingRemove = removeFromPlaylistProps.loadingRemove;
      showDeleteModalProp = removeFromPlaylistProps.showModal;
    }
    const { showDeleteModal } = this.state;
    const isModalOpen = typeof showDeleteModalProp === "boolean" ? showDeleteModalProp : showDeleteModal;

    const isUserPlaylist = playlists && playlists.some((playlist) => parseInt(playlist.id) === parseInt(userPlaylistId));
    const geoRestricted = !isCountryAllowed(track);

    return (
      <div className={`icon-more`}>
        {removeFromPlaylistProps && (
          <Modal
            isOpen={isModalOpen}
            onRequestClose={this.closeDeleteModal}
            onAfterClose={this.closeDeleteModal}
            style={customStyles}
            ariaHideApp={false}
          >
            <div className="remove-song-modal">
              <div className="icon-close flex justify-flex-end align-items-center" onClick={this.closeDeleteModal}>
                <img src={getAbsolutePath(iconClose)} alt="" />
              </div>
              {t("MY_PLAYLISTS_DELETE_TRACK_POPUP")}
              <div onClick={this.initRemoveTrack} className="button-red">
                {!loadingRemove && <span>{t("MY_PLAYLIST_DELETE_TRACK_CTA")}</span>}
                {loadingRemove && <LoadingBubble color={"#ffffff"} />}
              </div>
            </div>
          </Modal>
        )}
        <img onClick={this.toggleMenu} className="iconMore" src={getAbsolutePath(iconMore)} alt="" />
        <MediaQuery minWidth={881}>
          {moreMenu && (
            <MenuMorePopupDesktop
              t={t}
              moreMenu={moreMenu}
              listSongs={listSongs}
              isLogged={isLogged}
              hideAddToPlaylist={hideAddToPlaylist}
              addToPlaylist={this.addToPlaylist}
              createPlaylist={this.createPlaylist}
              removeFromPlaylistProps={removeFromPlaylistProps}
              isUserPlaylist={isUserPlaylist}
              openDeleteModal={this.openDeleteModal}
              track={track}
              urlOptions={urlOptions}
              onUrlOptionClick={this.onUrlOptionClick}
              getIcon={this.getIcon}
              geoRestricted={geoRestricted}
              isMiniPlayer={isMiniPlayer}
            />
          )}
        </MediaQuery>
        <MediaQuery maxWidth={880}>
          {moreMenuMobile && (
            <MenuMorePopupMobile
              t={t}
              moreMenu={moreMenu}
              listSongs={listSongs}
              isLogged={isLogged}
              hideAddToPlaylist={hideAddToPlaylist}
              addToPlaylist={this.addToPlaylist}
              createPlaylist={this.createPlaylist}
              removeFromPlaylistProps={removeFromPlaylistProps}
              isUserPlaylist={isUserPlaylist}
              openDeleteModal={this.openDeleteModal}
              track={track}
              urlOptions={urlOptions}
              onUrlOptionClick={this.onUrlOptionClick}
              getIcon={this.getIcon}
              geoRestricted={geoRestricted}
              isMiniPlayer={isMiniPlayer}
              isJazz={isJazz}
              workId={workId}
              albumDetails={albumDetails}
              goToComposer={this.goToComposer}
              goToAlbum={this.goToAlbum}
              goToWork={this.goToWork}
              hideMenuMobile={this.hideMenuMobile}
              showQueueMobile={showQueueMobile}
              toggleQueueMobile={this.toggleQueueMobile}
              isWebplayer={isWebplayer}
              playSong={playSong}
            />
          )}
        </MediaQuery>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: getLangSelector(state),
  playlists: getPlaylistsSelector(state),
  userLikedTracks: getUserLikedTracksSelector(state),
  isLogged: getLoginSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  setPlaylists: (playlists) => {
    dispatch(dispatchSetPlaylists(playlists));
  },
  setUserLikedTracks: (playlists) => {
    dispatch(dispatchSetUserLikedTracks(playlists));
  },
  setExpandedPlayerCollapsed: (data) => {
    dispatch(dispatchUpdateExpandedPlayerCollapsed(data));
  },
});

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuMore)));
