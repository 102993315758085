import isEmpty from "lodash/isEmpty";
import { getTrackIdFromTrack } from "utils/metaDataTools";

export const getTrackId = (data, getNext = false) => {
  if (data.dataTrack) {
    const index = getNext ? data.indexOfTrack + 1 : data.indexOfTrack;
    let trackId = null;
    if (index <= data.dataTrack.length) {
      trackId = getTrackIdFromTrack(data.dataTrack[index]);
    }
    return trackId;
  }
  return null;
};


export const getChapterId = (miniPlayer) => {
  if (!isEmpty(miniPlayer?.dataTrack)) {
    const index = miniPlayer.indexOfTrack;
    const chapterId = miniPlayer.dataTrack[index].id;
    return chapterId;
  }
  return null;
};