/**
 * Builds the URL for the dynamically resized image
 * Please only use this if EnhancedImage component doesn't fit your requirement.
 */
const getSizedImagePath = (size = "medium", imageRoot, imagePath, fallbackImage) => {
  if (!["small", "medium", "large", "xlarge"].includes(size)) {
    return fallbackImage;
  }

  if (!imageRoot || !imagePath) return fallbackImage;

  // we only resize for preprod and prod. Not stage. Below URL is prod Cloudfront
  if (imageRoot !== "https://d12nyqr7iviw59.cloudfront.net") return imageRoot + imagePath;

  try {
    return imageRoot + `/${size}` + imagePath;
  } catch {
    return fallbackImage;
  }
};

export default getSizedImagePath;
