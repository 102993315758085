import produce from 'immer';
import {
  SET_WORKS,
  SET_WORKS_COMPOSERS,
  SET_INDEX_ROOT_CATEGORY,
  SET_INDEX_WORKS_COMPOSERS_CATEGORY,
  SET_CATEGORY_NAME,
  SET_FAMOUS_FILTER,
  SET_PERFORMANCES,
  SET_LANG_WORKS,
  SET_WORKS_DEFAULTS,
} from './constants';

const initialState = {
  works: null,
  worksComposers: null,
  indexRootCategory: null,
  indexWorksComposersCategory: null,
  categoryName: null,
  famousFilter: false,
  performances: null,
  langWorks: 'en',
};

const worksReducer = function (state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SET_WORKS:
        draft.works = action.works
        break;
      case SET_WORKS_COMPOSERS:
        draft.worksComposers = action.worksComposers
        break;
      case SET_INDEX_ROOT_CATEGORY:
        draft.indexRootCategory = action.indexRootCategory
        break;
      case SET_INDEX_WORKS_COMPOSERS_CATEGORY:
        draft.indexWorksComposersCategory = action.indexWorksComposersCategory
        break;
      case SET_CATEGORY_NAME:
        draft.categoryName = action.categoryName
        break;
      case SET_FAMOUS_FILTER:
        draft.famousFilter = action.famousFilter
        break;
      case SET_PERFORMANCES:
        draft.performances = action.performances
        break;
      case SET_LANG_WORKS:
        draft.langWorks = action.langWorks
        break;
      case SET_WORKS_DEFAULTS:
        return initialState;
      default:
        return;
    }
  });
}

export default worksReducer;