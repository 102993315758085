import produce from "immer";
import { PAGE_ORIGIN_UNKNOWN } from "utils/nameVariables";
import { UPDATE_MINI_PLAYER, SET_IS_WEBPLAYER, SET_SLUG_WEBPLAYER, UPDATE_MODAL, SET_EXPANDED_PLAYER_OPEN_STATE } from "./constants";

const initialState = {
  playing: false,
  url: null,
  indexOfTrack: 0,
  dataTrack: null,
  isWebPlayer: localStorage.getItem("isWebPlayer") ? true : false,
  collapsed: true,
  slug: null,
  composerImage: null,
  composerName: null,
  composerSlug: null,
  composerId: null,
  showModal: false,
  jazzMode: false,
  playlist: null,
  pageOrigin: PAGE_ORIGIN_UNKNOWN,
  platformOrigin: "web",
  isFetching: false,
  mediaId: null, //update this to the playlistId as well (when its a playlist) to use the getIsCurrentlyFetchingOrLoading function
  mediaProps: null,
  position: 0,
  volume: 80,
  volumeMute: false,
  expandedPlayerCollapsed: true
};

const miniplayerReducer = function (state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case UPDATE_MINI_PLAYER:
        Object.assign(draft, action.data);
        break;
      case SET_IS_WEBPLAYER:
        draft.isWebPlayer = action.isWebPlayer;
        break;
      case SET_SLUG_WEBPLAYER:
        draft.slug = action.slug;
        break;
      case UPDATE_MODAL:
        draft.showModal = action.showModal;
        break;
      case SET_EXPANDED_PLAYER_OPEN_STATE:
        draft.expandedPlayerCollapsed = action.expandedPlayerCollapsed;
        break;
      default:
        return;
    }
  });
};

export default miniplayerReducer;
