import produce from 'immer';
import {
  SET_USER_COUNTRY,
  UPDATE_ACCOUNT,
} from './constants';

const initialState = {
  account: null,
  userCountry: null,
};

const accountReducer = function (state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case UPDATE_ACCOUNT:
        draft.account = action.account;
        break;
      case SET_USER_COUNTRY:
        draft.userCountry = action.userCountry;
        break;
      default:
        return;
    }
  });
}

export default accountReducer;