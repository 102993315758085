import api from "utils/apiManager";
import { CLASSICAL_NAME, DEFAULT_MEDIA_ROW_COUNT, JAZZ_NAME } from "utils/nameVariables";
import { fetchLang, isHeaderJazzUniverse } from "utils/storeUtils";
import { getHeaders } from "..";
import { albumUrl, getAlbumAlsoLikeUrl, getAlbumCategoriesUrl, getDailyAlbumsUrl } from "../urls";

export const apiLibraryGetAlbumDetails = async (id, isJazz) => {
  const lang = fetchLang();
  const headers = getHeaders();
  const universe = isHeaderJazzUniverse() || isJazz ? JAZZ_NAME : CLASSICAL_NAME;
  const albumDetailsUrl = albumUrl(id, lang, universe);
  try {
    const albumDetails = await api({ url: albumDetailsUrl, headers });
    if (albumDetails.status === 200) return albumDetails.data;
  } catch (error) {
    return error;
  }
};

export const apiLibraryGetAlbumCategories = async (limit) => {
  const lang = fetchLang();
  const headers = getHeaders();
  const universe = isHeaderJazzUniverse() ? JAZZ_NAME : CLASSICAL_NAME;
  const albumCategoriesUrl = getAlbumCategoriesUrl(limit, lang, universe);

  try {
    const resp = await api({ url: albumCategoriesUrl, headers });
    if (resp.status === 200) {
      return resp.data;
    }
    return null;
  } catch (error) {
    return false;
  }
};

export const apiLibraryGetAlbumAlsoLike = async (albumId) => {
  const headers = getHeaders();
  const lang = fetchLang();
  const universe = isHeaderJazzUniverse() ? JAZZ_NAME : CLASSICAL_NAME;
  const url = getAlbumAlsoLikeUrl(albumId, lang, universe);

  try {
    const resp = await api({ url, headers });
    if (resp.status === 200) {
      return resp.data;
    }
    return null;
  } catch (error) {
    return false;
  }
};

export const apiLibraryGetDailyAlbums = async (count = DEFAULT_MEDIA_ROW_COUNT) => {
  const headers = getHeaders();
  const universe = isHeaderJazzUniverse() ? JAZZ_NAME : CLASSICAL_NAME;
  const dailyAlbumsUrl = getDailyAlbumsUrl(count, universe);

  try {
    const resp = await api({ url: dailyAlbumsUrl, headers });
    if (resp.status === 200) {
      return resp;
    }
    return null;
  } catch (error) {
    return false;
  }
};