import { store } from "App.js";
import has from "lodash/has";
import getSizedImagePath from "./getSizedImagePath";
import { IS_HEADER_JAZZ } from "./localStoreKeys";
import { getJazzAlbumImageFromTrack } from "./metaDataTools";
import defaultPlaylistImage from "assets/images/notes_grey_bg.svg";

//contains generic store related functions
const fetchLang = () => {
  const storeState = store.getState();
  if (has(storeState, "config.lang")) {
    return storeState.config.lang;
  }
};

const isLoggedIn = () => {
  const storeState = store.getState();
  if (has(storeState, "config.isLogged")) {
    return storeState.config.isLogged;
  }
};

const isHeaderJazzUniverse = (urlFlag) => {
  const storeState = store.getState();
  if (has(storeState, "config.isJazzSelected") || urlFlag) {
    return storeState.config.isJazzSelected || (urlFlag && window.location.href.indexOf("/jazz") !== -1);
  }
};

const isHeaderJazzUniverseLocalStore = () => {
  let storeVal = localStorage.getItem(IS_HEADER_JAZZ);
  return storeVal ? storeVal === "true" : false;
};

const getSubscriptionFromRedux = () => {
  const storeState = store.getState();
  if (has(storeState, "account.account.subscription_ensemble")) {
    return storeState.account.account.subscription_ensemble;
  }
};

const getSubscriptionName = () => {
  let resp = "";
  if (isLoggedIn()) {
    const loginData = JSON.parse(localStorage.getItem("loginData"));
    if (!loginData) return resp;
    const subscriptionData = loginData?.subscription_ensemble;
    resp = subscriptionData?.subscription?.name || "";
  }
  return resp;
};

const getSubscriptionEndDate = (lang) => {
  let resp = "{Error: No Date Found}";
  const locale = lang === "en" ? "en-US" : "fr-FR";
  const storeState = store.getState();
  if (isLoggedIn()) {
    if (has(storeState, "account.account.subscription_ensemble")) {
      const subscriptionData = storeState.account.account.subscription_ensemble;
      const dateStr = subscriptionData.cycleEndDate.date.substring(0, 10);
      const date = new Date(dateStr);
      let options = { year: "numeric", month: "long", day: "numeric" };
      resp = date.toLocaleString(locale, options);
    } else {
      resp = "{Error: No Subscription object found}";
    }
  }
  return resp;
};

const getIsCurrentlyPlayingOrFetching = (id) => {
  const storeState = store.getState();
  let resp = {
    isCurrentlyPlaying: null,
    isCurrentlyLoading: null,
  };
  if (storeState?.miniPlayer?.mediaId && id) {
    const miniPlayer = storeState.miniPlayer;
    resp = {
      isCurrentlyPlaying: miniPlayer.playing && miniPlayer.mediaId.toString().replace(/\s/g, "") === id.toString().replace(/\s/g, ""),
      isCurrentlyLoading: miniPlayer.isFetching && miniPlayer.mediaId.toString().replace(/\s/g, "") === id.toString().replace(/\s/g, ""),
    };
  }
  return resp;
};

const getUserCountry = () => {
  const storeState = store.getState();
  if (has(storeState, "account.userCountry")) {
    return storeState.account.userCountry;
  }
};

const getOverlayImg = (isMobile) => {
  const storeState = store.getState();

  if (has(storeState, "miniPlayer.dataTrack") && has(storeState, "miniPlayer.indexOfTrack")) {
    const { imageRootUrl, imagePath } = getJazzAlbumImageFromTrack(storeState.miniPlayer.dataTrack?.[storeState.miniPlayer.indexOfTrack]);
    return (
      <div
        className={`overlayResp ${isMobile ? "mobile" : ""}`}
        style={{
          backgroundImage: `url(${getSizedImagePath("small", imageRootUrl, imagePath, defaultPlaylistImage)})`,
        }}
      />
    );
  }
};

const getUserEmail = () => {
  if (isLoggedIn) {
    const storeState = store.getState();
    if (has(storeState, "account.account.email")) {
      return storeState.account.account.email;
    }
  }
};

const getIsFlacStream = () => {
  const storeState = store.getState();
  if (has(storeState, "config.isFlacStream")) {
    return storeState.config.isFlacStream;
  }
};

export {
  fetchLang,
  isLoggedIn,
  isHeaderJazzUniverse,
  isHeaderJazzUniverseLocalStore,
  getSubscriptionFromRedux,
  getSubscriptionName,
  getSubscriptionEndDate,
  getIsCurrentlyPlayingOrFetching,
  getUserCountry,
  getOverlayImg,
  getUserEmail,
  getIsFlacStream,
};
