import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import iconClose from "assets/images/close_search.svg";
import filterCheckbox from "assets/images/filter_checkbox.svg";
import filterCheckboxChecked from "assets/images/filter_checkbox_checked.svg";
import getAbsolutePath from "utils/getAbsolutePath";
import { playerChangedAudioQualityTracking } from "utils/miniPlayerMixpanelUtils";
import { handleToggleAudioQuality } from "utils/apiLibrary/client";

const StreamQualityPopup = ({ t, closeModal, isMiniPlayer }) => {
  const [checked, setChecked] = useState(false);

  const toggleCheckbox = () => {
    setChecked(!checked);
  };

  const switchToFlac = () => {
    handleToggleAudioQuality();
    if (checked) localStorage.setItem("disableFlacAlert", true);
    isMiniPlayer && playerChangedAudioQualityTracking();
    closeModal();
  };

  return (
    <div className="streamQualityPopup">
      <div className="icon-close">
        <img onClick={closeModal} src={iconClose} alt="" />
      </div>
      <div className="content-wrapper">
        <div className="stream-title">{t("NOTIFICATION_CHANGE_AUDIO_FORMAT_TITLE")}</div>
        <div className="stream-description">{t("NOTIFICATION_CHANGE_AUDIO_FORMAT_CONTENT")}</div>
        <div className="stream-button-wrapper">
          <button className="button-red" onClick={switchToFlac}>
            {t("NOTIFICATION_CHANGE_AUDIO_FORMAT_SWITCH")}
          </button>
          <button className="button-white-outlined" onClick={closeModal}>
            {t("NOTIFICATION_CHANGE_AUDIO_FORMAT_CANCEL")}
          </button>
        </div>
        <div className="stream-dontshow-wrapper" onClick={toggleCheckbox}>
          <img src={getAbsolutePath(filterCheckboxChecked)} style={{ display: `${checked ? "block" : "none"}` }} alt="" />
          <img src={getAbsolutePath(filterCheckbox)} style={{ display: `${checked ? "none" : "block"}` }} alt="" />
          <span>{t("NOTIFICATION_CHANGE_AUDIO_FORMAT_DONTSHOW")}</span>
        </div>
        <div className="stream-footer-notification">{t("NOTIFICATION_NO_FLAC_MOBILE_WARNING")}</div>
      </div>
    </div>
  );
};

export default withTranslation()(StreamQualityPopup);
