import React from "react";

const Divider = ({ height = 10, showBorder = true, borderGrey }) => {
  return (
    <div className="divider-component">
      <div
        className={`${showBorder ? "divider-component__top" : ""} ${borderGrey ? "border-grey" : ""} `}
        style={{ paddingTop: `${height / 2}px` }}
      ></div>
      <div style={{ paddingBottom: `${height / 2}px` }}></div>
    </div>
  );
};

export default Divider;
