import React, { useState, Fragment, useCallback } from "react";
import Heart from "components/Heart";
import { connect, useSelector } from "react-redux";
import notificationManager from "utils/notificationManager";
import { Link } from "react-router-dom";
import { accessRoutes } from "components/ApplicationRoutes/routes";
import { Mixpanel } from "utils/mixpanel";
import {
  dispatchPlusMinusVialmaPlaylistLiked,
  dispatchSetUserLikedAlbums,
  dispatchSetUserLikedCollections,
  dispatchSetUserLikedVideos,
  dispatchSetVialmaPlaylists,
} from "modules/Library/actions.js";
import { dispatchShowSignupFreeTrialModal } from "modules/Config/actions";
import { apiLibraryLikeUnlikeAlbum, apiLibraryLikeUnlikeCollection, apiLibraryLikeUnlikePlaylist, apiLibraryLikeUnlikeVideo } from "utils/apiLibrary/misc";
import { ALBUM, COLLECTION, PLAYLIST, VIDEO } from "utils/nameVariables";
import { getLangSelector, getLoginSelector } from "modules/Config/selectors";
import { withTranslation } from "react-i18next";

const MAX_ALLOWED_FAVOURITE = 500;

const MediaFavouriteContainer = ({
  mediaId,
  type = PLAYLIST,
  lang,
  t,
  isLogged,
  heartBackground,
  heartSize,
  isJazz = null,
  showNotification = true,
  setShowSignupFreeTrialModal,
  setPlusMinusVialmaPlaylistLiked,
  setVialmaPlaylists,
  setUserLikedAlbums,
  setUserLikedVideos,
  setUserLikedCollections,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const vialmaPlaylists = useSelector((state) => state.library.vialmaPlaylists);
  const vialmaPlaylistLiked = useSelector((state) => state.library.vialmaPlaylistLiked);
  const likedAlbums = useSelector((state) => state.library.userLikedAlbums);
  const likedVideos = useSelector((state) => state.library.userLikedVideos);
  const likedCollections = useSelector((state) => state.library.userLikedCollections);

  const isPlaylistHeart = type === PLAYLIST;
  const isAlbumHeart = type === ALBUM;
  const isVideoHeart = type === VIDEO;
  const isCollectionHeart = type === COLLECTION;

  const getIsMediaLiked = () => {
    let resp = false;
    if (isPlaylistHeart) {
      resp =
        Boolean(Array.isArray(vialmaPlaylists) && mediaId) && vialmaPlaylists.find((playlist) => playlist.id.toString() === mediaId.toString())
          ? true
          : null;
    }
    if (isAlbumHeart) {
      resp = Boolean(Array.isArray(likedAlbums) && mediaId) && likedAlbums.find((album) => album.id.toString() === mediaId.toString()) ? true : null;
    }
    if (isVideoHeart) {
      resp = Boolean(Array.isArray(likedVideos) && mediaId) && likedVideos.find((video) => video.id.toString() === mediaId.toString()) ? true : null;
    }
    if (isCollectionHeart) {
      resp =
        Boolean(Array.isArray(likedCollections) && mediaId) && likedCollections.find((collection) => collection.id.toString() === mediaId.toString())
          ? true
          : null;
    }
    return resp;
  };

  const isMediaLiked = getIsMediaLiked();

  const openTrialModal = () => {
    setShowSignupFreeTrialModal(true, t("PLAYLISTS_LOGGED_OUT_LIKED_POPUP"));
  };

  const handleClick = async (isAdd = true) => {
    if (isLoading) return;

    if (!isLogged) {
      openTrialModal();
      return;
    }

    if (isAdd && isPlaylistHeart && vialmaPlaylistLiked >= MAX_ALLOWED_FAVOURITE) {
      notificationManager.showMessage(
        "fail",
        <span>
          {t("NOTIFICATION_PLAYLIST_EXCEEDED_LIMIT")}
          <Link to={{ pathname: accessRoutes[lang].library, state: { jazzMode: !!isJazz } }}>{t("NOTIFICATION_PLAYLIST_LIKED_PART2_URL")}</Link>
        </span>
      );
      throw new Error("Exceeded playlist limit");
    }

    if (!mediaId) return;

    setIsLoading(true);

    let method = isAdd ? "POST" : "DELETE";
    let successMessage = "";
    let failMessage = "";

    if (isPlaylistHeart) {
      successMessage = isAdd ? (
        <span>
          {t("NOTIFICATION_PLAYLIST_LIKED_PART1")}
          <Link to={accessRoutes[lang].library}>{t("NOTIFICATION_PLAYLIST_LIKED_PART2_URL")}</Link>
        </span>
      ) : (
        t("NOTIFICATION_PLAYLIST_DISLIKED")
      );
      failMessage = isAdd ? t("NOTIFICATION_PLAYLIST_LIKE_FAILED") : t("NOTIFICATION_PLAYLIST_DISLIKE_FAILED");
    }

    if (isAlbumHeart) {
      successMessage = isAdd ? (
        <span>
          {t("NOTIFICATION_ALBUM_LIKED_PART1")}&nbsp;
          <Link to={`${accessRoutes[lang].library}#${t("HASH_LIKED_ALBUMS")}`}>{t("NOTIFICATION_ALBUM_LIKED_PART2_URL")}</Link>
        </span>
      ) : (
        t("NOTIFICATION_ALBUM_DISLIKED")
      );

      failMessage = isAdd ? t("NOTIFICATION_ALBUM_LIKE_FAILED") : t("NOTIFICATION_ALBUM_DISLIKE_FAILED");
    }
    if (isVideoHeart) {
      successMessage = isAdd ? (
        <span>
          {t("NOTIFICATION_VIDEO_LIKED_PART1")}&nbsp;
          <Link to={`${accessRoutes[lang].library}#${t("HASH_LIKED_VIDEOS")}`}>{t("NOTIFICATION_VIDEO_LIKED_PART2_URL")}</Link>
        </span>
      ) : (
        t("NOTIFICATION_VIDEO_DISLIKED")
      );
      failMessage = isAdd ? t("NOTIFICATION_VIDEO_LIKE_FAILED") : t("NOTIFICATION_VIDEO_DISLIKE_FAILED");
    }
    if (isCollectionHeart) {
      successMessage = isAdd ? (
        <span>
          {t("NOTIFICATION_COLLECTION_LIKED_PART1")}&nbsp;
          <Link to={`${accessRoutes[lang].library}#${t("HASH_LIKED_COLLECTIONS")}`}>{t("NOTIFICATION_COLLECTION_LIKED_PART2_URL")}</Link>
        </span>
      ) : (
        t("NOTIFICATION_COLLECTION_DISLIKED")
      );
      failMessage = isAdd ? t("NOTIFICATION_COLLECTION_LIKE_FAILED") : t("NOTIFICATION_COLLECTION_DISLIKE_FAILED");
    }
    handeLikeOrUnlikeMedia(method, successMessage, failMessage, isAdd);
  };

  const handleUpdate = useCallback(
    (isAdd, resp) => {
      let itemFromResp = type === COLLECTION ? resp.data.data : resp.data.data[type];

      if (isAdd) {
        if (isPlaylistHeart) {
          isPlaylistHeart && setPlusMinusVialmaPlaylistLiked(isAdd);
          isPlaylistHeart && setVialmaPlaylists(vialmaPlaylists, itemFromResp, "add");
        }
        if (isAlbumHeart) setUserLikedAlbums(likedAlbums, itemFromResp, "add");
        if (isVideoHeart) setUserLikedVideos(likedVideos, itemFromResp, "add");
        if (isCollectionHeart) setUserLikedCollections(likedCollections, itemFromResp, "add");
      } else {
        let idToFind = isAlbumHeart ? itemFromResp.album_metadata.id : itemFromResp.id;
        if (isPlaylistHeart) {
          isPlaylistHeart && setPlusMinusVialmaPlaylistLiked(isAdd);
          isPlaylistHeart && setVialmaPlaylists(vialmaPlaylists, idToFind, "remove");
        }
        if (isAlbumHeart) setUserLikedAlbums(likedAlbums, idToFind, "remove");
        if (isVideoHeart) setUserLikedVideos(likedVideos, idToFind, "remove");
        if (isCollectionHeart) setUserLikedCollections(likedCollections, idToFind, "remove");
      }
    },
    [
      isAlbumHeart,
      isPlaylistHeart,
      isVideoHeart,
      isCollectionHeart,
      likedAlbums,
      likedVideos,
      likedCollections,
      type,
      vialmaPlaylists,
      setUserLikedCollections,
      setPlusMinusVialmaPlaylistLiked,
      setVialmaPlaylists,
      setUserLikedAlbums,
      setUserLikedVideos,
    ]
  );

  const handeLikeOrUnlikeMedia = async (method, successMessage, failMessage, isAdd) => {
    try {
      let resp;
      if (isPlaylistHeart) resp = await apiLibraryLikeUnlikePlaylist(method, mediaId);
      if (isAlbumHeart) resp = await apiLibraryLikeUnlikeAlbum(method, mediaId);
      if (isVideoHeart) resp = await apiLibraryLikeUnlikeVideo(method, mediaId);
      if (isCollectionHeart) resp = await apiLibraryLikeUnlikeCollection(method, mediaId);

      if (resp) {
        handleUpdate(isAdd, resp);
        showNotification && notificationManager.showMessage("heart", successMessage);
        Mixpanel.track(`Favourited a ${type?.toLocaleLowerCase()}`);
      } else {
        notificationManager.showMessage("fail", failMessage);
      }
    } catch (e) {
      notificationManager.showMessage("fail", failMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <Heart
        isDefaultChecked={isMediaLiked}
        onCheck={() => handleClick(true)}
        onUncheck={() => handleClick(false)}
        withBackground={heartBackground}
        size={heartSize}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  lang: getLangSelector(state),
  isLogged: getLoginSelector(state),
});

const mapDispatcherToProps = (dispatch) => ({
  setShowSignupFreeTrialModal: (showSignupFreeTrialModal, headerText) => {
    dispatch(dispatchShowSignupFreeTrialModal(showSignupFreeTrialModal, headerText));
  },
  setPlusMinusVialmaPlaylistLiked: (isAdd) => {
    dispatch(dispatchPlusMinusVialmaPlaylistLiked(isAdd));
  },
  setVialmaPlaylists: (vialmaPlaylists, item, action) => {
    dispatch(dispatchSetVialmaPlaylists(vialmaPlaylists, item, action));
  },
  setUserLikedAlbums: (userLikedAlbums, item, action) => {
    dispatch(dispatchSetUserLikedAlbums(userLikedAlbums, item, action));
  },
  setUserLikedVideos: (userLikedVideos, item, action) => {
    dispatch(dispatchSetUserLikedVideos(userLikedVideos, item, action));
  },
  setUserLikedCollections: (userLikedCollections, item, action) => {
    dispatch(dispatchSetUserLikedCollections(userLikedCollections, item, action));
  },
});

export default withTranslation()(connect(mapStateToProps, mapDispatcherToProps)(MediaFavouriteContainer));
