import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { ARTIST } from "utils/nameVariables";
import defaultArtistImage from "assets/images/default_person_avatar.svg";
import defaultPlaylistImage from "assets/images/notes_grey_bg.svg";

import EnhancedImage from "components/EnhancedImage";

const Pill = (props) => {
  const { type, imageRootUrl, imagePath, topContent, bottomContent, targetUrl, square, handleClick = () => {} } = props;
  const defaultImage = type === ARTIST ? defaultArtistImage : defaultPlaylistImage;
  return (
    type && (
      <Link to={targetUrl} onClick={handleClick} className={`pill ${square ? "square" : ""} flex flex-row`}>
        <div className="image-container">
          <EnhancedImage size="small" imageRootUrl={imageRootUrl} imagePath={imagePath} fallbackImage={defaultImage} alt={topContent} />
        </div>

        <div className="detail-container flex flex-column">
          <div className="mb-5">{topContent}</div>
          <div>{bottomContent}</div>
        </div>
      </Link>
    )
  );
};

export default withTranslation()(Pill);
