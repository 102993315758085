import { Mixpanel } from "./mixpanel";

export const playerExtendedTracking = () => {
  Mixpanel.track("Player Interaction", {
    Action: "Player expanded",
  });
};
export const playerAddToPlayistTracking = () => {
  Mixpanel.track("Player Interaction", {
    Action: "Add to playlist",
  });
};

export const expandedPlayerAddToPlayistTracking = () => {
  Mixpanel.track("Expanded Player Interaction", {
    Action: "Add to playlist",
  });

};
export const playerSaveTrackTracking = () => {
  Mixpanel.track("Player Interaction", {
    Action: "Save a track",
  });
};
export const playerUnsaveTrackTracking = () => {
  Mixpanel.track("Player Interaction", {
    Action: "Unsave a track",
  });
};
export const playerGoToComposerTracking = () => {
  Mixpanel.track("Player Interaction", {
    Action: "Go to composer",
  });
};
export const playerGoToArtistTracking = () => {
  Mixpanel.track("Player Interaction", {
    Action: "Go to artist",
  });
};
export const playerGoToAlbumTracking = () => {
  Mixpanel.track("Player Interaction", {
    Action: "Go to album",
  });
};
export const playerGoToWorkTracking = () => {
  Mixpanel.track("Player Interaction", {
    Action: "Go to work",
  });
};
export const playerGoToRecordingTracking = () => {
  Mixpanel.track("Player Interaction", {
    Action: "Go to recording",
  });
};
export const playerChangedAudioQualityTracking = () => {
  Mixpanel.track("Player Interaction", {
    Action: "Changed audio quality",
  });
};
export const playerArtistNameClickedTracking = () => {
  Mixpanel.track("Player Interaction", {
    Action: "Clicked artist name",
  });
};
export const playerGoToPodcastTracking = () => {
  Mixpanel.track("Player Interaction", {
    Action: "Go to podcast",
  });
};
export const playerGoToChapterTracking = () => {
  Mixpanel.track("Player Interaction", {
    Action: "Go to chapter",
  });
};

export const playerGoToPlayingFromTracking = () => {
  Mixpanel.track("Player Interaction", {
    Action: "Go to playing from",
  });
};
