import { createSelector } from 'reselect';
import { REDUCER_KEY } from './constants';

const getAccount = state => state[REDUCER_KEY].account;
const getUserCountry = state => state[REDUCER_KEY].userCountry;

export const getAccountSelector = createSelector(
  getAccount,
  account => account
);

export const getUserCountrySelector = createSelector(
  getUserCountry,
  userCountry => userCountry
);