import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { getMiniPlayerSelector, getCollapsedSelector } from "modules/MiniPlayer/selectors";
import { dispatchUpdateModalWebPlayer } from "modules/MiniPlayer/actions";
import { dispatchUpdateMiniPlayer } from "modules/MiniPlayer/actions";
import { getLangSelector, getLoginSelector } from "modules/Config/selectors";
import { Link } from "react-router-dom";
import isEmpty from "lodash/isEmpty";

const TrackInfoDesktop = ({
  t,
  isTabletOrMobile,
  isLandscape,
  title,
  collapsePlayer,
  work,
  displayArtist,
  isJazzTrack,
  displayArtistUrl,
  displayName,
  performers,
  recordLabel,
  recordingYear,
  hasMediaProps,
  playingFromUrl,
  handlePlayingFromClick,
  playingFromText,
}) => {
  return (
    <div className={`track-info ${isTabletOrMobile && isLandscape && "hide"}`}>
      <p className="pb-10">
        <span className="mr-10">{t("WEBPLAYER_TRACK_TITLE")}:</span>
        <span className="bold">{title}</span>
      </p>
      {work && (
        <p className="pb-10">
          <span className="mr-10">{t("WEBPLAYER_FROM_WORK")}:</span>
          <span className="bold">{work?.name}</span>
        </p>
      )}
      {displayArtist && (
        <p className="pb-10">
          <span className="mr-10">{t(isJazzTrack ? "WEBPLAYER_ARTIST" : "WEBPLAYER_COMPOSER")}:</span>
          <Link to={displayArtistUrl} className="bold">
            {displayName}
          </Link>
        </p>
      )}
      {!isEmpty(performers) && (
        <div className="pb-10">
          <span className="mr-10">{t("WEBPLAYER_PERFORMED_BY")}:</span>
          <span className="bold d-inline-block">
            <div>
              {performers.map((performer, index) => {
                return (
                  <Fragment key={performer.id}>
                    {index > 0 && ", "}
                    <Link to={performer.url} onClick={collapsePlayer}>
                      {performer.firstName} {performer.lastName}
                    </Link>
                  </Fragment>
                );
              })}
            </div>
          </span>
        </div>
      )}
      {recordLabel && (
        <p className="pb-10">
          <span className="mr-10">{t("WEBPLAYER_RECORD_LABEL")}:</span>
          <span className="bold">{recordLabel}</span>
        </p>
      )}
      {recordingYear && (
        <p className="pb-10">
          <span className="mr-10">{t("WEBPLAYER_RECORDED")}:</span>
          <span className="bold">{recordingYear}</span>
        </p>
      )}
      {hasMediaProps && (
        <p className="mt-5">
          <Link className="playing-from-link truncate tiny" to={playingFromUrl} onClick={handlePlayingFromClick}>
            {playingFromText}
          </Link>
        </p>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  miniPlayer: getMiniPlayerSelector(state),
  lang: getLangSelector(state),
  collapsed: getCollapsedSelector(state),
  isLogged: getLoginSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateMiniPlayer: (data) => {
    dispatch(dispatchUpdateMiniPlayer(data));
  },
  updateShowModalWebPlayer: (showModal) => {
    dispatch(dispatchUpdateModalWebPlayer(showModal));
  },
});

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(TrackInfoDesktop)));
