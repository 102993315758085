import "rc-slider/assets/index.css";
import React, { Component } from "react";
import { connect } from "react-redux";
import MenuMore from "components/MenuMore";
import { dispatchUpdateMiniPlayer, dispatchUpdateModalWebPlayer } from "modules/MiniPlayer/actions";
import { getMiniPlayerSelector } from "modules/MiniPlayer/selectors";
import { getLoginSelector, getLangSelector, getIsJazzSelector } from "modules/Config/selectors";
import { getFlacStreamSelector } from "modules/Config/selectors";
import { Mixpanel } from "utils/mixpanel";
import iconPlay from "assets/images/miniplayer/play.svg";
import iconPause from "assets/images/miniplayer/pause.svg";
import iconPrev from "assets/images/miniplayer/prev.svg";
import iconNext from "assets/images/miniplayer/skip.svg";
import getAbsolutePath from "utils/getAbsolutePath";
import { withRouter } from "react-router";
import SettingsCog from "components/SettingsCog";
import isEmpty from "lodash/isEmpty";
import { withTranslation } from "react-i18next";
import {
  getWorkFromTrack,
  getTrackIdFromTrack,
  getAlbumFromTrack,
  getDisplayArtistFromTrack,
  getRecordYear,
  isCountryAllowed,
} from "utils/metaDataTools";
import {
  classicalAlbumOption,
  composerOption,
  workOption,
  jazzAlbumOption,
  artistOption,
  recordingOption,
  podcastOption,
  chapterOption,
} from "components/MenuMore/MenuMoreOptions.js";
import { getUserLikedTracksSelector } from "modules/Library/selectors";
import { dispatchSetUserLikedTracks } from "../../modules/Library/actions";
import { isAllowedToPlay } from "utils/checkHasAccess";
import { dispatchUpdateShowClassicModal } from "modules/Config/actions";
import { PAGE_ORIGIN_PODCASTS } from "utils/nameVariables";

let pauseTimer;

class MobileExpandedControls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pausedForSeconds: 0,
    };
  }

  getIconPrev = () => {
    const { miniPlayer, isWebplayer } = this.props;
    const { indexOfTrack } = miniPlayer;
    if (indexOfTrack === 0) {
      return (
        <div className="iconPrev disabled" data-cy={`${isWebplayer ? "extended_" : ""}miniplayer_prev_disabled`}>
          <img src={getAbsolutePath(iconPrev)} alt="" />
        </div>
      );
    } else {
      return (
        <div className="iconPrev" onClick={() => this.changeSong(-1)} data-cy={`${isWebplayer ? "extended_" : ""}miniplayer_prev`}>
          <img src={getAbsolutePath(iconPrev)} alt="" />
        </div>
      );
    }
  };

  changeSong = (changeIndex = 1) => {
    const { miniPlayer, handlePlayerError = () => {} } = this.props;
    const { indexOfTrack, dataTrack } = miniPlayer;

    const newIndex = indexOfTrack + changeIndex;
    if (!dataTrack?.[newIndex]) {
      const { t } = this.props;
      handlePlayerError(t("COUNTRY_NOT_IN_REMAINING_TRACKS"));
      return;
    }
    if (dataTrack && newIndex < dataTrack.length) {
      this.playSong(newIndex);
    }

    window.dataLayer.push({
      event: "playerInteraction",
      interaction: changeIndex === 1 ? "forward" : "backward",
    });
    Mixpanel.track("Player Interaction", {
      Action: changeIndex === 1 ? "Forward" : "Backward",
    });
  };

  startPauseTimer = () => {
    pauseTimer = setInterval(() => {
      this.setState((prevState) => {
        const newPausedValue = prevState.pausedForSeconds + 1;
        return {
          pausedForSeconds: newPausedValue,
        };
      });
    }, 1000);
  };

  stopPauseTimer = () => {
    clearInterval(pauseTimer);
  };

  getIconNext = () => {
    const { miniPlayer, isWebplayer } = this.props;
    const { indexOfTrack, dataTrack } = miniPlayer;
    if (!dataTrack) {
      return null;
    }
    if (indexOfTrack + 1 === dataTrack.length) {
      return (
        <div className="iconNext disabled" data-cy={`${isWebplayer ? "extended_" : ""}miniplayer_next_disabled`}>
          <img src={getAbsolutePath(iconNext)} alt="" />
        </div>
      );
    } else {
      return (
        <div className="iconPrev" onClick={() => this.changeSong(1)} data-cy={`${isWebplayer ? "extended_" : ""}miniplayer_next`}>
          <img src={getAbsolutePath(iconNext)} alt="" />
        </div>
      );
    }
  };

  playSong = (newIndex) => {
    const { miniPlayer, updateMiniPlayer, updateShowModalWebPlayer } = this.props;

    const isPodcastStream = miniPlayer?.pageOrigin === PAGE_ORIGIN_PODCASTS;

    if (isPodcastStream || isAllowedToPlay()) {
      updateMiniPlayer({
        playing: true,
        indexOfTrack: newIndex,
      });
    } else {
      updateShowModalWebPlayer(true);
      updateMiniPlayer({
        playing: false,
      });
    }
  };

  playPauseSong = () => {
    const { updateMiniPlayer, miniPlayer } = this.props;
    const { isFetching } = miniPlayer;
    if (isFetching) return; // disable control until song is loading
    updateMiniPlayer({ isFetching: false });
    const isCurrentlyPlaying = miniPlayer.playing;
    if (isCurrentlyPlaying) {
      this.startPauseTimer();
    } else {
      this.stopPauseTimer();
    }
    updateMiniPlayer({ playing: !isCurrentlyPlaying });

    window.dataLayer.push({
      event: "playerInteraction",
      interaction: miniPlayer.playing ? "pause" : "play",
    });
    Mixpanel.track("Player Interaction", {
      Action: miniPlayer.playing ? "Pause" : "Play",
    });
  };

  stopExtending = (event) => {
    if (isEmpty(event.target.attributes[0])) return;

    const forcePropagationItems = ["user-playlist"];
    let forcePropagation = false;

    event.target.attributes.forEach((attr) => {
      if (!forcePropagation && attr.name === "data-cy" && forcePropagationItems.includes(attr.nodeValue)) {
        forcePropagation = true;
      }
    });
    if (!forcePropagation) event.stopPropagation();
  };

  render() {
    const { isWebplayer, miniPlayer, collapsed, isLogged, collapsePlayer, t, lang } = this.props;
    const { playing, indexOfTrack, dataTrack, jazzMode, pageOrigin, isFetching: fetchingSong } = miniPlayer;

    if (isEmpty(dataTrack)) return null;
    const countryAllowed = isCountryAllowed(dataTrack[indexOfTrack]);

    const isPodcast = miniPlayer.pageOrigin === PAGE_ORIGIN_PODCASTS;

    const jazzFlag = jazzMode;

    const hiddenClass = miniPlayer.url === "empty" ? "hidden" : "";
    const podcastId = dataTrack && dataTrack[indexOfTrack].podcast_id;
    const chapterId = dataTrack && dataTrack[indexOfTrack].id;

    const currentTrackId = getTrackIdFromTrack(dataTrack[indexOfTrack]);
    const work = getWorkFromTrack(dataTrack[indexOfTrack]);
    const recYear = getRecordYear(dataTrack[indexOfTrack]);

    const displayArtist = getDisplayArtistFromTrack(dataTrack[indexOfTrack]);

    const album = getAlbumFromTrack(dataTrack[indexOfTrack]);

    const useMobileControlScheme = window.matchMedia("(max-width: 658px)").matches;

    return (
      useMobileControlScheme && (
        <div
          className={`mini-player ${!isWebplayer ? "collapse" : ""} ${hiddenClass} ${useMobileControlScheme ? (!collapsed ? "expanded" : "") : ""}`}
          tabIndex="0"
        >
          <div name="player" className="mini-player__controls-right" onClick={this.stopExtending}>
            <div className="box-icons" name="player">
              {isLogged && !isPodcast && countryAllowed && <SettingsCog className="stream-settings mobile controls" isMiniPlayer={true} />}
              {this.getIconPrev()}
              <div
                className={`iconPausePlay ${fetchingSong ? "loading" : ""}`}
                data-cy={`${isWebplayer ? "extended_" : ""}miniplayer_playpause`}
                onClick={this.playPauseSong}
              >
                {playing ? <img src={getAbsolutePath(iconPause)} alt="" /> : <img src={getAbsolutePath(iconPlay)} alt="" />}
              </div>
              {this.getIconNext()}
              <div>
                {pageOrigin === PAGE_ORIGIN_PODCASTS ? (
                  <MenuMore
                    hideAddToPlaylist={true}
                    urlOptions={[podcastOption(podcastId, jazzMode, lang, t), chapterOption(chapterId, jazzMode, lang, t)]}
                    isMiniPlayer={true}
                    isWebplayer={isWebplayer}
                  />
                ) : (
                  isLogged && (
                    <MenuMore
                      runAfterRedirect={collapsePlayer}
                      listSongs={[currentTrackId]}
                      isJazz={jazzFlag}
                      urlOptions={
                        jazzFlag
                          ? album && [jazzAlbumOption(album, lang, t), artistOption(displayArtist, lang, t)]
                          : [
                              composerOption(displayArtist, lang, t),
                              classicalAlbumOption(album, lang, t),
                              workOption(work, lang, t),
                              recordingOption(displayArtist, work, recYear, lang, t),
                            ]
                      }
                      track={dataTrack[indexOfTrack]}
                      playSong={this.playSong}
                      isMiniPlayer={true}
                      isWebplayer={isWebplayer}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  miniPlayer: getMiniPlayerSelector(state),
  isLogged: getLoginSelector(state),
  lang: getLangSelector(state),
  isFlacStream: getFlacStreamSelector(state),
  userLikedTracks: getUserLikedTracksSelector(state),
  isJazzSelected: getIsJazzSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateMiniPlayer: (data) => {
    dispatch(dispatchUpdateMiniPlayer(data));
  },
  updateShowModalWebPlayer: (showModal) => {
    dispatch(dispatchUpdateModalWebPlayer(showModal));
  },
  setShowClassicModal: (showClassicModal) => {
    dispatch(dispatchUpdateShowClassicModal(showClassicModal));
  },
  setUserLikedTracks: (playlists) => {
    dispatch(dispatchSetUserLikedTracks(playlists));
  },
});

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileExpandedControls)));
