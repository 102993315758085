import React from "react";
import { Store } from "react-notifications-component";
import heart_svg from "assets/images/heart_clicked.svg";

const NOTIFICATION_TYPES = {
  none: { icon: "" },
  heart: { icon: heart_svg },
  fail: { icon: "" },
};

const notificationManager = {
  // documentation: https://www.npmjs.com/package/react-notifications-component
  /**
   * Shows the toast notification
   * @param {string} type - The type that determines the style and icons. 'none' | 'heart' | 'fail'
   * @param {string} content - string or jsx content.
   */
  showMessage: (type, content) => {
    Store.addNotification({
      container: "bottom-left",
      dismiss: {
        duration: 5000,
        pauseOnHover: true,
      },
      content: <VialmaToast type={type} content={content} />,
      // width property is overridden in CSS under .notification-parent to fit-content
    });
  },
};

const VialmaToast = ({ type = "none", content }) => {
  return (
    <div className="vialma-toast">
      <img src={NOTIFICATION_TYPES[type].icon} className="vialma-toast__icon" alt=""></img>
      <div className="vialma-toast__text">{content}</div>
      <div className="vialma-toast__X">&#10006;</div>
    </div>
  );
};

export default notificationManager;
