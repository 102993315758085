import React, { lazy, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import customStyles from "utils/customStyleModal";
import logoFooter from "assets/images/vialma.svg";
import logoFooterJazz from "assets/images/vialma_blue.svg";
import { getIsJazzSelector, getLangSelector, getLoginSelector } from "modules/Config/selectors";
import { accessRoutes } from "components/ApplicationRoutes/routes";
import Divider from "components/Divider";
import { getMiniPlayerSelector } from "modules/MiniPlayer/selectors";
import { Fragment } from "react";
import fbIcon from "assets/images/fb.svg";
import linkedInIcon from "assets/images/linkedin.svg";
import instaIcon from "assets/images/insta.svg";
const Terms = lazy(() => import("components/Terms"));
const Privacy = lazy(() => import("components/Privacy"));

const Footer = ({ t, jazzMode, lang, isLogged, miniPlayer, isJazzSelected }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenPrivacy, setModalIsOpenPrivacy] = useState(false);
  const [footerSpace, setFooterSpace] = useState(0);
  const lngRoutes = accessRoutes[lang];
  const isJazz = jazzMode || isJazzSelected;

  useEffect(() => {
    const getFooterSpace = () => {
      const playerContainer = document.getElementById("playerContainer");
      const playerHeight = playerContainer?.clientHeight !== 0 ? playerContainer?.clientHeight + 10 : 10;
      setFooterSpace(playerHeight);
    };

    getFooterSpace();
  }, [miniPlayer, isJazzSelected]);

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const openModalPrivacy = () => {
    setModalIsOpenPrivacy(true);
  };
  const closeModalPrivacy = () => {
    setModalIsOpenPrivacy(false);
  };
  const openPrivacy = () => {
    closeModal();
    openModalPrivacy();
  };

  return (
    <div className="footer">
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} ariaHideApp={false}>
        <Terms closeModal={closeModal} openPrivacy={openPrivacy} />
      </Modal>
      <Modal isOpen={modalIsOpenPrivacy} onRequestClose={closeModalPrivacy} style={customStyles} ariaHideApp={false}>
        <Privacy closeModal={closeModalPrivacy} />
      </Modal>
      <div className="content">
        <div className="content__left">
          <img className="logo-foot" src={isJazz ? logoFooterJazz : logoFooter} alt="" />
          <div className="menu-foot">
            <span className="no-link text-left">{t("RC_FOOTER_4")}</span>
          </div>
          <div>
            <p className="no-link text-left text-bold mt-20 mb-10">{t("SOCIAL_MEDIA_COPY")}</p>
            <div className="flex text-left">
              <a href={t("SOCIAL_MEDIA_INSTAGRAM_URL")} className="mr-10 ml-0" target="_blank" rel="noopener noreferrer">
                <img src={instaIcon} alt="instagram link" />
              </a>
              <a href={t("SOCIAL_MEDIA_FACEBOOK_URL")} className="mr-10 ml-0" target="_blank" rel="noopener noreferrer">
                <img src={fbIcon} alt="facebook link" />
              </a>
              <a href={t("SOCIAL_MEDIA_LINKEDIN_URL")} className="mr-10 ml-0" target="_blank" rel="noopener noreferrer">
                <img src={linkedInIcon} alt="linkedin link" />
              </a>
            </div>
          </div>
        </div>

        <div className="content__right">
          <div className={`menu-foot ${isJazz ? "jazz" : ""}`}>
            <a href={t("PARTNERS_LINK_URL", { utmMedium: "footer" })} rel="noopener noreferrer" target="_blank">
              {t("PARTNERS_FOOTER_LINK")}
            </a>
            <a href={t("CRISP_HELPDESK_URL")} rel="noopener noreferrer" target="_blank">
              {t("FOOTER_HELPDESK_LINK")}
            </a>
            <Link to={isJazz ? lngRoutes.jazzWhoAreWe : lngRoutes.whoAreWe}>{t("RC_FOOTER_1")}</Link>

            <Link to={lngRoutes.giftcardPurchase}>{t("RC_FOOTER_GIFT_CARD")}</Link>
            {isJazz ? (
              <Fragment>
                <Link to={lngRoutes.jazzPlaylists}>{t("RC_FOOTER_JAZZ_PLAYLISTS")}</Link>
                <Link to={lngRoutes.jazzArtists}>{t("RC_FOOTER_JAZZ_ARTISTS")}</Link>
                <Link to={lngRoutes.jazzAlbums}>{t("RC_FOOTER_JAZZ_ALBUMS")}</Link>
                <Link to={lngRoutes.jazzArticles}>{t("RC_FOOTER_JAZZ_ARTICLES")}</Link>
                <Link to={lngRoutes.jazzVideos}>{t("RC_FOOTER_JAZZ_VIDEOS")}</Link>
                <Link to={lngRoutes.jazzCollections}>{t("RC_FOOTER_JAZZ_COLLECTIONS")}</Link>
                <Link to={lngRoutes.podcastsJazz}>{t("RC_FOOTER_JAZZ_PODCASTS")}</Link>
              </Fragment>
            ) : (
              <Fragment>
                <Link to={lngRoutes.playlists}>{t("RC_FOOTER_CLASSICAL_PLAYLISTS")}</Link>
                <Link to={lngRoutes.composers}>{t("RC_FOOTER_CLASSICAL_COMPOSERS")}</Link>
                <Link to={lngRoutes.videos}>{t("RC_FOOTER_CLASSICAL_VIDEOS")}</Link>
                <Link to={lngRoutes.collections}>{t("RC_FOOTER_COLLECTIONS")}</Link>
                <Link to={lngRoutes.articles}>{t("RC_FOOTER_CLASSICAL_ARTICLES")}</Link>
                <Link to={lngRoutes.podcasts}>{t("RC_FOOTER_PODCASTS")}</Link>
              </Fragment>
            )}
          </div>
          <div className={`menu-foot-lower ${isJazz ? "jazz" : ""}`}>
            <Link className="mr-25" to={`${isJazz ? lngRoutes.siteMapJazz : lngRoutes.siteMap}`}>
              {t("RC_FOOTER_SITEMAP")}
            </Link>
            <span onClick={openModal}>{t("RC_FOOTER_2")}</span>
            <span className="menu-foot-pipe">&nbsp;|&nbsp;</span>
            <span onClick={openModalPrivacy}>{t("RC_FOOTER_3")}</span>
          </div>
        </div>
      </div>
      <Divider height={footerSpace} showBorder={false} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: getLangSelector(state),
  isLogged: getLoginSelector(state),
  miniPlayer: getMiniPlayerSelector(state),
  isJazzSelected: getIsJazzSelector(state),
});

export default withTranslation()(connect(mapStateToProps)(Footer));
