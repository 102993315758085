import { store } from "App.js";
import has from "lodash/has";

let axiosCallCounterCountdown = 4;


const getAxiosCalls = () => {
  const storeState = store.getState();
  if (has(storeState, "config.axiosLiveCalls")) {
    return storeState.config.axiosLiveCalls;
  }
};

// Rendertron is hooking up to this event to determine when a page is loaded. 
const prerenderLoadEvent = () => {
  const axiosCallsInProgress = getAxiosCalls();

  if (axiosCallsInProgress && axiosCallsInProgress !== 0) {
    axiosCallCounterCountdown = 4;
    setTimeout(() => {
      prerenderLoadEvent();
    }, 500);
  } else {
    if (axiosCallCounterCountdown === 0) {
      console.log("Page load completed")
      const load_event = new Event("prerender_page_loaded");
      window.dispatchEvent(load_event);
    } else {
      axiosCallCounterCountdown--;
      setTimeout(() => {
        prerenderLoadEvent();
      }, 500);
    }
  }
}


export default prerenderLoadEvent;