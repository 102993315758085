import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import LoadingBubble from "components/LoadingBubble";
import { withRouter } from "react-router";
import MiniPlayer from "components/MiniPlayer";
import { getMiniPlayerSelector, getCollapsedSelector, getexpandedPlayerCollapsedSelector } from "modules/MiniPlayer/selectors";
import { dispatchUpdateExpandedPlayerCollapsed, dispatchUpdateModalWebPlayer } from "modules/MiniPlayer/actions";
import { dispatchUpdateMiniPlayer } from "modules/MiniPlayer/actions";
import { getLangSelector, getLoginSelector } from "modules/Config/selectors";
import {
  getJazzPerformersFromTrack,
  getClassicalPerformersFromTrack,
  getTitleFromTrack,
  getWorkFromTrack,
  getTrackIdFromTrack,
  getRecordYear,
  getJazzAlbumImageFromTrack,
  getUniverseFromTrack,
  getLabelFromTrack,
  getDisplayArtistFromTrack,
} from "utils/metaDataTools";
import iconHide from "assets/images/ic_hideplayer_white.svg";
import iconHideWhite from "assets/images/collapse_white.svg";
import iconClose from "assets/images/close_search.svg";
import getAbsolutePath from "utils/getAbsolutePath";
import { Link } from "react-router-dom";
import defaultDisplayImage from "assets/images/default_person_avatar.svg";
import { expandedPlayerAddToPlayistTracking, playerExtendedTracking, playerGoToPlayingFromTracking } from "utils/miniPlayerMixpanelUtils";
import EnhancedImage from "components/EnhancedImage";
import getSizedImagePath from "utils/getSizedImagePath";
import defaultPlaylistImage from "assets/images/notes_grey_bg.svg";
import TracksHeart from "components/TracksHeart";
import AddToPlaylist from "components/MenuMore/AddToPlaylist";
import { encodeWithQuote, toggleCrispChatVisibility } from "utils/tools";
import axios from "axios";
import notificationManager from "utils/notificationManager";
import { Mixpanel } from "utils/mixpanel";
import { apiLibraryGetUserPlaylists } from "utils/apiLibrary/library";
import ExpandedPlayerQueue from "./ExpandedPlayerQueue";
import SettingsCog from "components/SettingsCog";
import { useMediaQuery } from "react-responsive";
import { getArtistUrl, parseMediaProps } from "utils/miniplayerUtils";
import TrackInfoDesktop from "./TrackInfoDesktop";
import usePrevious from "utils/hooks/usePrevious";
import { useEffect } from "react";
import MobileExpanded from "components/MiniPlayer/MobileExpandedControls";
import { PAGE_ORIGIN_PODCASTS } from "utils/nameVariables";

const ExpandedPlayer = (props) => {
  const { t, miniPlayer, lang, expandedPlayerCollapsed: collapsed, setExpandedPlayerCollapsed: setCollapsed } = props;
  const [showQueue, setShowQueue] = useState(false);
  const [error, setError] = useState("");
  const prevError = usePrevious(error);

  useEffect(() => {
    if (error && error !== prevError) {
      notificationManager.showMessage("none", error);
      setError("");
    }
  }, [error, prevError]);

  const handlePlayerError = (errorText) => {
    setError(errorText);
  };

  const isMobile = useMediaQuery({ maxWidth: 658 });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 880px)" });
  const isLandscape = useMediaQuery({ query: "(orientation: landscape)" });

  const { dataTrack, indexOfTrack, pageOrigin, mediaProps, jazzMode } = miniPlayer;
  const isPodcast = pageOrigin === PAGE_ORIGIN_PODCASTS;

  const collapsePlayer = () => {
    toggleCrispChatVisibility(true);
    setCollapsed(true);
  };
  const expandPlayer = () => {
    toggleCrispChatVisibility(false);
    playerExtendedTracking();
    setCollapsed(false);
  };

  const createPlaylist = (listSongs, textNewPlaylist) => {
    if (!textNewPlaylist.trim()) return;
    const urlCreatePlaylist = `${process.env.REACT_APP_VIALMA_API}/playlists/edit`;
    const encodedNewPlaylistName = encodeWithQuote(textNewPlaylist);
    const payload = {
      title: encodedNewPlaylistName,
      description: "",
      enabled: true,
      type: "customer",
    };
    const tokenHash = JSON.parse(localStorage.getItem("loginData")).token;
    const token = `Bearer ${tokenHash}`;
    axios
      .post(urlCreatePlaylist, payload, {
        headers: {
          Authorization: token,
        },
      })
      .then(() => {
        apiLibraryGetUserPlaylists().then((playlists) => {
          const addedPlaylist = playlists[playlists.length - 1];
          const addedPlaylistId = addedPlaylist.id;
          addToPlaylist(addedPlaylistId, listSongs);
        });
      })
      .catch(() => {
        notificationManager.showMessage("none", t("NOTIFICATION_PLAYLIST_CREATION_FAILURE"));
      });
  };

  const toggleShowQueue = () => {
    setShowQueue(!showQueue);
  };

  const addToPlaylist = (id, listSongs) => {
    const [songId] = listSongs;
    const urlAddToPlayList = `${process.env.REACT_APP_VIALMA_API}/playlists/${id}/add/song`;
    const payload = { songs: listSongs };
    const tokenHash = JSON.parse(localStorage.getItem("loginData")).token;
    const token = `Bearer ${tokenHash}`;
    axios
      .post(urlAddToPlayList, payload, {
        headers: {
          Authorization: token,
        },
      })
      .then(() => {
        Mixpanel.track("Saved Track to Playlist", {
          TrackID: songId,
          Universe: window.location.href.indexOf("/jazz") !== -1 ? "Jazz" : "Classical",
        });
        expandedPlayerAddToPlayistTracking();
        notificationManager.showMessage("none", t("NOTIFICATION_ADDED_TO_PLAYLIST"));
      })
      .catch(() => {
        notificationManager.showMessage("none", t("NOTIFICATION_ADD_TO_PLAYLIST_FAILURE"));
      });
  };

  const url = window.location.href;
  const { imageRootUrl: displayAlbumRootUrl, imagePath: displayAlbumPath } = getJazzAlbumImageFromTrack(dataTrack?.[indexOfTrack]);

  const imgSrc = useMemo(
    () => getSizedImagePath("medium", displayAlbumRootUrl, displayAlbumPath + "?" + new Date().getTime(), defaultPlaylistImage),
    [displayAlbumRootUrl, displayAlbumPath]
  );

  const WEBPLAYER_IMAGE_ID = "webplayer_image_id";
  if (miniPlayer.url === "empty" && url.indexOf("formregister") === -1) {
    return <MiniPlayer />;
  }
  if (!dataTrack && miniPlayer.url !== "empty" && url.indexOf("formregister") === -1) {
    return (
      <div className="content-loading">
        <LoadingBubble color={"#DD4E52"} />
      </div>
    );
  }
  if (dataTrack && url.indexOf("formregister") === -1) {
    const trackUniverse = getUniverseFromTrack(dataTrack[indexOfTrack]);
    const isJazzTrack = trackUniverse === "jazz";
    const recordingYear = getRecordYear(dataTrack[indexOfTrack]);
    const performers = isJazzTrack
      ? getJazzPerformersFromTrack(dataTrack[indexOfTrack], lang)
      : getClassicalPerformersFromTrack(dataTrack[indexOfTrack], lang);
    const displayArtist = getDisplayArtistFromTrack(dataTrack[indexOfTrack]);
    const displayName = displayArtist && displayArtist.name;
    const displayImage = isPodcast ? (
      <img src={dataTrack[indexOfTrack].image_url || defaultDisplayImage} size="small" alt={displayName} />
    ) : (
      <EnhancedImage
        id={WEBPLAYER_IMAGE_ID}
        size="medium"
        imageRootUrl={displayAlbumRootUrl}
        imagePath={displayAlbumPath}
        fallbackImage={defaultDisplayImage}
        alt={displayName}
      />
    );

    const title = isPodcast ? dataTrack[indexOfTrack].name : getTitleFromTrack(dataTrack[indexOfTrack]);
    const recordLabel = getLabelFromTrack(dataTrack[indexOfTrack]);
    const work = getWorkFromTrack(dataTrack[indexOfTrack]);

    const { hasMediaProps, playingFromText, playingFromUrl } = parseMediaProps(mediaProps);
    const displayArtistUrl = getArtistUrl(displayArtist, lang, jazzMode);

    const handlePlayingFromClick = () => {
      playerGoToPlayingFromTracking();
      collapsePlayer();
    };
    return (
      <React.Fragment>
        <div className={`container container-webplayer`}>
          <div className={collapsed ? "info-webplayer collapsed" : "info-webplayer"} data-cy="extended_miniplayer_container">
            <div className=" content-webplayer">
              {isMobile && (
                <div className="icon-close-modal my-10 flex justify-flex-end align-items-center" onClick={collapsePlayer}>
                  <span>{t("MOBILE_WEBPLAYER_CLOSE")}</span>
                  <img src={getAbsolutePath(iconClose)} alt="" />
                </div>
              )}
              <div className="top">
                <div
                  className="overlay"
                  style={{
                    backgroundImage: `url(${imgSrc})`,
                  }}
                ></div>
                <div className="content">
                  {!showQueue ? (
                    <div className="track-info-container">
                      <div className="track-image mr-20 bordered rounded shadow overflow-hidden h-max-content">{displayImage}</div>
                      <TrackInfoDesktop
                        isTabletOrMobile={isTabletOrMobile}
                        isLandscape={isLandscape}
                        work={work}
                        displayArtist={displayArtist}
                        isJazzTrack={isJazzTrack}
                        displayArtistUrl={displayArtistUrl}
                        displayName={displayName}
                        performers={performers}
                        recordLabel={recordLabel}
                        recordingYear={recordingYear}
                        hasMediaProps={hasMediaProps}
                        playingFromUrl={playingFromUrl}
                        handlePlayingFromClick={handlePlayingFromClick}
                        playingFromText={playingFromText}
                        collapsePlayer={collapsePlayer}
                        title={title}
                      />
                    </div>
                  ) : (
                    <div className="track-info-container">
                      <ExpandedPlayerQueue miniPlayer={miniPlayer} dataTrack={dataTrack} collapsePlayer={collapsePlayer} />
                    </div>
                  )}
                  <div className="right-buttons-container pb-25">
                    <div className="iconHide" onClick={collapsePlayer}>
                      <img className="iconHide-desktop" src={getAbsolutePath(iconHide)} alt="" data-cy="extended_miniplayer_collapse" />
                      <img className="iconHide-mobile" src={getAbsolutePath(iconHideWhite)} alt="" />
                    </div>
                    <div className="button-white-outlined slim w-max-content" onClick={toggleShowQueue}>
                      {t(showQueue ? "WEBPLAYER_SHOW_TRACK_DETAILS" : "WEBPLAYER_SHOW_QUEUE")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bottom">
                <div className="content">
                  <div className="title-section">
                    <div className="left">
                      <div className={`track-title playfair truncate tiny-landscape mb-15`}>{title}</div>
                      <div className="track-artist" onClick={collapsePlayer}>
                        <span className="bold">
                          <Link to={displayArtistUrl} onClick={collapsePlayer}>
                            {displayName}
                          </Link>
                        </span>
                      </div>
                    </div>
                    <div className="right flex flex-column justify-flex-end">
                      <div className="flex-row flex">
                        <span className="mr-10 has-link red">
                          {!isPodcast && (
                            <TracksHeart
                              isIcon
                              iconCopy={isMobile ? null : t("LIKE_THIS_PIECE")}
                              size="small"
                              track={dataTrack[indexOfTrack]}
                              source="expanded miniplayer"
                            />
                          )}
                        </span>
                        <span className="has-link flex flex-row align-items-center hide-sm">
                          {!isPodcast && (
                            <AddToPlaylist
                              listSongs={[getTrackIdFromTrack(dataTrack[indexOfTrack])]}
                              addToPlaylist={addToPlaylist}
                              createPlaylist={createPlaylist}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <MobileExpanded
                    expandPlayer={expandPlayer}
                    collapsePlayer={collapsePlayer}
                    collapsed={collapsed}
                    tracks={dataTrack}
                    isWebplayer={true}
                    handlePlayerError={handlePlayerError}
                  />
                  {isMobile && (
                    <div className="bottom-panel">
                      <span className="p-15">
                        <SettingsCog />
                      </span>
                      <span>
                        <AddToPlaylist
                          listSongs={[getTrackIdFromTrack(dataTrack[indexOfTrack])]}
                          addToPlaylist={addToPlaylist}
                          createPlaylist={createPlaylist}
                        />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <MiniPlayer
            handlePlayerError={handlePlayerError}
            expandPlayer={expandPlayer}
            collapsePlayer={collapsePlayer}
            collapsed={collapsed}
            tracks={dataTrack}
          />
        </div>
      </React.Fragment>
    );
  }
  return null;
};

const mapStateToProps = (state) => ({
  miniPlayer: getMiniPlayerSelector(state),
  lang: getLangSelector(state),
  collapsed: getCollapsedSelector(state),
  isLogged: getLoginSelector(state),
  expandedPlayerCollapsed: getexpandedPlayerCollapsedSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateMiniPlayer: (data) => {
    dispatch(dispatchUpdateMiniPlayer(data));
  },
  setExpandedPlayerCollapsed: (data) => {
    dispatch(dispatchUpdateExpandedPlayerCollapsed(data));
  },
  updateShowModalWebPlayer: (showModal) => {
    dispatch(dispatchUpdateModalWebPlayer(showModal));
  },
});

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ExpandedPlayer)));
