import React from "react";
import ErrorPage from 'pages/ErrorPage';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
    this.error = '';
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage error={this.error}/>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
