import {
  UPDATE_MINI_PLAYER,
  SET_IS_WEBPLAYER,
  SET_SLUG_WEBPLAYER,
  UPDATE_MODAL,
  SET_EXPANDED_PLAYER_OPEN_STATE
} from "./constants";

export const dispatchUpdateMiniPlayer = data => ({
  type: UPDATE_MINI_PLAYER,
  data
});

export const dispatchSetIsWebplayer = isWebPlayer => ({
  type: SET_IS_WEBPLAYER,
  isWebPlayer
});

export const dispatchSetSlugWebPlayer = slug => ({
  type: SET_SLUG_WEBPLAYER,
  slug
});

export const dispatchUpdateModalWebPlayer = showModal => ({
  type: UPDATE_MODAL,
  showModal
});

export const dispatchUpdateExpandedPlayerCollapsed = expandedPlayerCollapsed => ({
  type: SET_EXPANDED_PLAYER_OPEN_STATE,
  expandedPlayerCollapsed
});
