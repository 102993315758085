import produce from "immer";
import {
  SET_FEATURED_ARTISTS,
  SET_ARTISTS,
  SET_FILTERED_ARTISTS,
  SET_SEARCH_ARTISTS,
  CLEAR_ARTISTS,
  SET_ARTISTS_DEFAULTS,
  SET_SHOULD_LOAD_ARTISTS,
} from "./constants";

const initialState = {
  featuredArtists: null,
  artists: [],
  filteredArtists: null,
  search: "",
  shouldLoadMore: true,
};

const artistsReducer = function(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SET_FEATURED_ARTISTS:
        draft.featuredArtists = action.featuredArtists;
        break;
      case SET_ARTISTS:
        draft.artists.push(...action.artists);
        break;
      case SET_FILTERED_ARTISTS:
        draft.filteredArtists = action.filteredArtists;
        break;
      case SET_SEARCH_ARTISTS:
        draft.search = action.search;
        break;
      case CLEAR_ARTISTS:
        draft.artists = [];
        break;
      case SET_ARTISTS_DEFAULTS:
        return initialState;
      case SET_SHOULD_LOAD_ARTISTS: 
        draft.shouldLoadMore = action.shouldLoad;
        break;
      default:
        return;
    }
  });
}

export default artistsReducer;