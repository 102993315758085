const getCustomerGroupsAsString = (array) => {
  let customerGroupNames = [];
  let customerGroupsString;
  try {
    array?.forEach((group) => {
      customerGroupNames.push(group?.title);
    });
    if (customerGroupNames?.length) {
      customerGroupsString = customerGroupNames.join(", ");
    } else {
      customerGroupsString = "No customer group";
    }
  } catch (error) {
    customerGroupsString = "Error parsing customer group";
  }

  return customerGroupsString;
};

const pendoInit = () => {
  //only difference is initiatize funtciton name

  // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
  // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
  // Call this function in your authentication promise handler or callback when your visitor and account id values are available

  // Please use Strings, Numbers, or Bools for value types.
  window?.pendo?.initialize({
    visitor: {
      id: "VISITOR-UNIQUE-ID", // Required if user is logged in, default creates anonymous ID
    },

    account: {
      id: "ACCOUNT-UNIQUE-ID", // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
    },
  });
};

const pendoIdentify = (userData) => {
  const { email, userId, username, customerGroups, subscription_ensemble } = userData || {};
  const { name: subscriptionName, productId, unit } = subscription_ensemble?.subscription || {};
  window?.pendo?.identify({
    visitor: {
      id: userId || "VISITOR-UNIQUE-ID",
      email: email,
      full_name: username,
      customer_groups: getCustomerGroupsAsString(customerGroups),
    },

    account: {
      id: productId || "ACCOUNT-UNIQUE-ID",
      name: subscriptionName,
      period: unit,
    },
  });
};
export const pendoInitHandler = (userData) => {
  if (userData) {
    pendoIdentify(userData);
  } else {
    pendoInit();
  }
};
