import produce from "immer";
import {
  SET_FAVOURITE_PERSONS,
} from "./constants";

const initialState = {
  favouritePersons: null,
};

const favouritesReducer = function (state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_FAVOURITE_PERSONS:
        draft.favouritePersons = action.favouritePersons;
        break;
      default:
        return;
    }
  });
}

export default favouritesReducer;