import React from "react";
import { Mixpanel } from "utils/mixpanel";
import MediaCardComponent from "components/MediaCardComponent";

const RecommendBoxContainer = (props) => {
  const { labelColor, copy, isLogged, allow, openModal, content, isJazz, url, type,  } = props;

  const trackingFunction = () => {
    Mixpanel.track("Clicked recommended content", { contentType: "various" });
    if (!(isLogged || allow)) {
      openModal(url);
    }
  };

  return (
    <MediaCardComponent
      playlist={content}
      media={content}
      isJazz={isJazz}
      type={type}
      labelProps={{ colour: labelColor, text: copy }}
      handleClick={trackingFunction}
      showDescription={false}
    />
  );
};

export default RecommendBoxContainer;
