import React from "react";
import { store } from "App.js";
import isEmpty from "lodash/isEmpty";
import { isSubBothUniverse, isSubClassical, isSubClassicalOnly, isSubJazz, isSubJazzOnly } from "./getUniverse";
import { isLoggedIn, fetchLang, getSubscriptionFromRedux } from "./storeUtils";
import { dispatchShowSignupFreeTrialModal, dispatchUpdateShowClassicModal } from "modules/Config/actions";
import { accessRoutes } from "components/ApplicationRoutes/routes";
import notificationManager from "./notificationManager";
import i18n from "i18n";
import { isCountryAllowed } from "./metaDataTools";
import { IS_HEADER_JAZZ } from "./localStoreKeys";

const IGNORE_URLS = () => {
  const lang = fetchLang();
  const urls = [
    accessRoutes[lang].classicalHome,
    accessRoutes[lang].jazzHome,
    accessRoutes[lang].account,
    accessRoutes[lang].library,
    accessRoutes[lang].libraryPlaylistDetails,
    accessRoutes[lang].upgrade,
    accessRoutes[lang].congratulations,
    accessRoutes[lang].jazzCongratulations,
  ];

  return urls;
};

const ACCESS_RESPONSE_TYPE = {
  PAGE_NOT_ALLOWED: "PAGE_NOT_ALLOWED",
  EXPIRED_USER: "EXPIRED_USER",
  ALLOWED: "ALLOWED",
};

/**
 * Checks whether user is allowed to see a page or perform a song play
 * @param {string} path - The url requested to be seen
 * @param {boolean} isPageCheck - true if the access is for a page, false if it's for a song. Default: true
 */

const checkIfSubscriptionHasExpired = (subscription) => {
  
  if (subscription?.subscription?.expired) return true;

  let endDate = subscription?.endDate?.date;
  if (endDate) {
    endDate = new Date(endDate);
    try {
      const now = new Date();
      return endDate.getTime() < now.getTime();
    } catch (error) {
      return false;
    }
  }
  return false;
};

const checkHasAccess = (path, isPageCheck = true) => {
  let subscription = getSubscriptionFromRedux();
  if (!subscription || isEmpty(subscription)) {
    const loginData = JSON.parse(localStorage.getItem("loginData"));
    subscription = loginData && loginData.subscription_ensemble;
  }
  if (!subscription || isEmpty(subscription) || !subscription.subscription || isEmpty(subscription.subscription))
    return ACCESS_RESPONSE_TYPE.PAGE_NOT_ALLOWED; // could not get subscription data from anywhere. Probably a logged out user!
  const isBothClassicalAndJazz = isSubBothUniverse(subscription);
  const isJazzPage = path.indexOf("/jazz") !== -1 || localStorage.getItem(IS_HEADER_JAZZ) === "true";
;
  // access check for playing music
  if (!isPageCheck && checkIfSubscriptionHasExpired(subscription)) {
    return ACCESS_RESPONSE_TYPE.EXPIRED_USER;
  }

  // access check for seeing a particular page/path
  if (isPageCheck && (IGNORE_URLS().some((url) => path.indexOf(url) !== -1) || isBothClassicalAndJazz)) {
    return ACCESS_RESPONSE_TYPE.ALLOWED;
  }

  if (isJazzPage && isSubJazz(subscription)) return ACCESS_RESPONSE_TYPE.ALLOWED;
  if (isJazzPage && isSubClassicalOnly(subscription)) return ACCESS_RESPONSE_TYPE.PAGE_NOT_ALLOWED;
  if (!isJazzPage && isSubClassical(subscription)) return ACCESS_RESPONSE_TYPE.ALLOWED;
  if (!isJazzPage && isSubJazzOnly(subscription)) return ACCESS_RESPONSE_TYPE.PAGE_NOT_ALLOWED;
};

const isCountryAllowedClick = (track) => {
  let resp = isCountryAllowed(track);
  if (!resp && isLoggedIn()) {
    notificationManager.showMessage("fail", i18n.t("COUNTRY_NOT_ALLOWED"));
    // temporary measure untitl update to react-notifications-component for replacing existing notifications is made
    notificationManager.showMessage("none", i18n.t("ATTEMPTING_NEXT_AVAILABLE_TRACK"));
  }
  return resp;
};

const isAllowedToPlay = (track, utmSource) => {
  if (track) {
    const countryAllowed = isCountryAllowedClick(track);
    if (!countryAllowed && isLoggedIn()) return countryAllowed;
  }

  const lang = fetchLang();
  const utmSourceExceptions = ["OperaDeParis", "ADLyon", "Champs-elysees"];

  const pathName = window.location.pathname;
  const accessType = checkHasAccess(pathName, false);

  if (utmSource) {
    if (isLoggedIn()) {
      return true;
    } else {
      if (utmSourceExceptions.includes(utmSource)) {
        return true;
      } else {
        store.dispatch(dispatchShowSignupFreeTrialModal(true));
      }
    }
  }

  const notAllowedNotification = () => {
    return notificationManager.showMessage(
      "fail",
      <span>
        {i18n.t("ACTION_NOT_ALLOWED")}&nbsp;
        <a href={accessRoutes[lang].upgrade}>{i18n.t("UPGRADE_TITLE")}</a>
      </span>
    );
  };

  if (accessType === ACCESS_RESPONSE_TYPE.PAGE_NOT_ALLOWED) {
    if (!isLoggedIn()) {
      store.dispatch(dispatchShowSignupFreeTrialModal(true));
    } else {
      store.dispatch(dispatchUpdateShowClassicModal(true));
    }
    notAllowedNotification();
    return false;
  } else if (accessType === ACCESS_RESPONSE_TYPE.EXPIRED_USER) {
    window.location.href = accessRoutes[lang].upgrade;
    notAllowedNotification();
    return false;
  } else if (accessType === ACCESS_RESPONSE_TYPE.ALLOWED) {
    return true;
  }
  notAllowedNotification();
  return false;
};

export { checkHasAccess, ACCESS_RESPONSE_TYPE, isAllowedToPlay, isCountryAllowedClick };
