import React, { useState } from "react";
import iconMenuPlaylist from "assets/images/icon_menu_playlist.svg";
import closeSearch from "assets/images/close_search.svg";
import iconEnter from "assets/images/icon_enter.svg";
import iconClose from "assets/images/close_search.svg";
import getAbsolutePath from "utils/getAbsolutePath";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getLoginSelector } from "modules/Config/selectors";
import { getPlaylistsSelector } from "modules/Library/selectors";
import { dispatchSetPlaylists } from "modules/Library/actions";
import EnhancedImage from "components/EnhancedImage";
import defaultPlaylistImage from "assets/images/notes_grey_bg.svg";
import { useMediaQuery } from "react-responsive";
import { getOverlayImg } from "utils/storeUtils";
import { getMiniPlayerSelector } from "modules/MiniPlayer/selectors";
import { PAGE_ORIGIN_PODCASTS } from "utils/nameVariables";

const AddToPlaylist = (props) => {
  const { t, isLogged, playlists, listSongs, addToPlaylist, createPlaylist, miniPlayer } = props;

  const [showCreate, setShowCreate] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [textNewPlaylist, setTextNewPlaylist] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 880 });
  const isPodcast = miniPlayer?.pageOrigin === PAGE_ORIGIN_PODCASTS;
  const showCreateFunc = (bool) => {
    setShowCreate(bool);
    bool && setTextNewPlaylist(t("DEFAULT_NEW_PLAYLIST_NAME", { playlistNumber: playlists?.length + 1 || 1 }));
  };
  const showAddModalFunc = () => {
    if (isMobile) {
      setShowAddModal(!showAddModal);
      setShowCreate(false);
    }
  };
  const handleChange = (e) => {
    setTextNewPlaylist(e.target.value);
  };

  const handleCreate = async () => {
    await createPlaylist(listSongs, textNewPlaylist);
    setShowCreate(false);
    setTextNewPlaylist("");
  };

  if (!isLogged || isPodcast) return null;
  if (showAddModal)
    return (
      <div className="add-modal">
        <div className="header my-10 ">
          <div className="icon-close-modal flex flex-row justify-flex-end align-items-center" onClick={showAddModalFunc}>
            <span>{t(showCreate ? "MOBILE_WEBPLAYER_CANCEL" : "MOBILE_WEBPLAYER_CLOSE")}</span>
            <img src={getAbsolutePath(iconClose)} alt="" />
          </div>
        </div>
        <ul className="py-5 px-15 add-modal-content overflow-y-hidden">
          {getOverlayImg(true)}
          <li className="create-playlist ">
            <p className="playfair font-24">{t(showCreate ? "MY_LIBRARY_PLAYLISTS_CREATE_NEW" : "PLAYER_ACTIONS_1")}</p>
            {showCreate ? (
              <>
                <div className="box-input-create mb-10">
                  <input className="input-create" type="text" value={textNewPlaylist} onChange={handleChange} />
                </div>
                <div onClick={() => handleCreate()} className="button-create w-max-content">
                  {t("MY_LIBRARY_PLAYLISTS_CREATE_NEW")}
                </div>
              </>
            ) : (
              playlists && (
                <div className="box-list">
                  <div onClick={() => showCreateFunc(true)} className="button-create w-max-content">
                    {t("MY_LIBRARY_PLAYLISTS_CREATE_NEW")}
                  </div>
                  <ul className="with-images">
                    {playlists?.map((item) => (
                      <li key={item.id} onClick={() => addToPlaylist(item.id, listSongs)}>
                        <div className="image-container">
                          <EnhancedImage
                            size="small"
                            imageRootUrl={item.imageRootUrl}
                            imagePath={item.imagePaths[0]}
                            fallbackImage={defaultPlaylistImage}
                          />
                        </div>
                        {item.album_title}
                      </li>
                    ))}
                  </ul>
                </div>
              )
            )}
          </li>
        </ul>
      </div>
    );
  return (
    <li className="add-to-playlist flex flex-row align-items-center" onClick={showAddModalFunc}>
      <img src={getAbsolutePath(iconMenuPlaylist)} alt="" />
      {t("PLAYER_ACTIONS_1")}
     {!isMobile && <ul>
        <li className="create-playlist" onClick={showAddModalFunc}>
          <div onClick={() => showCreateFunc(true)} className="button-create">
            {t("MY_LIBRARY_PLAYLISTS_CREATE_NEW")}
          </div>
          {showCreate && (
            <div className="box-input-create">
              <input className="input-create" id="input-create-playlist" value={textNewPlaylist} type="text" onChange={handleChange} />
              <img className="icon-create" onClick={() => handleCreate()} src={getAbsolutePath(iconEnter)} alt="" />
              <img className="icon-create" onClick={() => showCreateFunc(false)} src={getAbsolutePath(closeSearch)} alt="" />
            </div>
          )}
          {playlists && (
            <div className="box-list">
              <ul>
                {playlists.map((item) => (
                  <li key={item.id} data-cy="user-playlist" onClick={() => addToPlaylist(item.id, listSongs)} className="truncate">
                    {item.album_title}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </li>
      </ul>}
    </li>
  );
};

const mapStateToProps = (state) => ({
  playlists: getPlaylistsSelector(state),
  isLogged: getLoginSelector(state),
  miniPlayer: getMiniPlayerSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  setPlaylists: (playlists) => {
    dispatch(dispatchSetPlaylists(playlists));
  },
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AddToPlaylist));
