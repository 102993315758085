import axios from "axios";
import ReactPixel from "react-facebook-pixel";
import { getUserEmail } from "utils/storeUtils";

const URL = process.env.REACT_APP_FB_CONVERSION_API_URL;
const API_KEY = process.env.REACT_APP_FB_CONVERSION_API_KEY;
let email;
let hashedEmail = "";

const initFBPixel = async () => {
  if (!window.allowFBTracking) return;
  email = getUserEmail || "No email found";
  hashedEmail = crypto.subtle ? await digestMessage(email) : ""; // http will not have crypto.subtle
  ReactPixel.init(process.env.REACT_APP_FB_PIXEL);
};

const trackFBEvent = (fbEvent, fbEventData) => {
  if (!window.allowFBTracking) return;
  const uuid = Date.now();
  sendConversionApiEvent(fbEvent, fbEventData, uuid);
  ReactPixel.track(fbEvent, fbEventData, uuid);
};

const trackFBPageView = () => {
  if (!window.allowFBTracking) return;
  const uuid = Date.now();
  sendConversionApiEvent("PageView", { URL: window?.location?.href }, uuid);
  ReactPixel.pageView(uuid);
};

async function digestMessage(message) {
  const msgUint8 = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
  return hashHex;
}

const sendConversionApiEvent = async (fbEvent, fbEventData, uuid) => {
  try {
    // for rules please see https://developers.facebook.com/docs/marketing-api/conversions-api/payload-helper
    // Below: All customer information parameters should be hashed as SHA256, except for client IP address,
    // client user agent, click ID, and browser ID. Any other customer information parameters that
    // are not hashed are automatically rejected by Facebook.
    const payload = {
      // mandatory
      fbData: {
        data: [
          {
            action_source: "website",
            event_id: uuid,
            event_name: fbEvent,
            event_time: Math.floor(new Date().getTime() / 1000),
            user_data: {
              em: [hashedEmail],
            },
          },
        ],
      },
      // mandatory
      pixelId: process.env.REACT_APP_FB_PIXEL,
      // optional. You can find this code in FB event manager => data sources => test events
      // to fire test events, uncomment this line, but also check the testcode (they change it all the time)
      // testEventCode: "TEST59935",
    };

    // optional
    if (fbEventData) {
      payload.fbData.data[0].custom_data = fbEventData;
    }

    let headers = {
      "x-api-key": API_KEY,
    };

    await axios.post(URL, payload, { headers });
  } catch (error) {
    console.log(error);
  }
};

export { initFBPixel, trackFBEvent, trackFBPageView };
