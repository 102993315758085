import "rc-slider/assets/index.css";
import React, { useState } from "react";
import { connect } from "react-redux";
import Slider from "rc-slider";
import MenuMore from "components/MenuMore";
import { dispatchUpdateMiniPlayer, dispatchUpdateModalWebPlayer } from "modules/MiniPlayer/actions";
import { getMiniPlayerSelector } from "modules/MiniPlayer/selectors";
import { getLoginSelector, getLangSelector } from "modules/Config/selectors";
import { getFlacStreamSelector } from "modules/Config/selectors";
import iconPlay from "assets/images/miniplayer/play.svg";
import iconPause from "assets/images/miniplayer/pause.svg";
import iconCollapse from "assets/images/miniplayer/collapse.svg";
import iconInfo from "assets/images/miniplayer/info.svg";
import iconVolume from "assets/images/ic_volume.svg";
import iconExpand from "assets/images/ic_expandplayer_white.svg";
import getAbsolutePath from "utils/getAbsolutePath";
import { withRouter } from "react-router";
import SettingsCog from "components/SettingsCog";
import { withTranslation } from "react-i18next";
import { getAlbumFromTrack, getDisplayArtistFromTrack } from "utils/metaDataTools";
import {
  classicalAlbumOption,
  composerOption,
  workOption,
  jazzAlbumOption,
  artistOption,
  recordingOption,
  podcastOption,
  chapterOption,
} from "components/MenuMore/MenuMoreOptions.js";
import { Link } from "react-router-dom";
import { getUserLikedTracksSelector } from "modules/Library/selectors";
import { dispatchSetUserLikedTracks } from "../../modules/Library/actions";
import { dispatchUpdateShowClassicModal } from "modules/Config/actions";
import MediaQuery from "react-responsive";
import TrackInfoMobile from "pages/ExpandedPlayer/TrackInfoMobile";

const ExpandedPlayerMiniplayer = (props) => {
  const {
    t,
    isLogged,
    isPodcast,
    prevTrack,
    prevTrackTitle,
    prevTrackDisplayArtistName,
    prevArtistId,
    prevArtistSlug,
    nextTrack,
    nextTrackTitle,
    nextTrackDisplayArtistName,
    nextArtistId,
    nextArtistSlug,
    isJazzTrack,
    playing,
    indexOfTrack,
    dataTrack,
    jazzMode,
    jazzFlag,
    fetchingSong,
    podcastId,
    chapterId,
    collapsePlayer,
    currentTrackId,
    work,
    recYear,
    lang,
    volume,
    volumeMute,
    collapsed,
    expandPlayer,
    stopExtending,
    getIconPrev,
    playPauseSong,
    getIconNext,
    toggleVolume,
    changeVolume,
    isWebplayer,
    playSong,
  } = props;
  const [showTrackInfo, setShowTrackInfo] = useState(false);

  const displayArtist = getDisplayArtistFromTrack(dataTrack[indexOfTrack]);

  const album = getAlbumFromTrack(dataTrack[indexOfTrack]);
  const menuMoreComponent = isPodcast ? (
    <MenuMore
      hideAddToPlaylist={true}
      urlOptions={[podcastOption(podcastId, jazzMode, lang, t), chapterOption(chapterId, jazzMode, lang, t)]}
      isMiniPlayer={true}
      isWebplayer={isWebplayer}
    />
  ) : (
    isLogged && (
      <MenuMore
        runAfterRedirect={collapsePlayer}
        listSongs={[currentTrackId]}
        isJazz={jazzFlag}
        urlOptions={
          jazzFlag
            ? album && [jazzAlbumOption(album, lang, t), artistOption(displayArtist, lang, t)]
            : [
                composerOption(displayArtist, lang, t),
                classicalAlbumOption(album, lang, t),
                workOption(work, lang, t),
                recordingOption(displayArtist, work, recYear, lang, t),
              ]
        }
        track={dataTrack[indexOfTrack]}
        isMiniPlayer={true}
        isWebplayer={isWebplayer}
        playSong={playSong}
      />
    )
  );

  const toggleShowTrackInfo = () => {
    setShowTrackInfo(!showTrackInfo);
  };
  return (
    <div className="miniplayer webplayer w-100 mt-25 mt-0-sm flex flex-row justify-space-between">
      <div className="left flex flex-column hide-sm">
        {prevTrack && (
          <>
            <p className="mb-5 truncate">
              <span className="mr-10">{t("WEBPLAYER_JUST_PLAYED")}:</span>
              <span className="bold">{prevTrackTitle}</span>
            </p>
            <p>
              <span className="mr-10">{t("WEBPLAYER_BY")}:</span>
              {isPodcast ? (
                <span className="bold">{prevTrackDisplayArtistName}</span>
              ) : (
                <Link to={`${isJazzTrack ? "jazz" : ""}/profile/${prevArtistId}/${prevArtistSlug}`} className="bold">
                  {prevTrackDisplayArtistName}
                </Link>
              )}
            </p>
          </>
        )}
      </div>
      <div name="player " className="mini-player__controls-right center" onClick={stopExtending}>
        <div className="flex flex-column controls-container w-100 justify-center">
          <div className="box-icons mb-20" name="player">
            <MediaQuery minWidth={659}>
              <div className="iconCollapse" onClick={collapsePlayer}>
                <img src={getAbsolutePath(iconCollapse)} alt="" />
              </div>
            </MediaQuery>
            <MediaQuery maxWidth={658}>
              <div className="mobileIconMore">{menuMoreComponent}</div>
            </MediaQuery>

            {getIconPrev()}
            <div
              className={`iconPausePlay ${fetchingSong ? "loading" : ""}`}
              data-cy={`${isWebplayer ? "extended_" : ""}miniplayer_playpause`}
              onClick={playPauseSong}
            >
              {playing ? <img src={getAbsolutePath(iconPause)} alt="" /> : <img src={getAbsolutePath(iconPlay)} alt="" />}
            </div>
            {getIconNext()}
            <MediaQuery maxWidth={658}>
              {showTrackInfo && <TrackInfoMobile toggleShowTrackInfo={toggleShowTrackInfo} collapsePlayer={collapsePlayer} isJazz={isJazzTrack} />}
              <div className="iconCollapse" onClick={toggleShowTrackInfo}>
                {!isPodcast && <img src={getAbsolutePath(iconInfo)} alt="" />}
                &nbsp;
              </div>
            </MediaQuery>
            <MediaQuery minWidth={659}>{menuMoreComponent}</MediaQuery>
          </div>
          <MediaQuery minWidth={880}>
            <div className="mini-player__controls-bottom hide-1200">
              <>
                <img onClick={toggleVolume} src={getAbsolutePath(iconVolume)} className={volumeMute ? "iconVolume disabled" : "iconVolume"} alt="" />
                <div className={`volume-container mr-15 ${jazzFlag ? "slider-box theme-slider jazz" : "slider-box theme-slider"}`}>
                  <Slider min={0} max={100} step={1} value={volumeMute ? 0 : volume} disabled={volumeMute} onChange={changeVolume} />
                </div>
              </>
              <SettingsCog className="stream-settings" isMiniPlayer={true} />
            </div>
          </MediaQuery>
        </div>

        {collapsed && isLogged && !isPodcast && <img onClick={expandPlayer} className="iconExpand" src={getAbsolutePath(iconExpand)} alt="" />}
      </div>
      <div className="right flex flex-column hide-sm text-right">
        {nextTrack && (
          <>
            <p className="mb-5 truncate">
              <span className="mr-10">{t("WEBPLAYER_UP_NEXT")}:</span>
              <span className="bold">{nextTrackTitle}</span>
            </p>
            <p>
              <span className="mr-10">{t("WEBPLAYER_BY")}:</span>
              {isPodcast ? (
                <span className="bold">{nextTrackDisplayArtistName}</span>
              ) : (
                <Link to={`${isJazzTrack ? "jazz" : ""}/profile/${nextArtistId}/${nextArtistSlug}`} className="bold">
                  {nextTrackDisplayArtistName}
                </Link>
              )}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  miniPlayer: getMiniPlayerSelector(state),
  isLogged: getLoginSelector(state),
  lang: getLangSelector(state),
  isFlacStream: getFlacStreamSelector(state),
  userLikedTracks: getUserLikedTracksSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateMiniPlayer: (data) => {
    dispatch(dispatchUpdateMiniPlayer(data));
  },
  updateShowModalWebPlayer: (showModal) => {
    dispatch(dispatchUpdateModalWebPlayer(showModal));
  },
  setShowClassicModal: (showClassicModal) => {
    dispatch(dispatchUpdateShowClassicModal(showClassicModal));
  },
  setUserLikedTracks: (playlists) => {
    dispatch(dispatchSetUserLikedTracks(playlists));
  },
});

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ExpandedPlayerMiniplayer)));
