import { soundManager } from "soundmanager2";
import silence from "assets/audio/1-second-of-silence.mp3";

let currentSong = "";
soundManager.setup({
  preferFlash: false,
  defaultOptions: {
    // set global default volume for all sound objects
    multiShot: false,
    autoPlay: false,
  },
});

const createSound = (onPlayingCallback, onFinishedCallback, onBufferingCallback, onLoadCallback, onErrorCallback) => {
  if (currentSong) return; //avoid creating a new sound if there's already one.

  console.log("%cCreated new sound object", "color: red");
  console.log(`%cSound is ${silence.toString()}`, "color: red");

  currentSong = soundManager.createSound({
    id: "sound",
    url: silence,
    volume: 100,
    whileplaying: () => {
      onPlayingCallback();
    },
    onfinish: () => {
      onFinishedCallback();
    },
    onbufferchange: () => {
      onBufferingCallback(currentSong.isBuffering);
    },
    onload: (isSuccess) => {
      onLoadCallback(isSuccess);
    },
    onerror: (code, description) => {
      onErrorCallback(code, description);
      currentSong?.unload?.();
    },
  });
};

const changeSoundAndPlay = (url, id, shouldPlay = true) => {
  const isMuted = currentSong.muted;
  if (currentSong) {
    currentSong.url = url;
    currentSong.id = id;
    const resp = shouldPlay && playSound();
    if (isMuted) currentSong.mute(); // soundmanager doesn't restore mute state on new sound play.
    return resp;
  }
};

const restoreSound = (url, id) => {
  if (currentSong) {
    currentSong.url = url;
    currentSong.id = id;
    currentSong?.stop();
    // currentSong.load({ url: url });
  }
};

const skip5seconds = () => {
  const newPosition = currentSong.position + 5000;
  if (newPosition < currentSong.durationEstimate) currentSong.setPosition(newPosition);
};

const rewind5seconds = () => {
  const newPosition = currentSong.position - 5000;
  if (newPosition > 0) currentSong.setPosition(newPosition);
};

const pauseSound = () => {
  // if (currentSong?._a?.readyState < 3) return;
  currentSong?.pause();
};

const playSound = () => {  
  if (currentSong) {
    return currentSong.play();
  }
};

const setPosition = (value) => {
  if (currentSong) currentSong.setPosition(value);
};

const getCurrentPosition = () => {
  return currentSong ? currentSong.position : "";
};

const getDuration = () => {
  return currentSong ? currentSong.durationEstimate : "";
};

const toggleMute = () => {
  currentSong.toggleMute();
};

const setVolume = (volume) => {
  if (currentSong) currentSong.setVolume(volume);
};

export {
  createSound,
  restoreSound,
  changeSoundAndPlay,
  playSound,
  pauseSound,
  skip5seconds,
  rewind5seconds,
  getCurrentPosition,
  getDuration,
  setPosition,
  toggleMute,
  setVolume,
  currentSong
};
