import { accessRoutes } from "components/ApplicationRoutes/routes";
import { playerGoToAlbumTracking, playerGoToArtistTracking, playerGoToChapterTracking, playerGoToComposerTracking, playerGoToPodcastTracking, playerGoToRecordingTracking, playerGoToWorkTracking } from "utils/miniPlayerMixpanelUtils";

const classicalAlbumOption = (album, lang, t) => {
  if (!album || !album.id || !lang) return;
  const lngRoutes = accessRoutes[lang];
  const slug = album.slug[lang] || album.slug
  return {
    url: `${lngRoutes.classicalAlbumsDetails}/${album.id}/${slug}`,
    text: t("JAZZ_PLAYER_GO_TO_ALBUM"),
    iconType: "note",
    miniPlayerTrackingFunc: playerGoToAlbumTracking,
  };
};

const jazzAlbumOption = (album, lang, t) => {
  if (!album || !album.id || !lang) return;
  const lngRoutes = accessRoutes[lang];
  const slug = album.slug[lang] || album.slug
  return {
    url: `${lngRoutes.jazzAlbumsDetails}/${album.id}/${slug}`,
    text: t("JAZZ_PLAYER_GO_TO_ALBUM"),
    iconType: "note",
    miniPlayerTrackingFunc: playerGoToAlbumTracking,
  };
};

const composerOption = (displayArtist, lang, t) => {
  if (!displayArtist || !displayArtist.id || !lang) return;
  const lngRoutes = accessRoutes[lang];
  return {
    url: `${lngRoutes.personPage}/${displayArtist.id}/${displayArtist.slug}`,
    text: t("PLAYER_ACTIONS_3"),
    iconType: "composerIcon",
    miniPlayerTrackingFunc: playerGoToComposerTracking,
  };
};

const artistOption = (displayArtist, lang, t) => {
  if (!displayArtist || !displayArtist.id || !lang) return;
  const lngRoutes = accessRoutes[lang];
  return {
    url: `${lngRoutes.jazzArtistsDetails}/${displayArtist.id}/${displayArtist.slug}`,
    text: t("JAZZ_PLAYER_GO_TO_ARTIST"),
    iconType: "composerIcon",
    miniPlayerTrackingFunc: playerGoToArtistTracking,
  };
};

const workOption = (work, lang, t) => {
  if (!work || !work.id || !lang) return;
  const lngRoutes = accessRoutes[lang];
  return {
    url: `${lngRoutes.workDetail}/${work.id}/${work.slug}`,
    text: t("PLAYER_ACTIONS_4"),
    iconType: "note",
    miniPlayerTrackingFunc: playerGoToWorkTracking,
  };
};

const recordingOption = (displayArtist, work, recYear, lang, t) => {
  if (!displayArtist || !displayArtist.id || !displayArtist.slug || !work || !work.id || !work.slug || !lang) return;
  const lngRoutes = accessRoutes[lang];
  const urlWithoutYear = `${lngRoutes.personPage}/${displayArtist.id}/${displayArtist.slug}/${lngRoutes.recording}/${work.id}/${work.slug}`;
  const fullUrl = urlWithoutYear.concat(recYear ? `/${recYear}` : "");

  return {
    url: fullUrl,
    text: t("MENUMORE_GOTO_RECORDING"),
    iconType: "note",
    miniPlayerTrackingFunc: playerGoToRecordingTracking,
  };
};

const podcastOption = (id, jazzMode, lang, t) => {
  if (!id || !lang) return;
  const lngRoutes = accessRoutes[lang];
  return {
    url: jazzMode ? `${lngRoutes.podcastDetailsJazz}/${id}` : `${lngRoutes.podcastDetails}/${id}`,
    text: t("PLAYER_GO_TO_PODCAST"),
    iconType: "note",
    miniPlayerTrackingFunc: playerGoToPodcastTracking,
  };
};

const chapterOption = (id, jazzMode, lang, t) => {
  if (!id || !lang) return;
  const lngRoutes = accessRoutes[lang];
  return {
    url: jazzMode ? `${lngRoutes.chapterDetailsJazz}/${id}` : `${lngRoutes.chapterDetails}/${id}`,
    text: t("PLAYER_GO_TO_CHAPTER"),
    iconType: "note",
    miniPlayerTrackingFunc: playerGoToChapterTracking,
  };
};

export { classicalAlbumOption, jazzAlbumOption, composerOption, artistOption, workOption, recordingOption, podcastOption, chapterOption };
