import React from "react";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import iconClose from "assets/images/close_search.svg";
import { withRouter } from "react-router-dom";
import { accessRoutes } from "components/ApplicationRoutes/routes";

const StreamQualityPopup = ({ t, closeModal, history }) => {
  const lang = useSelector((state) => state.config.lang);
  const lngRoutes = accessRoutes[lang];

  const goToUpgrade = () => {
    history.push(lngRoutes.upgrade);
    closeModal();
  };

  return (
    <div className="streamQualityPopup">
      <div className="icon-close">
        <img onClick={closeModal} src={iconClose} alt="" />
      </div>
      <div className="content-wrapper">
        <div className="stream-title">{t("NOTIFICATION_NO_FLAC_STREAM_QUALITY")}</div>
        <div className="stream-description">{t("NOTIFICATION_NO_FLAC_DESCRIPTION")}</div>
        <div className="stream-allvialma-url" onClick={goToUpgrade}>{t("NOTIFICATION_NO_FLAC_ALL_VIALMA")}</div>
        <div className="stream-button-wrapper">
          <button className="button-red" onClick={goToUpgrade}>
            {t("NOTIFICATION_NO_FLAC_CTA_UPGRADE")}
          </button>
          <button className="button-white-outlined" onClick={closeModal}>
            {t("NOTIFICATION_NO_FLAC_BTN_NO_UPGRADE")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(withTranslation()(StreamQualityPopup));
