import React, { Fragment, useState } from "react";
import { withTranslation } from "react-i18next";
import tick from "assets/images/tick_thicker.svg";
import { useSelector } from "react-redux";
import cog from "assets/images/miniplayer/cogs.svg";
import getAbsolutePath from "utils/getAbsolutePath";
import lock from "assets/images/Lock_big.svg";
import Modal from "react-modal";
import StreamPopupAllowed from "components/StreamQualityPopups/PopupAllowed";
import StreamPopupNotAllowed from "components/StreamQualityPopups/PopupNotAllowed";
import customStyles from "utils/customStyleModal";
import iconClose from "assets/images/close_search.svg";
import { handleToggleAudioQuality } from "utils/apiLibrary/client";

const SettingsCog = ({ t, className, isMiniPlayer = false }) => {
  const isFlacAllowed = useSelector((state) => state.config.isFlacAllowed);
  const isFlacStream = useSelector((state) => state.config.isFlacStream);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const getFlacIcon = () => {
    if (isFlacAllowed) {
      return isFlacStream ? (
        <img className="icon visible" src={getAbsolutePath(tick)} alt="" />
      ) : (
        <img className="icon" src={getAbsolutePath(tick)} alt="" />
      );
    } else {
      return <img className="icon visible" src={getAbsolutePath(lock)} alt="" />;
    }
  };

  const getStandardIcon = () => {
    return isFlacStream ? (
      <img className="icon" src={getAbsolutePath(tick)} alt="" />
    ) : (
      <img className="icon visible" src={getAbsolutePath(tick)} alt="" />
    );
  };

  const toggleFlac = () => {
    const disableFlacAlert = JSON.parse(localStorage.getItem("disableFlacAlert"));
    if (!disableFlacAlert && !isFlacStream) {
      setModalOpen(true);
      closeSettings();
    } else {      
      handleToggleAudioQuality();
      closeSettings();
    }
  };

  const closeSettings = () => {
    setSettingsOpen(false);
    setTimeout(() => {
      window.removeEventListener("click", closeSettings);
    }, 0);
  };

  const openSettings = () => {
    setSettingsOpen(true);
    setTimeout(() => {
      window.addEventListener("click", closeSettings);
    }, 0);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <Fragment>
      <div className={`icon-settings ${className}`}>
        <span className="tooltiptext">{t("MINIPLAYER_COG_TOOLTIP")}</span>
        <img src={getAbsolutePath(cog)} alt="" onClick={settingsOpen ? closeSettings : openSettings} />
        <div className={`settings-options-wrapper ${settingsOpen ? "visible" : ""}`}>
          <div className="option-wrapper" onClick={isFlacStream ? null : toggleFlac}>
            {getFlacIcon()}
            <span>{t("MY_ACCOUNT_STREAMING_QUALITY_DEFAULT_HIGH_QUALITY")}</span>
          </div>
          <div className="option-wrapper" onClick={isFlacStream ? toggleFlac : null}>
            {getStandardIcon()}
            <span>{t("MINIPLAYER_COG_STANDARD_STREAMING")}</span>
          </div>
        </div>
        <div className={`settings-options-wrapper-mobile ${settingsOpen ? "visible" : ""}`}>
          <div className="icon-close-modal">
            <img onClick={closeSettings} src={getAbsolutePath(iconClose)} alt="" />
          </div>
          <ul className="settings-list-mobile">
            <li onClick={isFlacStream ? null : toggleFlac}>
              {getFlacIcon()}
              <span>{t("MY_ACCOUNT_STREAMING_QUALITY_DEFAULT_HIGH_QUALITY")}</span>
            </li>
            <li onClick={isFlacStream ? toggleFlac : null}>
              {getStandardIcon()}
              <span>{t("MINIPLAYER_COG_STANDARD_STREAMING")}</span>
            </li>
          </ul>
        </div>
      </div>
      <Modal isOpen={modalOpen} onRequestClose={closeModal} style={customStyles} ariaHideApp={false}>
        {modalOpen &&
          (isFlacAllowed ? (
            <StreamPopupAllowed closeModal={closeModal} isMiniPlayer={isMiniPlayer} />
          ) : (
            <StreamPopupNotAllowed closeModal={closeModal} />
          ))}
      </Modal>
    </Fragment>
  );
};

export default withTranslation()(SettingsCog);
