import { accessRoutes } from "components/ApplicationRoutes/routes";
import { fetchLang, isHeaderJazzUniverse } from "utils/storeUtils";

const jazzBrowseMenuItems = () => {
  const lang = fetchLang();
  const lngRoutes = accessRoutes[lang];
  return [
    { name_en: "Albums", name_fr: "Albums", pathName: lngRoutes.jazzAlbums, state: { jazzMode: true } },
    { name_en: "Playlists", name_fr: "Playlists", pathName: lngRoutes.jazzPlaylists, state: { jazzMode: true } },
    { name_en: "Articles", name_fr: "Articles", pathName: lngRoutes.jazzArticles, state: { jazzMode: true } },
    { name_en: "Videos", name_fr: "Vidéos", pathName: lngRoutes.jazzVideos, state: { jazzMode: true } },
    { name_en: "Collections", name_fr: "Collections", pathName: lngRoutes.jazzCollections, state: { jazzMode: true } },
    { name_en: "Podcasts", name_fr: "Podcasts", pathName: lngRoutes.podcastsJazz, state: { jazzMode: true } },
  ];
};

const classicalBrowseMenuItems = () => {
  const lang = fetchLang();
  const lngRoutes = accessRoutes[lang];
  return [
    { name_en: "Playlists", name_fr: "Playlists", pathName: lngRoutes.playlists, state: { jazzMode: false } },
    { name_en: "Articles", name_fr: "Articles", pathName: lngRoutes.articles, state: { jazzMode: false } },
    { name_en: "Videos", name_fr: "Vidéos", pathName: lngRoutes.videos, state: { jazzMode: false } },
    { name_en: "Collections", name_fr: "Collections", pathName: lngRoutes.collections, state: { jazzMode: false } },
    { name_en: "Podcasts", name_fr: "Podcasts", pathName: lngRoutes.podcasts, state: { jazzMode: false } },
  ];
};

const isRouteActive = (url, jazzMode) => {
  let currentPageClass = "";
  if (url) {
    const lang = fetchLang();
    const lngRoutes = accessRoutes[lang];
    const relatedMap = {
      [lngRoutes.playlists]: [lngRoutes.playlists, lngRoutes.playlistDetails],
      [lngRoutes.articles]: [lngRoutes.articles, lngRoutes.articleDetails],
      [lngRoutes.videos]: [lngRoutes.videos, lngRoutes.videoDetails],
      [lngRoutes.podcasts]: [lngRoutes.podcastDetails, lngRoutes.chapterDetails],

      [lngRoutes.jazzAlbums]: [lngRoutes.jazzAlbums, lngRoutes.jazzAlbumsDetails],
      [lngRoutes.jazzPlaylists]: [lngRoutes.jazzPlaylists, lngRoutes.jazzPlaylistDetails],
      [lngRoutes.jazzArticles]: [lngRoutes.jazzArticles, lngRoutes.jazzArticleDetails],
      [lngRoutes.jazzVideos]: [lngRoutes.jazzVideos, lngRoutes.jazzVideoDetails],
      [lngRoutes.podcastsJazz]: [lngRoutes.podcastsJazz, lngRoutes.podcastDetailsJazz, lngRoutes.chapterDetailsJazz],

      [lngRoutes.account]: [lngRoutes.account, lngRoutes.cancelSubscription, lngRoutes.cancellationConfirmed],
      [lngRoutes.upgrade]: [lngRoutes.upgrade, lngRoutes.subscribe],
      [lngRoutes.giftcardPurchase]: [lngRoutes.giftcardPurchase],
    };

    const isActiveClass =
      relatedMap[url] && relatedMap[url].some((relatedMapUrl) => window.location.pathname.indexOf(relatedMapUrl) !== -1) ? "active" : "";
    const isJazzClass = isHeaderJazzUniverse() || jazzMode ? "jazz" : "";

    currentPageClass = isActiveClass + " " + isJazzClass;
  }

  return currentPageClass;
};
export { jazzBrowseMenuItems, classicalBrowseMenuItems, isRouteActive };
