import * as Sentry from "@sentry/react";

const getDefaultLang = () => {
  try {
    const language = localStorage.getItem("userLang");
    if (language && language.length === 2) {
      return language.toLowerCase().indexOf("fr") !== -1 ? "fr" : "en";
    } else {
      return navigator.language.toLowerCase().indexOf("fr") !== -1 ? "fr" : "en";
    }
  } catch (e) {
    Sentry.captureException(e);
    return "en";
  }
};

export default getDefaultLang;
