import produce from "immer";
import { SET_SEARCH_DATA_CLASSICAL, SET_SEARCH_DATA_JAZZ, SET_SEARCH_INITIAL_STATE, SET_SEARCH_INITIAL_STATE_JAZZ } from "./constants";

const initialState = {
  searchDataClassical: null,
  searchDataJazz: null,
};

const searchReducer = function (state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_SEARCH_DATA_CLASSICAL:
        draft.searchDataClassical = action.payload;
        break;
      case SET_SEARCH_INITIAL_STATE:
        draft.searchDataClassical = null;
        break;
      case SET_SEARCH_INITIAL_STATE_JAZZ:
        draft.searchDataJazz = null;
        break;
      case SET_SEARCH_DATA_JAZZ:
        draft.searchDataJazz = action.payload;
        break;
      default:
        return;
    }
  });
};

export default searchReducer;
