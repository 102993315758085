import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { getMiniPlayerSelector, getCollapsedSelector } from "modules/MiniPlayer/selectors";
import { dispatchUpdateModalWebPlayer } from "modules/MiniPlayer/actions";
import { dispatchUpdateMiniPlayer } from "modules/MiniPlayer/actions";
import { getLangSelector, getLoginSelector } from "modules/Config/selectors";
import {
  getWorkFromTrack,
  getRecordYear,
  getLabelFromTrack,
} from "utils/metaDataTools";
import getAbsolutePath from "utils/getAbsolutePath";
import iconClose from "assets/images/close_search.svg";
import Pill from "components/Pill";
import { ALBUM, ARTIST, DISPLAY_ARTIST } from "utils/nameVariables";
import { accessRoutes } from "components/ApplicationRoutes/routes";
import { getOverlayImg } from "utils/storeUtils";

const TrackInfoMobile = ({ t, isLogged, lang, miniPlayer, isJazz, collapsePlayer, toggleShowTrackInfo, isMobile }) => {
  const { dataTrack, indexOfTrack } = miniPlayer;
  const lngRoutes = accessRoutes[lang];

  const handleClose = () => {
    toggleShowTrackInfo();
  };

  const handlePillClick = () => {
    toggleShowTrackInfo()
    collapsePlayer()
  }

  const track = dataTrack?.[indexOfTrack];
  const trackName = track?.trackTitle || track?.track_metadata?.title;
  const work = getWorkFromTrack(track);
  const recordingYear = getRecordYear(track);
  const recordLabel = getLabelFromTrack(track);

  const getPillProps = (track, typeProp, index) => {
    let metaData = track?.track_metadata;

    let type;
    let imageRootUrl;
    let imagePath;
    let topContent;
    let bottomContent;
    let targetUrl;
    switch (typeProp) {
      case DISPLAY_ARTIST:
        const displayArtist = metaData.actors.displayArtist;
        type = ARTIST;
        imageRootUrl = displayArtist.imageRootUrl;
        imagePath = displayArtist.imagePath;
        topContent = displayArtist.name || `${displayArtist.firstName} ${displayArtist.lastName}`;
        let periods = [];
        displayArtist.periods.forEach((period) => periods.push(period?.name || (typeof period === "string" && period)));
        bottomContent = periods.join(", ");
        targetUrl = `${isJazz ? lngRoutes.jazzArtistsDetails : lngRoutes.personPage}/${displayArtist.id}/${displayArtist.slug}`;
        break;
      case ARTIST:
        const artist = metaData.actors.artists[index];
        type = ARTIST;
        imageRootUrl = artist.imageRootUrl;
        imagePath = artist.imagePath;
        topContent = artist.name || `${artist.firstName} ${artist.lastName}`;
        let roles = [];
        artist.roles.forEach((role) => roles.push(role?.name || (typeof role === "string" && role)));
        bottomContent = roles.join(", ");
        targetUrl = `${isJazz ? lngRoutes.jazzArtistsDetails : lngRoutes.personPage}/${artist.id}/${artist.slug}`;
        break;
      case ALBUM:
        const album = metaData.album;
        type = ALBUM;
        imageRootUrl = album.imageRootUrl;
        imagePath = album.imagePath;
        topContent = album.title;
        bottomContent = album.releaseDate;
        targetUrl = `${isJazz ? lngRoutes.jazzAlbumsDetails : lngRoutes.classicalAlbumsDetails}/${album.id}/${
          album?.slugs?.[lang] || album?.slug || ""
        }`;
        break;
      default:
        type = null;
        imageRootUrl = null;
        imagePath = null;
        topContent = null;
        bottomContent = null;
        targetUrl = "/";
    }
    return {
      type,
      imageRootUrl,
      imagePath,
      topContent,
      bottomContent,
      targetUrl,
    };
  };
  return (
    <div className="modal-more-menu">
      <div className="icon-close-modal my-10 flex justify-flex-end align-items-center" onClick={handleClose}>
        <span>{t("MOBILE_WEBPLAYER_CLOSE")}</span>
        <img src={getAbsolutePath(iconClose)} alt="" />
      </div>
      <ul className="track-info-menu pt-5 px-15">
        {getOverlayImg(true)}
        <li className="mb-50">
          <p className="playfair font-24 truncate short py-3">{trackName}</p>
        </li>
        <li className="mb-50">
          <p className="font-20 roboto bold">{t("MOBILE_WEBPLAYER_ABOUT_THE_WORK")}</p>
          <Pill {...getPillProps(track, DISPLAY_ARTIST)} handleClick={handlePillClick}/>
          {work && (
            <p>
              {t("MOBILE_WEBPLAYER_FROM_WORK")}: {work?.name}
            </p>
          )}
          {recordingYear && (
            <p>
              {t("MOBILE_WEBPLAYER_COMPOSED")}: {recordingYear}
            </p>
          )}
        </li>
        <li className="mb-50">
          <p className="font-20 roboto bold">{t("MOBILE_WEBPLAYER_ABOUT_THE_RECORDING")}</p>
          <Pill {...getPillProps(track, ALBUM)} square handleClick={handlePillClick}/>
          {recordLabel && (
            <p>
              {t("MOBILE_WEBPLAYER_RECORD_LABEL")}: {recordLabel}
            </p>
          )}
        </li>
        <li className="mb-50">
          <p className="font-20 roboto bold">{t("MOBILE_WEBPLAYER_PERFORMING_ARTISTS")}</p>
          {track?.track_metadata?.actors?.artists?.map((artist, index) => (
            <Pill {...getPillProps(track, ARTIST, index)} key={index} handleClick={handlePillClick} />
          ))}
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => ({
  miniPlayer: getMiniPlayerSelector(state),
  lang: getLangSelector(state),
  collapsed: getCollapsedSelector(state),
  isLogged: getLoginSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateMiniPlayer: (data) => {
    dispatch(dispatchUpdateMiniPlayer(data));
  },
  updateShowModalWebPlayer: (showModal) => {
    dispatch(dispatchUpdateModalWebPlayer(showModal));
  },
});

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(TrackInfoMobile)));
