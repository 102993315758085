import produce from 'immer';
import FILTERS from './mock';
import {
  SET_COMPOSERS,
  SET_COMPOSERS_LANG,
  SET_FILTERS_COMPOSERS,
  SET_FILTERED_COMPOSERS,
  SET_COMMENTS_COMPOSER,
  SET_SEARCH_COMPOSER,
  SET_COMPOSERS_DEFAULTS,
} from './constants';

const initialState = {
  composers: null,
  langComposers: 'en',
  filters: FILTERS,
  filteredComposers: null,
  comments: null,
  search: '',
};

const composersReducer = function (state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SET_COMPOSERS:
        draft.composers = action.composers;
        break;
      case SET_COMPOSERS_LANG:
        draft.langComposers = action.langComposers;
        break;
      case SET_FILTERS_COMPOSERS:
        draft.filters = Object.assign([], action.filters);
        break;
      case SET_FILTERED_COMPOSERS:
        draft.filteredComposers = action.filteredComposers;
        break;
      case SET_COMMENTS_COMPOSER:
        draft.comments = action.comments;
        break;
      case SET_SEARCH_COMPOSER:
        draft.search = action.search;
        break;
      case SET_COMPOSERS_DEFAULTS:
        return initialState;
      default:
        return;
    }
  });
}

export default composersReducer;