import produce from "immer";
import { SET_RADIOS, SET_RADIOS_SONGS, RESET_RADIOS, SET_LANG_RADIOS, SET_RADIOS_DEFAULTS } from "./constants";

const initialState = {
  radios: null,
  songs: null,
  langRadios: "en",
  lastPage: null,
};

const radiosReducer = function (state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_RADIOS:
        draft.radios = action.radios;
        break;
      case SET_RADIOS_SONGS:
        draft.songs = action.songs;
        break;
      case RESET_RADIOS:
        draft.radios = null;
        draft.songs = null;
        break;
      case SET_LANG_RADIOS:
        draft.langRadios = action.langRadios;
        break;
      case SET_RADIOS_DEFAULTS:
        return initialState;
      default:
        return;
    }
  });
}

export default radiosReducer;