import { FREETV_REDIRECT } from "./localStoreKeys";
import { Mixpanel } from "./mixpanel";
import notificationManager from "./notificationManager";
import i18n from "i18n";
import { ARTICLE, PLAYLIST, VIDEO } from "./nameVariables";
import RecommendBoxContainer from "components/RecommendBoxContainer";

// general purpose utility functions. If you can't find any other files for your function, put it here.

export const encodeWithQuote = (textToEncode) => {
  if (!textToEncode) return;

  return encodeURIComponent(textToEncode).replace(/'/g, "%27");
};

//IFF params are for the FREE TV redirect to vialma.com/fr?tmpCode=xxxx
//The user is expected to login/register and then send their freetv param to the BE via the /login_check API
//This connects their account to Free TV
export const setFreeTvSessionParam = () => {
  let resp;
  try {
    const url = new URL(window.location.href);
    const freeTvParam = url.searchParams.get("tmpCode");
    if (freeTvParam) resp = freeTvParam;
  } catch (error) {
    clearFreeTvParam();
  } finally {
    resp && window.sessionStorage.setItem(FREETV_REDIRECT, resp);
  }
};

export const getFreeTvParam = () => {
  return window?.sessionStorage?.getItem(FREETV_REDIRECT);
};
export const clearFreeTvParam = () => {
  window?.sessionStorage?.removeItem(FREETV_REDIRECT);
};

export const checkFreeTvSuccessfulAndClear = () => {
  const checkIfGenericFormRedirect = () => {
    let resp;
    try {
      const url = new URL(window.location.href);
      const genericFormFreeTvParam = url.searchParams.get("freeTvSuccessful");
      if (genericFormFreeTvParam) resp = genericFormFreeTvParam;
    } catch (e) {
      resp = "false";
    }
    return resp;
  };
  if (getFreeTvParam() || checkIfGenericFormRedirect() === "true") {
    notificationManager.showMessage("none", i18n.t("IFF_REDIRECT_SUCCESSFUL_MESSAGE"));
    clearFreeTvParam();
  } else if (checkIfGenericFormRedirect() === "false") {
    notificationManager.showMessage("fail", i18n.t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
  }
};

//Mixpanel Tracking for play event
export const playTrackingFunction = (el, targetUrl) => {
  let trackingParams = ["Play click from Content tile", { target: targetUrl }];
  const sectionTitle = el?.target?.closest?.("[data-mx]")?.getAttribute?.("data-mx");
  if (sectionTitle) {
    trackingParams[1] = { ...trackingParams[1], section: sectionTitle };
  }
  Mixpanel.track(...trackingParams);
};

export const getRedirectRoute = () => {
  try {
    const url = new URL(window.location.href);
    let redirectRoute = url.searchParams.get("route");
    const routeQuery = url.searchParams.get("query");
    if (redirectRoute && routeQuery) redirectRoute = redirectRoute + `?${routeQuery}`;
    return redirectRoute;
  } catch (error) {
    return null;
  }
};

export const generateUniqueItems = (data) => {
  try {
    let uniqueArray = [];
    if (data?.length) {
      uniqueArray[0] = data[0];
      for (let item of data) {
        uniqueArray.length < 4 && !uniqueArray.find((entry) => entry.type === item.type) && uniqueArray.push(item);
        if (uniqueArray.length === 4) break;
      }

      if (uniqueArray.length < data.length && uniqueArray.length < 4) {
        for (let item of data) {
          uniqueArray.length < 4 && !uniqueArray.find((entry) => entry.id === item.id) && uniqueArray.push(item);
          if (uniqueArray.length === 4) break;
        }
      }
    }
    return uniqueArray;
  } catch (error) {
    return [];
  }
};

export const toggleCrispChatVisibility = (shouldShow) => {
  shouldShow ? window.$crisp?.push?.(["do", "chat:show"]) : window.$crisp?.push?.(["do", "chat:hide"]);
};

export const generateArticlesRecommendedContent = (data, isJazz) => {
  const arr = ["recommendedContent1", "recommendedContent2", "recommendedContent3", "recommendedContent4"];
  const fields = [
    { field: "newsId", labelColor: "green", type: ARTICLE, copy: i18n.t("ARTICLES_DETAILED_READ") },
    { field: "radioId", labelColor: "brown", type: PLAYLIST, copy: i18n.t("ARTICLES_DETAILED_LISTEN") },
    { field: "videoId", labelColor: "purple", type: VIDEO, copy: i18n.t("ARTICLES_DETAILED_WATCH") },
  ];
  let resp;
  try {
    resp = [];
    if (data) {
      arr.forEach((recommendedField, index) => {
        if (data[recommendedField]) {
          fields.forEach((fieldObj) => {
            if (data[recommendedField][fieldObj?.field]) {
              resp.push(
                <RecommendBoxContainer
                  allow={true}
                  labelColor={fieldObj?.labelColor}
                  copy={fieldObj?.copy}
                  content={data[recommendedField]}
                  isJazz={isJazz}
                  type={fieldObj?.type}
                />
              );
            }
          });
        }
      });
    }
  } catch (error) {
    resp = null;
  }
  return resp;
};

export const isValidUrl = (urlStr) => {
  let isValid = false;
  try {
    isValid = new URL(urlStr);
  } catch (error) {
    isValid = false;
  }
  return isValid;
};

export const isActiveTranslation = (key) => {
  // Checks if a translation is active or valid.
  return key && i18n.t(key) !== key;
};
