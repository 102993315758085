import produce from "immer";
import {
  SET_LANG_LIBRARY,
  SET_LISTENING_HISTORY,
  SET_PLAYLISTS,
  SET_USER_LIKED_TRACKS,
  SET_VIALMA_PLAYLIST,
  SET_VIALMA_PLAYLIST_LIKED,
  SET_VIALMA_PLAYLIST_LIKED_PLUSMINUS,
  SET_USER_LIKED_ALBUMS,
  SET_USER_LIKED_VIDEOS,
  SET_USER_LIKED_COLLECTIONS,
} from "./constants";

const initialState = {
  langLibrary: "en",
  listeningHistory: null,
  playlists: null,
  vialmaPlaylists: null,
  vialmaPlaylistLiked: 0,
  userLikedTracks: null,
  userLikedAlbums: null,
  userLikedVideos: null,
  userLikedCollections: null,
};

const handleAdd = (arr, item) => {
  try {
    arr.push(item);
  } catch (error) {
    arr = [].push(item);
  }
};

const handleRemove = (arr, idToFind) => {
  try {
    const refIndex = arr.findIndex((item) => item.id === idToFind);
    if (refIndex > -1) {
      arr.splice(refIndex, 1);
    }
  } catch (error) {
    arr = [];
  }
};

const libraryReducer = function (state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_LANG_LIBRARY:
        draft.langLibrary = action.langLibrary;
        break;
      case SET_LISTENING_HISTORY:
        draft.listeningHistory = action.listeningHistory;
        break;
      case SET_PLAYLISTS:
        draft.playlists = action.playlists;
        break;
      case SET_VIALMA_PLAYLIST_LIKED:
        draft.vialmaPlaylistLiked = action.vialmaPlaylistLiked;
        break;
      case SET_VIALMA_PLAYLIST_LIKED_PLUSMINUS:
        draft.vialmaPlaylistLiked = action.isPlus ? state.vialmaPlaylistLiked + 1 : state.vialmaPlaylistLiked - 1;
        break;
      case SET_USER_LIKED_TRACKS:
        let userLikedTracks = Array.isArray(action.userLikedTracks) ? [...action.userLikedTracks] : [];
        draft.userLikedTracks = userLikedTracks;
        break;
      case SET_VIALMA_PLAYLIST:
        if (action.action === "add") {
          handleAdd(draft.vialmaPlaylists, action.item);
        } else if (action.action === "remove") {
          handleRemove(draft.vialmaPlaylists, action.item);
        } else {
          draft.vialmaPlaylists = Array.isArray(action.vialmaPlaylists) ? [...action.vialmaPlaylists] : [];
        }
        draft.vialmaPlaylistLiked = action.vialmaPlaylists?.length;
        break;
      case SET_USER_LIKED_ALBUMS:
        if (action.action === "add") {
          handleAdd(draft.userLikedAlbums, action.item);
        } else if (action.action === "remove") {
          handleRemove(draft.userLikedAlbums, action.item);
        } else {
          draft.userLikedAlbums = Array.isArray(action.userLikedAlbums) ? [...action.userLikedAlbums] : [];
        }
        break;
      case SET_USER_LIKED_VIDEOS:
        if (action.action === "add") {
          handleAdd(draft.userLikedVideos, action.item);
        } else if (action.action === "remove") {
          handleRemove(draft.userLikedVideos, action.item);
        } else {
          draft.userLikedVideos = Array.isArray(action.userLikedVideos) ? [...action.userLikedVideos] : [];
        }

        break;
      case SET_USER_LIKED_COLLECTIONS:
        if (action.action === "add") {
          draft.userLikedCollections.push(action.item);
        } else if (action.action === "remove") {
          handleRemove(draft.userLikedCollections, action.item);
        } else {
          draft.userLikedCollections = Array.isArray(action.userLikedCollections) ? [...action.userLikedCollections] : [];
        }
        break;
      default:
        return;
    }
  });
};

export default libraryReducer;
