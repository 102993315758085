import { accessRoutes } from "components/ApplicationRoutes/routes";
import i18n from "i18n";
import { ALBUM, ARTICLE, ARTIST, PLAYLIST } from "./nameVariables";

const getMediaTypeForMiniplayer = (type, url) => {
  let resp = {};

  switch (type) {
    case "profile":
      resp.type = ARTIST;
      break;
    case "article":
      resp.type = ARTICLE;
      break;
    case "playlist":
      resp.type = PLAYLIST;
      break;
    case "album":
      resp.type = ALBUM;
      break;
    default:
      resp = null;
      break;
  }
  if (type && url) {
    resp.url = url;
  }
  return resp;
};

const parseMediaProps = (mediaProps) => {
  const hasMediaProps = typeof mediaProps === "object" && mediaProps !== null;
  let playingFromText = "";
  let playingFromUrl = "#";
  if (hasMediaProps) {
    playingFromText = `${i18n.t("PLAYING_FROM")} ${mediaProps?.type}: ${mediaProps?.name}`;
    playingFromUrl = mediaProps?.url;
  }
  return { hasMediaProps, playingFromText, playingFromUrl };
};

const getArtistUrl = (artistObject, lang, isJazz) => {
  const lngRoutes = accessRoutes[lang];
  if (artistObject) {
    return `${isJazz ? lngRoutes.jazzArtistsDetails : lngRoutes.personPage}/${artistObject.id}/${artistObject.slug}`;
  }
  return "#";
};

export { getMediaTypeForMiniplayer, parseMediaProps, getArtistUrl };
