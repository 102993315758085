import { createSelector } from "reselect";
import { REDUCER_KEY } from "./constants";

const getMiniPlayer = state => state[REDUCER_KEY];
const getIsWebPlayer = state => state[REDUCER_KEY].isWebPlayer;
const getCollapsed = state => state[REDUCER_KEY].collapsed;
const getUrl = state => state[REDUCER_KEY].url;
const getSlug = state => state[REDUCER_KEY].slug;
const getShowModalWebPlayer = state => state[REDUCER_KEY].showModal;
const getExpandedPlayerCollapsed = state => state[REDUCER_KEY].expandedPlayerCollapsed;

export const getMiniPlayerSelector = createSelector(
  getMiniPlayer,
  miniPlayer => miniPlayer
);

export const getIsWebPlayerSelector = createSelector(
  getIsWebPlayer,
  isWebPlayer => isWebPlayer
);

export const getCollapsedSelector = createSelector(
  getCollapsed,
  collapsed => collapsed
);

export const getUrlSelector = createSelector(
  getUrl,
  url => url
);

export const getSlugSelector = createSelector(
  getSlug,
  slug => slug
);

export const getShowModalWebPlayerSelector = createSelector(
  getShowModalWebPlayer,
  showModal => showModal
);

export const getexpandedPlayerCollapsedSelector = createSelector(
  getExpandedPlayerCollapsed,
  expandedPlayerCollapsed => expandedPlayerCollapsed
);