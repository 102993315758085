const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "0"
  },
  overlay: {
    backgroundColor: "rgba(0,0,0, 0.75)"
  }
};

export default customStyles;
