const formatTime = (seconds) => {
  const date = new Date(seconds < 0 ? 0 : seconds * 1000);
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = pad(date.getUTCSeconds())
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`
  }
  return `${mm}:${ss}`
}

const pad = (string) => (
  ('0' + string).slice(-2)
);

export default formatTime;