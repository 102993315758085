const translationsToFR = {
  "welcome": "bienvenue",
  "expired": "expire",
  "noplan": "sans-plan",
  "registration": "inscription",
  "confidentiality": "confidentialite",
  "forgot-password": "mot-de-passe-perdu",
  "generating-new-one": "generation",
  "who-are-we": "qui-sommes-nous",
  "subscribe": "s-inscrire",
  "checkout": "paiement",
  "works": "oeuvres",
  "home": "accueil",
  "composers": "compositeurs",
  "welcomeRadio": "bienvenueRadio",
  "account": "compte",
  "upgrade": "mise-a-niveau",
  "congratulations": "felicitations",
  "library": "bibliotheque",
  "listening": "ecoutes",
  "search": "recherche",
  "artists": "artistes",
  "gift-card": "cartecadeau",
  "activate": "activer",
}

const translationsToEN = {
  "bienvenue": "welcome",
  "expire": "expired",
  "sans-plan": "noplan",
  "inscription": "registration",
  "confidentialite": "confidentiality",
  "mot-de-passe-perdu": "forgot-password",
  "generation": "generating-new-one",
  "qui-sommes-nous": "who-are-we",
  "s-inscrire": "subscribe",
  "paiement": "checkout",
  "oeuvres": "works",
  "accueil": "home",
  "compositeurs": "composers",
  "bienvenueRadio": "welcomeRadio",
  "compte": "account",
  "mise-a-niveau": "upgrade",
  "felicitations": "congratulations",
  "bibliotheque": "library",
  "ecoutes": "listening",
  "recherche": "search",
  "artistes": "artists",
  "cartecadeau": "gift-card",
  "activer": "activate",
}

const translatedPathname = (lang) => {
  const { pathname } = window.location;
  const pathParts = pathname.split('/');

  const translatedPath = pathParts.map((pathPart) => {
    if(translationsToEN[pathPart] && lang === 'en' ) {
      return translationsToEN[pathPart];
    } else if (translationsToFR[pathPart] && lang === 'fr') {
      return translationsToFR[pathPart];
    } else {
      return pathPart
    }
  });

  return translatedPath.join('/');
};

export default translatedPathname;