/* eslint-disable jsx-a11y/alt-text */
// This component to be used if your image path is coming as a backend API response
// If you want this image to be a sized before you get it on frontend
// If you want your image to be lazyloaded and only retrieved when it's in view
// Please do not use this component for images with static path (where path is not from API)

import React, { useEffect, useRef, useState } from "react";
import getSizedImagePath from "utils/getSizedImagePath";

const EnhancedImage = (props) => {
  const imageRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { size, imageRootUrl, imagePath, fallbackImage, isLazy = true, ...rest } = props;

  const callbackFunc = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) setIsVisible(true);
  };

  useEffect(() => {
    if (!isLazy) return;

    const observer = new IntersectionObserver(callbackFunc);
    const theImage = imageRef.current;
    if (theImage) observer.observe(theImage);

    return () => {
      if (theImage) observer.unobserve(theImage);
    };
  }, [imageRef, isLazy]);



  if (!isLazy) return <img {...rest} src={getSizedImagePath(size, imageRootUrl, imagePath, fallbackImage)} />;

  if (isLazy)
    return (
      <>
        {!isVisible && <span ref={imageRef} />}
        {isVisible && <img {...rest} src={getSizedImagePath(size, imageRootUrl, imagePath, fallbackImage)} />}
      </>
    );
};

export default EnhancedImage;
