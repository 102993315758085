import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { getLangSelector } from "modules/Config/selectors";
import { getUserLikedTracksSelector } from "modules/Library/selectors";
import { dispatchSetUserLikedTracks } from "modules/Library/actions";
import { getMiniPlayerSelector } from "modules/MiniPlayer/selectors";
import axios from "axios";
import notificationManager from "utils/notificationManager";
import { Link } from "react-router-dom";
import { accessRoutes } from "components/ApplicationRoutes/routes";
import { Mixpanel } from "utils/mixpanel";
import { playerSaveTrackTracking, playerUnsaveTrackTracking } from "utils/miniPlayerMixpanelUtils";
import { withTranslation } from "react-i18next";
import api from "utils/apiManager";
import { FAVOURITE_TRACKS_CACHE_KEY } from "utils/cacheKeys";

const TracksHeart = (props) => {
  //isIcon has a copy prop that can be used to add a copy to the Icon
  //this is a result of deviation from initial usage parameters
  const {
    t,
    lang,
    miniPlayer,
    userLikedTracks,
    setUserLikedTracks,
    parent,
    track,
    isMiniPlayerHeart,
    isIcon,
    iconCopy,
    size = "big",
    source,
  } = props;
  const isMiniPlayer = parent === "miniplayer";
  const isMiniPlayerMobile = parent === "miniplayer-mobile";
  const { indexOfTrack, dataTrack } = miniPlayer;
  const LIKE_STR = "like";
  const UNLIKE_STR = "unlike";

  const updateUserLikedTracks = useCallback(
    (trackId, action, track) => {
      let newUserLikedTracks = userLikedTracks ? [...userLikedTracks] : [];
      const trackIndex = newUserLikedTracks.findIndex((track) => track.track_metadata.id === trackId);
      if (action === UNLIKE_STR) {
        newUserLikedTracks.splice(trackIndex, 1);
      } else if (action === LIKE_STR) {
        if (trackIndex < 0) {
          newUserLikedTracks.push(track);
        }
      }
      setUserLikedTracks(newUserLikedTracks);
    },
    [userLikedTracks, setUserLikedTracks]
  );

  const isTrackLiked = (userLikedTracks, song) => {
    const songId = Array.isArray(song) ? song[0] : song;
    const isFound = userLikedTracks && userLikedTracks.some((track) => track.track_metadata.id === songId);
    const resp = Array.isArray(userLikedTracks) && userLikedTracks && isFound;
    return resp;
  };

  const likeTrack = useCallback(
    (songId, url, token, action, track) => {
      const payload = { songs: songId };
      updateUserLikedTracks(songId, action, track);
      axios
        .post(url, payload, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Mixpanel.track("Liked a track", {
            TrackID: songId,
            Universe: window.location.href.indexOf("/jazz") !== -1 ? "Jazz" : "Classical",
            Source: source,
          });
          api.storage.remove(FAVOURITE_TRACKS_CACHE_KEY)
          notificationManager.showMessage(
            "heart",
            <span>
              {t("NOTIFICATION_LIKED_TRACK_PT1")}&nbsp;
              <Link to={{ pathname: accessRoutes[lang].library }}>{t("NOTIFICATION_LIKED_TRACK_PT2_URL")}</Link>
            </span>
          );
        })
        .catch((error) => {
          notificationManager.showMessage("none", t("NOTIFICATION_LIKE_TRACK_FAILURE"));
          updateUserLikedTracks(songId, UNLIKE_STR, track);
        });
    },
    [t, lang, source, updateUserLikedTracks]
  );

  const unLikeTrack = useCallback(
    (songId, url, token, action) => {
      updateUserLikedTracks(songId, action, track);
      axios
        .delete(url, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Mixpanel.track("Unliked a track", {
            TrackID: songId,
            Universe: window.location.href.indexOf("/jazz") !== -1 ? "Jazz" : "Classical",
          });
          notificationManager.showMessage("heart", t("NOTIFICATION_UNLIKED_TRACK"));
        })
        .catch((error) => {
          notificationManager.showMessage("none", t("NOTIFICATION_UNLIKE_TRACK_FAILURE"));
        });
    },
    [t, track, updateUserLikedTracks]
  );

  const likeOrUnlikeTrack = useCallback(
    (action, listSongs, track) => {
      const songId = Array.isArray(listSongs) ? listSongs[0] : listSongs;
      const url = `${process.env.REACT_APP_VIALMA_API}/tracks/${songId}/favourite`;
      const tokenHash = JSON.parse(localStorage.getItem("loginData")).token;
      const token = `Bearer ${tokenHash}`;

      if (action === LIKE_STR) {
        likeTrack(songId, url, token, action, track);
        (isMiniPlayer || isMiniPlayerMobile || isMiniPlayerHeart) && playerSaveTrackTracking();
      } else if (action === UNLIKE_STR) {
        unLikeTrack(songId, url, token, action);
        (isMiniPlayer || isMiniPlayerMobile || isMiniPlayerHeart) && playerUnsaveTrackTracking();
      }
    },
    [likeTrack, unLikeTrack, isMiniPlayer, isMiniPlayerMobile, isMiniPlayerHeart]
  );

  const getLikeUnlikeButton = useCallback(() => {
    const trackObject = (isMiniPlayer || isMiniPlayerMobile) && dataTrack ? dataTrack[indexOfTrack] : track;
    const songId = trackObject ? trackObject.track_metadata.id : "";
    const trackLiked = isTrackLiked(userLikedTracks, songId);

    if (isIcon) {
      return (
        <div className="flex flex-row align-items-center" onClick={() => likeOrUnlikeTrack(`${trackLiked ? "unlike" : "like"}`, songId, trackObject)}>
          <div className={`mc-heart ${size} transparent ${trackLiked ? "clicked" : ""}`} />
          {iconCopy}
        </div>
      );
    }

    if (isMiniPlayer) {
      return (
        <div className="flex flex-row align-items-center font-red" onClick={() => likeOrUnlikeTrack(`${trackLiked ? "unlike" : "like"}`, songId, trackObject)}>
          <div className={`mc-heart miniplayer transparent ${trackLiked ? "clicked" : ""}`} />
          {iconCopy}
        </div>
      );
    }

    if (!isMiniPlayer || isMiniPlayerMobile) {
      return (
        <li onClick={() => likeOrUnlikeTrack(`${trackLiked ? "unlike" : "like"}`, songId, trackObject)}>
          <div className={`mc-heart dropdown mr-10 transparent ${trackLiked ? "clicked" : ""}`} />
          {t(trackLiked ? "MENUMORE_UNLIKE_TRACK" : "MENUMORE_LIKE_TRACK")}
        </li>
      );
    }
    return null;
  }, [track, dataTrack, indexOfTrack, t, userLikedTracks, isMiniPlayerMobile, isMiniPlayer, isIcon, iconCopy, size, likeOrUnlikeTrack]);

  useEffect(() => {
    getLikeUnlikeButton();
  }, [getLikeUnlikeButton]);

  return <React.Fragment>{getLikeUnlikeButton()}</React.Fragment>;
};

const mapStateToProps = (state) => ({
  lang: getLangSelector(state),
  userLikedTracks: getUserLikedTracksSelector(state),
  miniPlayer: getMiniPlayerSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  setUserLikedTracks: (userLikedTracks) => {
    dispatch(dispatchSetUserLikedTracks(userLikedTracks));
  },
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TracksHeart));
