import React, { Component, Suspense } from "react";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import reducers from "reducers";
import ContainerApp from "components/ContainerApp";
import i18n from "./i18n";
import "styles/style.scss";
import { I18nextProvider } from "react-i18next";
import SmallLoader from "components/SmallLoader";
import { TourProvider } from "@reactour/tour";
import { isHeaderJazzUniverse } from "utils/storeUtils";
import { COLOUR_BLUE, COLOUR_RED } from "utils/nameVariables";
import { Mixpanel } from "utils/mixpanel";
import { FROM_WELCOME, TOUR_FINAL_STEP_REACHED, TOUR_HEART_CLICKED } from "utils/localStoreKeys";
import { toggleCrispChatVisibility } from "utils/tools";

const devToolsParam = () => {
  const allowedDomains = ["http://localhost", "http://localhost:3000", "https://web2-stage.vialma.com", "https://pre-prod.vialma.com"];
  return allowedDomains.includes(process.env.REACT_APP_DOMAIN);
};

const store = configureStore({ reducer: reducers, devTools: devToolsParam() });

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Suspense fallback={<SmallLoader isPage />}>
          <I18nextProvider i18n={i18n}>
            <TourProvider
              styles={{
                popover: (base) => ({
                  ...base,
                  "--reactour-accent": isHeaderJazzUniverse(true) ? COLOUR_BLUE : COLOUR_RED,
                  borderRadius: 10,
                }),
                maskArea: (base) => ({ ...base, rx: 10 }),
                maskWrapper: (base) => ({ ...base, color: isHeaderJazzUniverse(true) ? "#4866e038" : "#FEE8E8" }),
                badge: (base) => ({ ...base, right: "auto", left: "-0.8125em" }),
                close: (base) => ({ ...base, left: "auto", right: 8, top: 8 }),
                controls: (base) => ({ ...base, marginTop: 10 }),
              }}
              badgeContent={({ currentStep, totalSteps }) => `${currentStep + 1}/${totalSteps}`}
              disableDotsNavigation={true}
              onClickHighlighted={(e) => {
                e.stopPropagation();
              }}
              onClickMask={(e) => {}}
              afterOpen={(e) => {
                toggleCrispChatVisibility(false);
                localStorage.removeItem(FROM_WELCOME);
                localStorage.removeItem(TOUR_HEART_CLICKED);
                localStorage.removeItem(TOUR_FINAL_STEP_REACHED);
                Mixpanel.track("Feature tour launched", {
                  campaign_name: "Liking feature tour",
                  completed: "no",
                });
              }}
              beforeClose={(e) => {
                toggleCrispChatVisibility(true);
                Mixpanel.track("Feature tour completed", {
                  campaign_name: "Liking feature tour",
                  completed: localStorage.getItem(TOUR_FINAL_STEP_REACHED) ? "yes" : "no",
                });
                localStorage.removeItem(TOUR_FINAL_STEP_REACHED);
              }}
              nextButton={({ Button, currentStep, stepsLength, setIsOpen, setCurrentStep }) => {
                return currentStep + 1 === stepsLength ? (
                  <div
                    className="button-red slim"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    {i18n.t("TOUR_GOT_IT")}
                  </div>
                ) : (
                  <span
                    className="flex has-link underline"
                    onClick={() => {
                      //if first step, trigger heart click when next button is clicked
                      //navigation to next step is handled in miniplayer
                      if (currentStep === 0) {
                        document.querySelectorAll(".mc-heart.miniplayer")[0].click();
                        localStorage.setItem(TOUR_HEART_CLICKED, true);
                      }
                      // if second step , go to next step when next button is clicked
                      currentStep === 1 && setCurrentStep(2);
                    }}
                  >
                    {i18n.t("TOUR_NEXT")}
                    <Button className="has-link underline"></Button>
                  </span>
                );
              }}
            >
              <ContainerApp />
            </TourProvider>
          </I18nextProvider>
        </Suspense>
      </Provider>
    );
  }
}

if (window.Cypress) {
  window.store = store;
}

export default App;
export { store };
