import { fetchLang, isLoggedIn, isHeaderJazzUniverse } from "../../storeUtils";
import api from "utils/apiManager";
import { CLASSICAL_NAME, DEFAULT_MEDIA_ROW_COUNT, JAZZ_NAME } from "utils/nameVariables";
import { getAlsoLoveUrl, getCollectionContentForHome, getPersonalRecommendationsUrl, getPopularAlbums, getPopularPlaylists, getUserPlaylistsHomeUrl, getWelcomeBannerUrl, jumpBackInUrl } from "../urls";
import axios from "axios";
import orderBy from "lodash/orderBy";
import { getHeaders } from "..";


//API Calls
export const apiLibraryGetWelcomeBanner = async () => {
  const lang = fetchLang();
  const isLogged = isLoggedIn();
  const headers = getHeaders();
  const universe = isHeaderJazzUniverse() ? JAZZ_NAME : CLASSICAL_NAME;

  if (!isLogged) return;
  const welcomeBannerUrl = getWelcomeBannerUrl(lang, universe);
  try {
    const resp = await api({ url: welcomeBannerUrl, headers });
    return resp;
  } catch (e) {
    return null;
  }
};


export const apiLibraryGetUserPlaylistsHome = async (count = DEFAULT_MEDIA_ROW_COUNT) => {
  const headers = getHeaders();
  const lang = fetchLang();
  const getUserPlaylsitsHomeUrl = getUserPlaylistsHomeUrl(count, lang);

  try {
    const resp = await axios.get(getUserPlaylsitsHomeUrl, { headers });
    if (resp.status === 200) {
      const sortedPlaylists = orderBy(resp.data.playlists, (item) => Date.parse(item.createdAt.date.substring(0, 10)), ["desc"]);
      return sortedPlaylists;
    }
  } catch (error) {
    return [];
  }
};
export const apiLibraryGetUserJumpBackIn = async (limit) => {
  const lang = fetchLang();
  const isLogged = isLoggedIn();
  const headers = getHeaders();
  const universe = isHeaderJazzUniverse() ? JAZZ_NAME : CLASSICAL_NAME;

  if (!isLogged) return;
  const jumpBackIn = jumpBackInUrl(lang, universe, limit);
  try {
    const resp = await api({ url: jumpBackIn, headers });
    return resp.data.data.items;
  } catch (e) {
    return [];
  }
};

export const apiLibraryGetPopularAlbums = async (limit = DEFAULT_MEDIA_ROW_COUNT) => {
  const lang = fetchLang();
  const isLogged = isLoggedIn();
  const headers = getHeaders();
  const universe = isHeaderJazzUniverse() ? JAZZ_NAME : CLASSICAL_NAME;

  if (!isLogged) return;
  const popularAlbumsUrl = getPopularAlbums(lang, universe, limit);
  try {
    const resp = await api({ url: popularAlbumsUrl, headers });
    return resp.data.data;
  } catch (e) {
    return [];
  }
};

export const apiLibraryGetPopularPlaylists = async (limit = DEFAULT_MEDIA_ROW_COUNT) => {
  const lang = fetchLang();
  const isLogged = isLoggedIn();
  const headers = getHeaders();
  const universe = isHeaderJazzUniverse() ? JAZZ_NAME : CLASSICAL_NAME;

  if (!isLogged) return;
  const popularPlaylistsUrl = getPopularPlaylists(lang, universe, limit);
  try {
    const resp = await api({ url: popularPlaylistsUrl, headers });
    return resp.data.data;
  } catch (e) {
    return [];
  }
};

export const apiLibraryGetAlsoLove = async (limit) => {
  const lang = fetchLang();
  const isLogged = isLoggedIn();
  const headers = getHeaders();
  const universe = isHeaderJazzUniverse() ? JAZZ_NAME : CLASSICAL_NAME;

  if (!isLogged) return;
  const alsoLove = getAlsoLoveUrl(lang, universe, limit);
  try {
    const resp = await api({ url: alsoLove, headers });
    return resp.data.data;
  } catch (e) {
    return [];
  }
};

export const apiLibraryGetPersonalRecommendations = async (limit) => {
  const lang = fetchLang();
  const isLogged = isLoggedIn();
  const headers = getHeaders();
  const universe = isHeaderJazzUniverse() ? JAZZ_NAME : CLASSICAL_NAME;

  if (!isLogged) return;
  const personalRecommendationsUrl = getPersonalRecommendationsUrl(lang, universe, limit);
  try {
    const resp = await api({ url: personalRecommendationsUrl, headers });
    return resp.data.data;
  } catch (e) {
    return [];
  }
};

export const apiLibraryGetCollectionContentForHome = async () => {
  const lang = fetchLang();
  const isLogged = isLoggedIn();
  const headers = getHeaders();
  const universe = isHeaderJazzUniverse() ? JAZZ_NAME : CLASSICAL_NAME;

  if (!isLogged) return;
  const collectionContentForHomeUrl = getCollectionContentForHome(lang, universe);
  try {
    const resp = await api({ url: collectionContentForHomeUrl, headers });
    return resp.data.data[0];
  } catch (e) {
    return [];
  }
};
