import has from "lodash/has";

const CLASSICAL = "Classical";
const JAZZ = "Jazz";

const isSubClassical = (subscription) => {
  if (!has(subscription, "subscription.universes")) return null; // this should never happen
  return subscription.subscription.universes.includes(CLASSICAL);
};

const isSubJazz = (subscription) => {
  if (!has(subscription, "subscription.universes")) return null;
  return subscription.subscription.universes.includes(JAZZ);
};

const isSubClassicalOnly = (subscription) => {
  if (!has(subscription, "subscription.universes")) return null;
  return subscription.subscription.universes.includes(CLASSICAL) && subscription.subscription.universes.length === 1;
};

const isSubJazzOnly = (subscription) => {
  if (!has(subscription, "subscription.universes")) return null;
  return subscription.subscription.universes.includes(JAZZ) && subscription.subscription.universes.length === 1;
};

const isSubBothUniverse = (subscription) => {
  if (!has(subscription, "subscription.universes")) return null;
  return subscription.subscription.universes.includes(JAZZ) && subscription.subscription.universes.includes(CLASSICAL);
};

export { isSubJazz, isSubClassical, isSubClassicalOnly, isSubJazzOnly, isSubBothUniverse };
