import produce from "immer";
import { SET_VIDEO_DETAILS } from "./constants";

const initialState = {
  videoDetails: null,
};

const videosReducer = function (state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_VIDEO_DETAILS:
        draft.videoDetails = action.videoDetails;
        break;
      default:
        return;
    }
  });
}

export default videosReducer;