import { TOUR_FINAL_STEP_REACHED } from "./localStoreKeys";

export const getTourSteps = (t, isMobile) => [
  {
    selector: ".mc-heart.miniplayer",
    position: "top",
    content: ({ currentStep, setCurrentStep }) => {
      return (
        <div className="flex flex-column justify-center text-center">
          <p className="mb-5 bold">{t("LIKING_TOUR_STEP_1_TITLE")}</p>
          <p className="mb-5">{t("LIKING_TOUR_STEP_1_BODY")}</p>
        </div>
      );
    },
    mutationObservables: [".miniplayer"],
  },
  {
    selector: `${isMobile ? "[data-tour='library']" : ".item.library > a"}`,
    position: `${isMobile ? "top" : "bottom"}`,
    content: ({ setIsOpen }) => {
      return (
        <div className="flex flex-column justify-center text-center">
          <p className="mb-5 bold">{t("LIKING_TOUR_STEP_2_TITLE")}</p>
          <p className="mb-5">{t("LIKING_TOUR_STEP_2_BODY")}</p>
        </div>
      );
    },
  },
  {
    selector: "#library-tab-liked_tracks",
    position: "top",
    content: ({ setIsOpen }) => {
      return (
        <div className="flex flex-column justify-center text-center">
          <p className="mb-5 bold">{t("LIKING_TOUR_STEP_3_TITLE")}</p>
          <p className="mb-5">{t("LIKING_TOUR_STEP_3_BODY")}</p>
        </div>
      );
    },
    action: () => {
      localStorage.setItem(TOUR_FINAL_STEP_REACHED, true);
    },
    mutationObservables: [".miniplayer, #library-tab-liked_tracks"],
  },
];
