import React, { forwardRef } from "react";
import getAbsolutePath from "utils/getAbsolutePath";
import { Link } from "react-router-dom";
import InteractivePlayIcon from "components/InteractivePlayIcon";
import { withTranslation } from "react-i18next";
import { accessRoutes } from "components/ApplicationRoutes/routes";
import defaultPlaylistImage from "assets/images/notes_grey_bg.svg";
import iconRemove from "assets/images/remove.svg";
import { getLangSelector, getLoginSelector } from "modules/Config/selectors";
import { getPlaylistsSelector, getVialmaPlaylistsSelector } from "modules/Library/selectors";
import { dispatchSetPlaylists, dispatchSetVialmaPlaylists } from "modules/Library/actions";
import { connect } from "react-redux";
import { ALBUM, CHAPTER, ARTICLE, COLLECTION, PLAYLIST, PODCAST, VIDEO, ARTIST } from "utils/nameVariables";
import time from "assets/images/time.svg";
import podcastDefault from "assets/images/default_podcast.png";
import InteractivePlayIconAlbum from "components/InteractivePlayIconAlbum";
import { getAlbumDetails, getAlbumDisplayArtist, getAlbumSlug, getAritstString } from "utils/metaDataTools";
import star from "assets/images/star.svg";
import mediaTypeTranslations from "utils/getMediaTypeTranslation";
import { playTrackingFunction } from "utils/tools";
import EnhancedImage from "components/EnhancedImage";
import MediaFavouriteContainer from "components/MediaFavouriteContainer";

const createPlaylistImageComponent = (playlist, componentType) => {
  const imageArray = playlist.imagePaths;
  const imageRootUrl = playlist.imageRootUrl;
  const noOfImages = imageArray ? imageArray.length : 0;
  let podcastOrChapterImage;

  switch (componentType) {
    case PODCAST:
      podcastOrChapterImage = playlist.smallLogoUrl || playlist.image_url || podcastDefault;
      break;
    case CHAPTER:
      podcastOrChapterImage = playlist.smallLogoUrl || playlist.image_url || podcastDefault;
      break;
    default:
      break;
  }

  let component;
  if (noOfImages > 0) {
    component = (
      <div className={`children-${noOfImages}`}>
        {imageArray.map((imagePath, index) => (
          <EnhancedImage
            size="medium"
            imageRootUrl={imageRootUrl}
            imagePath={imagePath}
            fallbackImage={defaultPlaylistImage}
            alt={`${playlist.album_title} ${index}`}
            className={`image-${index + 1}`}
            key={index}
          />
        ))}
      </div>
    );
  } else {
    component = (
      <div className={`children-1 default`}>
        {componentType === PODCAST || componentType === CHAPTER ? (
          <img src={podcastOrChapterImage} alt={"default"} className="image-1 default" />
        ) : (
          <EnhancedImage
            size="medium"
            imageRootUrl={playlist?.imageRootUrl || playlist?.imageUrls?.imageRootUrl}
            imagePath={playlist?.imagePath || playlist?.imageUrls?.imagePath}
            fallbackImage={defaultPlaylistImage}
            alt={playlist?.name || componentType}
            className={"image-1 default"}
          />
        )}
      </div>
    );
  }

  return component;
};

const getProps = (sourceProps) => {
  const { isUserPlaylistProp, playlist, media, isJazz = false, lang, playlists, pageOrigin, type = PLAYLIST } = sourceProps;
  let isUserPlaylist = false;
  let id;
  let name;
  let description;
  let subDescription;
  let url;
  let image_url;
  let pageOriginProp;
  let showPlayIcon = true;
  let showHeart = true;

  switch (type) {
    case PODCAST:
      const { id: podcastId, name: podcastName, title: podcastNameAlt } = media;
      showPlayIcon = false;
      isUserPlaylist = false;
      id = podcastId;
      name = podcastName || podcastNameAlt;
      showHeart = false;
      url = `${isJazz ? accessRoutes[lang].podcastDetailsJazz : accessRoutes[lang].podcastDetails}/${podcastId}`;
      break;
    case CHAPTER:
      const { id: chapterId, name: chapterName, title: chapterNameAlt, podcast_name: chapterPodcastName } = media;
      showPlayIcon = false;
      isUserPlaylist = false;
      id = chapterId;
      name = chapterName || chapterNameAlt;
      description = chapterPodcastName;
      showHeart = false;
      url = `${isJazz ? accessRoutes[lang].chapterDetailsJazz : accessRoutes[lang].chapterDetails}/${chapterId}`;
      break;
    case VIDEO:
      const {
        id: videoId,
        videoId: videoIdAlt,
        short_name: videoTitle,
        title: videoTitleAlt,
        long_name: videoDescription,
        longName: videoDescriptionAlt,
        excerpt: videoDescriptionAlt2,
        duration,
        length,
        url: videoUrl,
        slug: videoUrlAlt,
        slugs: videoSlugs,
      } = media;
      showPlayIcon = false;
      isUserPlaylist = false;
      name = videoTitle || videoTitleAlt;
      description = videoDescription || videoDescriptionAlt || videoDescriptionAlt2;
      subDescription = duration || length || "--";
      url = `${isJazz ? accessRoutes[lang].jazzVideoDetails : accessRoutes[lang].videoDetails}/${videoId || videoIdAlt}/${
        videoSlugs?.[lang] || videoUrl || videoUrlAlt
      }`;
      id = videoId;
      showHeart = true;
      break;
    case ARTICLE:
      const { id: articleId, newsId: artilceIdAlt, title: articleTitle, url: articleUrl, excerpt, slugs: articleSlugs, slug: articleSlug } = media;
      showPlayIcon = false;
      name = articleTitle;
      isUserPlaylist = false;
      description = excerpt;
      id = articleId || artilceIdAlt;
      url = `${isJazz ? accessRoutes[lang].jazzArticleDetails : accessRoutes[lang].articleDetails}/${id}/${
        articleSlugs?.[lang] || articleUrl || articleSlug
      }`;
      showHeart = false;
      break;
    case COLLECTION:
      const {
        id: collectionId,
        title: collectionTitle,
        titles: collectionTitles,
        name: collectionName,
        slug: collectionSlug,
        slugs: collectionSlugs,
      } = media;
      showPlayIcon = false;
      name = collectionTitles?.[lang] || collectionTitle || collectionName;
      isUserPlaylist = false;
      const collectionSlugStr = collectionSlugs?.[lang] ?? collectionSlug;
      url = `${isJazz ? accessRoutes[lang].jazzPartnerPage : accessRoutes[lang].classicalPartnerPage}/${collectionSlugStr}`;
      id = collectionId;
      showHeart = true;
      break;
    case ALBUM:
      const { id: albumId, name: albumTitle, title: albumTitleAlt, artists: albumArtists, release_year, releaseYear } = getAlbumDetails(media);
      const albumSlug = getAlbumSlug(media);
      const displayArtist = getAlbumDisplayArtist(media);
      name = albumTitle || albumTitleAlt;
      isUserPlaylist = false;
      url = `${isJazz ? accessRoutes[lang].jazzAlbumsDetails : accessRoutes[lang].classicalAlbumsDetails}/${albumId}/${albumSlug}`;
      id = albumId;
      description = displayArtist ? `by ${displayArtist}` : null;
      subDescription = getAritstString(albumArtists) || release_year || releaseYear;
      showHeart = true;
      break;
    case ARTIST:
      const { id: artistId, title: artistTitle, slug: artistSlug, isJazz: isArtistJazz } = media;
      name = artistTitle;
      isUserPlaylist = false;
      showPlayIcon = false;
      url = `${isArtistJazz ? accessRoutes[lang].jazzArtistsDetails : accessRoutes[lang].personPage}/${artistId}/${artistSlug}`;
      id = artistId;
      showHeart = false;
      break;
    case PLAYLIST:
    default:
      const { id: playlistId, radioId: playlistIdAlt, name: titleAlt, title, short_name, album_title, slug, url: slugAlt, slugs } = playlist;
      showPlayIcon = true;
      const localPlaylistId = playlistId || playlistIdAlt;
      isUserPlaylist = (playlists && playlists.some((playlist) => parseInt(playlist.id) === localPlaylistId)) || isUserPlaylistProp;
      name = title || short_name || album_title || titleAlt;
      const slugStr = slugs ? slugs[lang] : slug ? slug : slugAlt ? slugAlt : "";
      url = isUserPlaylist
        ? `${accessRoutes[lang].libraryPlaylistDetails}/${localPlaylistId}`
        : isJazz
        ? `${accessRoutes[lang].jazzPlaylistDetails}/${localPlaylistId}/${slugStr}`
        : `${accessRoutes[lang].playlistDetails}/${localPlaylistId}/${slugStr}`;
      pageOriginProp = isUserPlaylist ? "user_playlist" : pageOrigin ? pageOrigin : "playlist";
      id = localPlaylistId;
  }

  return {
    isUserPlaylist,
    id,
    name,
    description,
    subDescription,
    url,
    image_url,
    pageOriginProp,
    showPlayIcon,
    showHeart,
  };
};

const MediaCardComponent = forwardRef((props, ref) => {
  const {
    //ESSENTIAL
    t,
    playlist,
    media,
    isJazz = false,
    index,
    lang,
    type = PLAYLIST,
    //FOR EDITING
    editMode,
    removePlaylist,
    indexCurrentPlayList,
    textCurrentPlayList,
    handleChangeCurrent,
    saveEditPlayList,
    editPlayList,
    // MISC
    handleClick = () => {},
    isFeatured,
    labelProps,
    isMixedCard,
    showType,
    showDescription = true,
    isLogged,
  } = props;

  const { isUserPlaylist, id, name, description, subDescription, url, pageOriginProp, showPlayIcon, showHeart } = getProps(props);

  let playIcon;
  switch (type) {
    case VIDEO:
      playIcon = (
        <div className="play-video">
          <img alt="play" />
        </div>
      );
      break;
    case ALBUM:
      playIcon = showPlayIcon ? (
        <InteractivePlayIconAlbum
          albumId={id}
          isJazz={isJazz}
          pageOrigin={pageOriginProp}
          playTrackingFunction={(e) => playTrackingFunction(e, url)}
          mediaProps={{
            type: ALBUM,
            name: name, 
            url: url,
          }}
        />
      ) : null;
      break;
    default:
      playIcon = showPlayIcon ? (
        <InteractivePlayIcon
          playlistId={id}
          isJazz={isJazz}
          pageOrigin={pageOriginProp}
          isUserPlaylist={isUserPlaylist}
          playTrackingFunction={(e) => playTrackingFunction(e, url)}
          mediaProps={{ type, name, url }}
        />
      ) : null;
  }

  return (
    <Link
      ref={ref}
      className={`media-card ${type === VIDEO && !isMixedCard ? "video-card" : ""} ${isJazz ? "jazz" : ""} ${isMixedCard ? "mixed-card" : ""}`}
      to={url}
      key={id}
      onClick={(e) => {
        if (editMode) {
          e.stopPropagation();
          e.preventDefault();
        } else {
          handleClick(url);
        }
      }}
    >
      {isFeatured && <span className={`label ${isJazz ? "blue" : ""}`}>{t("PERSON_PAGE_RECENT_ALBUMS_FEATURED")}</span>}
      {!isFeatured && labelProps && <span className={`label ${labelProps?.colour}`}>{labelProps?.text}</span>}
      {isFeatured && type === ALBUM && (
        <div className="star-container">
          <img className="star" src={star} alt={"star"} />
        </div>
      )}
      {editMode && (
        <img
          className="icon-remove"
          src={getAbsolutePath(iconRemove)}
          alt=""
          onClick={() => removePlaylist(index, id)}
          data-cy={`remove_playlist_${id}`}
        />
      )}
      <div className="images_container interactive-play-icon-wrapper">
        {createPlaylistImageComponent(playlist || media, type)}
        {playIcon}
      </div>
      <div
        className="title"
        onClick={(e) => {
          if (editMode) {
            e.stopPropagation();
            e.preventDefault();
          }
        }}
      >
        {editMode && index === indexCurrentPlayList ? (
          <React.Fragment>
            <input value={textCurrentPlayList} onChange={handleChangeCurrent} type="text" />
            <div className="button-save-edit" onClick={() => saveEditPlayList(id)}>
              {t("MY_LIBRARY_PLAYLISTS_SAVE")}
            </div>
          </React.Fragment>
        ) : (
          <div className="title-text truncate tiny">{name}</div>
        )}

        {!isUserPlaylist && showHeart && (
          <MediaFavouriteContainer mediaId={id} type={type} lang={lang} t={t} isLogged={isLogged} showNotification={true} heartSize={"big"} />
        )}
        {isUserPlaylist && editMode && index !== indexCurrentPlayList && (
          <span className="edit-button" onClick={() => editPlayList(index, name, id)}>
            {t("MY_LIBRARY_PLAYLISTS_EDIT")}
          </span>
        )}
      </div>
      {showDescription && description && (
        <div className="description">
          <div className="description-text-mobile truncate short my-5">{description}</div>
        </div>
      )}
      {showDescription && subDescription && (
        <span className={`description text-grey`}>
          {type === VIDEO && (
            <>
              <img src={getAbsolutePath(time)} alt="" /> &nbsp;
            </>
          )}
          <span className={`description-text  ${type === ALBUM ? "truncate short" : ""}`}>{subDescription}</span>
        </span>
      )}
      {showType && <div className={"content-type-text"}>{t(mediaTypeTranslations(type))}</div>}
    </Link>
  );
});

const mapStateToProps = (state) => ({
  lang: getLangSelector(state),
  playlists: getPlaylistsSelector(state),
  vialmaPlaylists: getVialmaPlaylistsSelector(state),
  isLogged: getLoginSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  setPlaylists: (playlists) => {
    dispatch(dispatchSetPlaylists(playlists));
  },
  setVialmaPlaylists: (playlists) => {
    dispatch(dispatchSetVialmaPlaylists(playlists));
  },
});

export default withTranslation(null, { withRef: true })(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MediaCardComponent));
