import React from "react";
import LoadingBubble from "components/LoadingBubble";
import { useSelector } from "react-redux";
import { COLOUR_BLUE, COLOUR_RED } from "utils/nameVariables";

const SmallLoader = ({ color, isPage }) => {
  const isJazzSelected = useSelector((state) => state.config.isJazzSelected) || window.location.href.indexOf("/jazz") !== -1;
  const loaderColor = color ? color : isJazzSelected ? COLOUR_BLUE : COLOUR_RED;

  return (
    <div className={`${isPage ? "loading-screen" : "loading-icon-centered"}`}>
      <LoadingBubble color={loaderColor} />
    </div>
  );
};

export default SmallLoader;
