import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { ReactComponent as ErrorPageImage } from "assets/images/errorPageImg.svg";
import { ReactComponent as PhoneIcon } from "assets/images/icon_phone.svg";
import { ReactComponent as MailIcon } from "assets/images/icon_mail.svg";
import { ReactComponent as InstantMessagingIcon } from "assets/images/icon_instant_messaging.svg";
import * as Sentry from "@sentry/react";
import { Mixpanel } from "utils/mixpanel";
import { getLoginSelector } from "modules/Config/selectors";
import { Helmet } from "react-helmet";


const ErrorPage = ({ t, isLogged }) => {
  const goBackHome = () => {
    const isJazz = window.location.pathname.indexOf("/jazz") !== -1;
    const homeUrl = isJazz ? "/jazz/home" : "home";
    window.location.pathname = homeUrl;
  };

  useEffect(() => {
    if (isLogged) {
      Sentry.captureException("Error - Something went wrong");
      Mixpanel.track("Error - Something went wrong");
    }
  }, [isLogged]);

  return (
    <div className="ErrorPage">
      <Helmet>
        <meta name="render:status_code" content="404" />
      </Helmet>
      <div className="ErrorPage__TopSection">
        <div className="ErrorPage__TopSection__ImageContainer">
          <ErrorPageImage />
        </div>
        <h1 className="ErrorPage__TopSection__Heading">
          {t("SOMETHING_WENT_WRONG_TITLE_1")}
          <br />
          {t("SOMETHING_WENT_WRONG_TITLE_2")}
        </h1>

        <button className="button-red ErrorPage__TopSection__Button" onClick={goBackHome}>
          {t("SOMETHING_WENT_WRONG_CTA_1")}
        </button>
      </div>
      <div className="ErrorPage__ContactSection">
        <p className="ErrorPage__ContactSection__Copy">{t("SOMETHING_WENT_WRONG_CONTACT_COPY")}</p>

        <div className="ErrorPage__ContactSection__ContactInfo">
          <p>
            <MailIcon />
            {t("SOMETHING_WENT_WRONG_CONTACT_EMAIL")}
          </p>
          <p>
            <PhoneIcon />
            {t("SOMETHING_WENT_WRONG_CONTACT_PHONE_EN")}
          </p>
          <p>
            <PhoneIcon />
            {t("SOMETHING_WENT_WRONG_CONTACT_PHONE_FR")}
          </p>
          <p>
            <InstantMessagingIcon />
            {t("SOMETHING_WENT_WRONG_CONTACT_INSTANT_MSG")}
          </p>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  isLogged: getLoginSelector(state),
});
export default withTranslation()(connect(mapStateToProps)(withRouter(ErrorPage)));
