import produce from "immer";
import {
  SET_JAZZ_PLAYLISTS,
  SET_JAZZ_PLAYLISTS_SONGS,
  RESET_JAZZ_PLAYLISTS,
  SET_LANG_JAZZ_PLAYLISTS,
  SET_JAZZ_PLAYLISTS_DEFAULTS,
} from "./constants";

const initialState = {
  playlists: null,
  songs: null,
  langPlaylists: "en",
  lastPage: null,
};

const jazzPlaylistReducer = function (state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_JAZZ_PLAYLISTS:
        draft.playlists = action.playlists;
        break;
      case SET_JAZZ_PLAYLISTS_SONGS:
        draft.songs = action.songs;
        break;
      case RESET_JAZZ_PLAYLISTS:
        draft.playlists = null;
        draft.songs = null;
        break;
      case SET_LANG_JAZZ_PLAYLISTS:
        draft.langPlaylists = action.langPlaylists;
        break;
      case SET_JAZZ_PLAYLISTS_DEFAULTS:
        return initialState;
      default:
        return;
    }
  });
}

export default jazzPlaylistReducer;