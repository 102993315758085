import {
  SET_USER_COUNTRY,
  UPDATE_ACCOUNT,
} from './constants';

export const dispatchUpdateAccount = account => ({
  type: UPDATE_ACCOUNT,
  account,
});

export const dispatchSetUserCountry = userCountry => ({
  type: SET_USER_COUNTRY,
  userCountry,
});