import { setupCache, buildWebStorage, defaultKeyGenerator } from "axios-cache-interceptor";
import axios from "axios";
import { store } from "App.js";
import { dispatchDecreaseAxiosLiveCalls, dispatchIncreaseAxiosLiveCalls } from "modules/Config/actions";

const interpretHeaderFunc = (headers) => {
  // refer to https://axios-cache-interceptor.js.org/#/pages/global-configuration?id=headerinterpreter
  return 1 * 60 * 60 * 1000; // 1h
};

const fromSessionStorage = buildWebStorage(sessionStorage, "v-cache-session:");

const api = setupCache(axios.create(), {
  storage: fromSessionStorage,
  generateKey: defaultKeyGenerator,
  headerInterpreter: interpretHeaderFunc,
  debug: undefined,
});

api.interceptors.request.use(
  (config) => {
    store.dispatch(dispatchIncreaseAxiosLiveCalls());
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    store.dispatch(dispatchDecreaseAxiosLiveCalls());
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
