import { IS_HEADER_JAZZ } from "utils/localStoreKeys";
import {
  UPDATE_LANG,
  UPDATE_LOGIN,
  SET_OVERFLOW,
  UPDATE_SUBSCRIPTION,
  UPDATE_SHOW_FREE_TRIAL,
  UPDATE_SHOW_CLASSIC_MODAL,
  UPDATE_ADVENT_DATA_CLASSIC,
  UPDATE_ADVENT_DATA_JAZZ,
  UPDATE_UNIVERSE_SELECTION,
  UPDATE_SHOW_SIGNUP_FREE_TRIAL_MODAL,
  TOGGLE_FLAC_STREAM,
  SET_FLAC_ISENABLED,  
  INCREASE_AXIOS_LIVE_CALLS,
  DECREASE_AXIOS_LIVE_CALLS,
  UPDATE_SHOW_OFFER_MODAL,
  CHECK_SUBSCRIPTION_UPDATE,
  SET_FLAC_STREAM,
} from "./constants";

export const dispatchUpdatelang = (lang) => ({
  type: UPDATE_LANG,
  lang,
});

export const dispatchLogin = (isLogged) => ({
  type: UPDATE_LOGIN,
  isLogged,
});

export const dispatchOverflow = (overflow) => ({
  type: SET_OVERFLOW,
  overflow,
});

export const dispatchUpdateSubscription = (subscription) => ({
  type: UPDATE_SUBSCRIPTION,
  subscription,
});

export const dispatchUpdateShowFreeTrial = (showFreeTrial) => ({
  type: UPDATE_SHOW_FREE_TRIAL,
  showFreeTrial,
});

export const dispatchUpdateShowClassicModal = (showClassicModal) => ({
  type: UPDATE_SHOW_CLASSIC_MODAL,
  showClassicModal,
});

export const dispatchShowSignupFreeTrialModal = (showSignupFreeTrialModal, showSignupFreeTrialModalHeader) => ({
  type: UPDATE_SHOW_SIGNUP_FREE_TRIAL_MODAL,
  showSignupFreeTrialModal,
  showSignupFreeTrialModalHeader,
});

export const dispatchUpdateAdventDataClassic = (adventData, adventLang) => ({
  type: UPDATE_ADVENT_DATA_CLASSIC,
  adventData,
  adventLang,
});

export const dispatchUpdateAdventDataJazz = (adventData, adventLang) => ({
  type: UPDATE_ADVENT_DATA_JAZZ,
  adventData,
  adventLang,
});

export const dispatchUpdateUniverseSelection = (isJazz) => {
  localStorage.setItem(IS_HEADER_JAZZ, isJazz);
  return {
    type: UPDATE_UNIVERSE_SELECTION,
    isJazz,
  };
};

export const dispatchToggleFlacStream = () => ({
  type: TOGGLE_FLAC_STREAM,
});

export const dispatchSetFlacStream = (isFlac) => ({
  isFlacStream: isFlac,
  type: SET_FLAC_STREAM,
});

export const dispatchUpdateIsFlacEnabled = (isFlacAllowed) => ({
  type: SET_FLAC_ISENABLED,
  isFlacAllowed,
});

export const dispatchIncreaseAxiosLiveCalls = () => ({
  type: INCREASE_AXIOS_LIVE_CALLS,
});

export const dispatchDecreaseAxiosLiveCalls = () => ({
  type: DECREASE_AXIOS_LIVE_CALLS,
});

export const dispatchUpdateShowOfferModal = (showOfferModal) => ({
  type: UPDATE_SHOW_OFFER_MODAL,
  showOfferModal,
});

export const dispatchCheckSubscriptionUpdate = (checkSubscriptionUpdate) => ({
  type: CHECK_SUBSCRIPTION_UPDATE,
  checkSubscriptionUpdate,
});
