import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import playRadio from "assets/images/play_radio.svg";
import jazzPlayRadio from "assets/images/jazz_play_radio.svg";
import classicalPauseIcon from "assets/images/pause_red.svg";
import jazzPauseIcon from "assets/images/pause_blue.svg";
import getAbsolutePath from "utils/getAbsolutePath";
import { dispatchUpdateMiniPlayer } from "modules/MiniPlayer/actions";
import { isAllowedToPlay } from "utils/checkHasAccess";
import { withRouter } from "react-router";
import { getLangSelector } from "modules/Config/selectors";
import { getMiniPlayerSelector } from "modules/MiniPlayer/selectors";
import { getIsCurrentlyPlayingOrFetching } from "utils/storeUtils";
import { apiLibraryGetAlbumDetails } from "utils/apiLibrary/albums";
import { PAGE_ORIGIN_ALBUM } from "utils/nameVariables";

const InteractivePlayIconAlbums = ({ albumId, isJazz = true, miniPlayer, updateMiniPlayer, playTrackingFunction = () => {}, mediaProps }) => {
  const [isInitialising, setInitialising] = useState(false);

  const playIcon = isJazz ? getAbsolutePath(jazzPlayRadio) : getAbsolutePath(playRadio);
  const pauseIcon = isJazz ? getAbsolutePath(jazzPauseIcon) : getAbsolutePath(classicalPauseIcon);

  const { isCurrentlyPlaying, isCurrentlyLoading } = getIsCurrentlyPlayingOrFetching(albumId);

  const playAlbum = async (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isCurrentlyPlaying) {
      setInitialising(true);
      playTrackingFunction(e);
    }
    if (isCurrentlyPlaying) {
      updateMiniPlayer({ playing: false });
      return;
    }
    const albumDetails = await apiLibraryGetAlbumDetails(id, isJazz);
    const albumTracks = albumDetails?.tracks;
    if (isAllowedToPlay(albumTracks?.[0])) {
      updateMiniPlayer({
        url: "blank",
        playing: true,
        indexOfTrack: 0,
        dataTrack: albumDetails.tracks,
        isWebPlayer: true,
        composerImage: null,
        composerName: null,
        composerSlug: null,
        composerId: null,
        jazzMode: isJazz,
        playlist: null,
        playlistId: null,
        pageOrigin: PAGE_ORIGIN_ALBUM,
        mediaProps,
        mediaId: id,
      });
    }
    setInitialising(false);
  };

  return (
    <div
      className={`interactive-play-icon-album ${isInitialising ? "initialising" : ""} ${isCurrentlyPlaying ? "playing" : ""} ${
        isCurrentlyLoading ? "loading" : ""
      }`}
      onClick={(e) => playAlbum(e, albumId)}
      data-cy={`play-button-${albumId}`}
    >
      <img src={isCurrentlyPlaying ? pauseIcon : playIcon} alt="" />
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: getLangSelector(state),
  miniPlayer: getMiniPlayerSelector(state),
});
const mapDispatchToProps = (dispatch) => ({
  updateMiniPlayer: (data) => {
    dispatch(dispatchUpdateMiniPlayer(data));
  },
});

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(InteractivePlayIconAlbums)));
