import React from "react";
import queueIcon from "assets/images/browse_menu.svg";
import iconMenuComposer from "assets/images/icon_menu_composer.svg";
import iconClose from "assets/images/close_search.svg";
import iconMenuWork from "assets/images/icon_menu_work.svg";
import getAbsolutePath from "utils/getAbsolutePath";
import TracksHeart from "components/TracksHeart";
import { Link } from "react-router-dom";
import AddToPlaylist from "./AddToPlaylist";
import ExpandedPlayerQueue from "../../pages/ExpandedPlayer/ExpandedPlayerQueue";
import { getOverlayImg } from "utils/storeUtils";
import iconMenuRemovePlaylist from "assets/images/remove_from_playlist.svg";

const MenuMorePopupMobile = (props) => {
  const {
    t,
    listSongs,
    isLogged,
    addToPlaylist,
    createPlaylist,
    track,
    urlOptions,
    onUrlOptionClick,
    getIcon,
    geoRestricted,
    isMiniPlayer,
    isJazz,
    workId,
    albumDetails,
    hideMenuMobile,
    showQueueMobile,
    toggleQueueMobile,
    goToComposer,
    goToAlbum,
    goToWork,
    isWebplayer,
    removeFromPlaylistProps,
    isUserPlaylist,
    openDeleteModal
  } = props;
  const trackName = track?.trackTitle || track?.track_metadata?.title;

  const handleClose = () => {
    if (showQueueMobile) {
      toggleQueueMobile();
      return;
    }
    hideMenuMobile();
  };
  return (
    <div className="modal-more-menu">
      <div className="icon-close-modal my-10" onClick={handleClose}>
        <span>{t("MOBILE_WEBPLAYER_CLOSE")}</span>
        <img src={getAbsolutePath(iconClose)} alt="" />
      </div>
      {showQueueMobile ? (
        <ExpandedPlayerQueue isMobile={true} />
      ) : (
        <ul className="more-menu-mobile overflow-y-hidden">
          {getOverlayImg(true)}
          {track && <p className="title font-24 playfair px-20 my-10 truncate">{trackName}</p>}
          {isWebplayer && (
            <li onClick={toggleQueueMobile}>
              <img src={getAbsolutePath(queueIcon)} alt="" />
              {t("WEBPLAYER_SHOW_QUEUE")}
            </li>
          )}
          <AddToPlaylist listSongs={listSongs} addToPlaylist={addToPlaylist} createPlaylist={createPlaylist} />
          {isLogged && removeFromPlaylistProps && isUserPlaylist && (
            <li onClick={() => openDeleteModal(listSongs)}>
              <img src={getAbsolutePath(iconMenuRemovePlaylist)} alt="remove track from playlist" />
              {t("MENUMORE_REMOVE_FROM_PLAYLIST")}
            </li>
          )}
          {isLogged && track && !geoRestricted && (
            <TracksHeart track={track} parent={"miniplayer-mobile"} isMiniPlayerHeart={isMiniPlayer} source="more menu" />
          )}
          {workId && !isJazz && (
            <li onClick={goToComposer}>
              <img src={getAbsolutePath(iconMenuComposer)} alt="" />
              {t("PLAYER_ACTIONS_3")}
            </li>
          )}
          {albumDetails && isJazz && (
            <li onClick={goToAlbum}>
              <img src={getAbsolutePath(iconMenuWork)} alt="" />
              {t("JAZZ_PLAYER_GO_TO_ALBUM")}
            </li>
          )}
          {workId && !isJazz && (
            <li onClick={goToWork}>
              <img src={getAbsolutePath(iconMenuWork)} alt="" />
              {t("PLAYER_ACTIONS_4")}
            </li>
          )}
          {urlOptions &&
            urlOptions.map((option, index) => {
              if (option && option.url && !option.url.includes("null") && !option.url.includes("undefined")) {
                return (
                  <Link key={index} to={option.url} onClick={() => onUrlOptionClick(option)}>
                    {getIcon(option.iconType)}
                    {option.text}
                  </Link>
                );
              } else {
                return null;
              }
            })}
        </ul>
      )}
    </div>
  );
};

export default MenuMorePopupMobile;
