import produce from 'immer';
import {
  SET_PODCASTS,
} from './constants';

const initialState = {
  podcasts: null,
  isJazz: null,
};

const podcastsReducer = function (state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SET_PODCASTS:
        draft.podcasts = action.payload.podcasts;
        draft.isJazz = action.payload.isJazz;
        break;
      default:
        return;
    }
  });
}

export default podcastsReducer;
