import {
  SET_LANG_LIBRARY,
  SET_LISTENING_HISTORY,
  SET_PLAYLISTS,
  SET_VIALMA_PLAYLIST,
  SET_VIALMA_PLAYLIST_LIKED,
  SET_VIALMA_PLAYLIST_LIKED_PLUSMINUS,
  SET_USER_LIKED_TRACKS,
  SET_USER_LIKED_ALBUMS,
  SET_USER_LIKED_VIDEOS,
  SET_USER_LIKED_COLLECTIONS,
} from "./constants";

export const dispatchSetLangLibrary = (langLibrary) => ({
  type: SET_LANG_LIBRARY,
  langLibrary,
});

export const dispatchSetListeningHistory = (listeningHistory) => ({
  type: SET_LISTENING_HISTORY,
  listeningHistory,
});

export const dispatchSetPlaylists = (playlists) => ({
  type: SET_PLAYLISTS,
  playlists,
});

export const dispatchSetVialmaPlaylists = (vialmaPlaylists, item, action) => ({
  type: SET_VIALMA_PLAYLIST,
  vialmaPlaylists,
  item,
  action,
});

export const dispatchSetVialmaPlaylistLiked = (vialmaPlaylistLiked) => ({
  type: SET_VIALMA_PLAYLIST_LIKED,
  vialmaPlaylistLiked,
});

export const dispatchPlusMinusVialmaPlaylistLiked = (isPlus) => ({
  type: SET_VIALMA_PLAYLIST_LIKED_PLUSMINUS,
  isPlus,
});

export const dispatchSetUserLikedTracks = (userLikedTracks) => ({
  type: SET_USER_LIKED_TRACKS,
  userLikedTracks,
});

export const dispatchSetUserLikedAlbums = (userLikedAlbums, item, action) => ({
  type: SET_USER_LIKED_ALBUMS,
  userLikedAlbums,
  item,
  action,
});

export const dispatchSetUserLikedVideos = (userLikedVideos, item, action) => ({
  type: SET_USER_LIKED_VIDEOS,
  userLikedVideos,
  item,
  action,
});

export const dispatchSetUserLikedCollections = (userLikedCollections, item, action) => ({
  type: SET_USER_LIKED_COLLECTIONS,
  userLikedCollections,
  item,
  action,
});
